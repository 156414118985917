import {ADD_USERS_LOADING , ADD_USERS_SUCCESS , ADD_USERS_FAILURE} from '../Actions/Types';
import {GET_USERS_LOADING , GET_USERS_SUCCESS , GET_USERS_FAILURE} from '../Actions/Types';
import {EDIT_USERS_LOADING , EDIT_USERS_SUCCESS , EDIT_USERS_FAILURE} from '../Actions/Types';
import {DEL_USERS_LOADING , DEL_USERS_SUCCESS , DEL_USERS_FAILURE} from '../Actions/Types';
import {CHANGE_PASS_LOADING , CHANGE_PASS_SUCCESS , CHANGE_PASS_FAILURE} from '../Actions/Types';



import {CLEAN_ALL_DATA , CLEAN_USERS_DATA} from '../Actions/Types';



const AddUsers = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            cardData:[{}],
            cardError:{},
            passData : [{}],
            passError : {}
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            passData : [{}],
            passError : {}
        }

    case CLEAN_USERS_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                cardData:[{}],
                cardError:{},
                passData : [{}],
                 passError : {}
            }
    

    case ADD_USERS_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
      
    case GET_USERS_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };
          
    case EDIT_USERS_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };

    case CHANGE_PASS_LOADING:
        return {
          ...state,
          loading: true,
          passData: {},
          passError:[{}],
        };
    case CHANGE_PASS_SUCCESS:
        return {
          ...state,
          loading: false,
          passData: action.pdata,
          passError: {}
        };
    case CHANGE_PASS_FAILURE:
        return {
          ...state,
          loading: false,
          passError: action.perror,
          passError: [{}],
        };

    
    default:
        return state

}
}



export default AddUsers;
