import React, { useRef, useState, useEffect } from "react";
import Page from "../../components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";

import {} from 'react-router-dom'



import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import RestorePageIcon from '@material-ui/icons/RestorePage';

import tableIcons from '../../Config/IconsFile'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {GetSummary} from '../../Actions/StockSummary'

import {GetParty, DelParty, AddParty, EditParty} from '../../Actions/Party'
import {AddBox , ArrowDownward} from '@material-ui/icons'


import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Chip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { GetStock, DelStock, UpdateStockVisibility, ModifyStock } from '../../Actions/Stock';

import { CleanStockData , CleanSummary } from "../../Actions/ActionCreators";

import {CleanOutOfStockData} from '../../Actions/ActionCreators';
import {MarkOutOfStock} from '../../Actions/OutOfStock'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const tableRef = React.createRef();  
  
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  
  const [values, setValues] = useState({
    
    purchase_id:'',
    status:'',        
    
  })



  const [rdio, setRdio] = React.useState('admin');

  const handleChangeRadio = (event) => {
    console.log(event.target.value)
    setRdio(event.target.value);
  };


  
  const id = useSelector(state => state.login.data.admin_id);

  const [modify, setModify] = useState({
    admin_id:id,
    price:'',
    quantity:'',
    discount:'',
    party_id : '',
    card_number:'',
    remark:'',
    other_detail:'',
    purchase_id : '',
    comment : "",
    comment_party : ""
  })

  const handleClose = () => {
    setOpen(false);

  };

  const handleClose2 = () => {
    setOpen2(false);
    setModify({
      ...modify,    
      ['price']:'',
      ['quantity']:'',
      ['discount']:'',
      ['party_id']:'',
      ['card_number']:'',
      ['remark']:'',
      ['other_detail']:'',
      ['purchase_id']:'' 
    })

  };


  const listData = useSelector((state) =>
    state.AddStock &&
    Object.keys(state.AddStock.getdata) &&
      Object.keys(state.AddStock.getdata).length &&
      Array.isArray(state.AddStock.getdata.data) &&
      state.AddStock.getdata.data.length
        ? state.AddStock.getdata.data
        : []
  );

  const listLoding = useSelector((state) =>
   state.AddStock.loading
       
  );



  const CountD= useSelector((state) =>
    state.AddStock &&
    Object.keys(state.AddStock.getdata) &&
    Object.keys(state.AddStock.getdata).length &&
    state.AddStock.getdata.total_length
      ? parseInt(state.AddStock.getdata.total_length)
      : 0
  );



  const CountF= useSelector((state) =>
    state.AddStock &&
    Object.keys(state.AddStock.getdata) &&
    Object.keys(state.AddStock.getdata).length &&
    state.AddStock.getdata.page
      ? parseInt(state.AddStock.getdata.page)
      : 0
  );

  const [pagination , setPagination] = useState({
    per_page : 5,
    page : CountF,
    maxCount:CountD

  })



  
 
  const Delmsg = useSelector(state => state.AddStock.deldata && state.AddStock.deldata.status ? state.AddStock.deldata.status : null  )
  const Delerror = useSelector(state => state.AddStock.delerror && state.AddStock.delerror.message ? state.AddStock.delerror.message : null  )
  const editmsg = useSelector(state => state.AddStock && state.AddStock.editdata && state.AddStock.editdata.message ? state.AddStock.editdata.message : null  )
  const editerror = useSelector(state => state.AddStock && state.AddStock.editerror && state.AddStock.editerror.message ? state.AddStock.editerror.message : null  )

  const stockmsg = useSelector(state => state.OutOfStock && state.OutOfStock.data && state.OutOfStock.data.message ? state.OutOfStock.data.message : null  )
  const stockerror = useSelector(state => state.OutOfStock && state.OutOfStock.error && state.OutOfStock.error.message ? state.OutOfStock.error.message : null  )


  const modifymsg = useSelector(state => state.AddStock && state.AddStock.modifyData && state.AddStock.modifyData.message ? state.AddStock.modifyData.message : null  )
  const modifyerror = useSelector(state => state.AddStock && state.AddStock.modifyError && state.AddStock.modifyError.message ? state.AddStock.modifyError.message : null  )

  const viewmsg = useSelector(state => state.AddStock && state.AddStock.viewData && state.AddStock.viewData.message ? state.AddStock.viewData.message : null  )
  const viewerror = useSelector(state => state.AddStock && state.AddStock.viewError && state.AddStock.viewError.message ? state.AddStock.viewError.message : null  )





  
useEffect( () => {
    var t  =  {page : pagination.page , per_page : pagination.per_page}

    dispatch(GetStock(t))
    dispatch(GetParty())

    

    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg!==null && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror!==null && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS(editmsg)
      }
      if(editerror !==null)
      {
        tostE(editerror)
      }
      if(modifymsg !== null && typeof(modifymsg) === 'string')
      {
        tostS(modifymsg)
      }
      if(modifyerror !==null)
      {
        tostE(modifyerror)
      }
      if(viewmsg !== null && typeof(viewmsg) === 'string')
      {
        tostS(viewmsg)
      }
      if(viewerror !==null)
      {
        tostE(viewerror)
      }
      if(stockmsg!==null && typeof(stockmsg) === 'string')
      {
        tostS(stockmsg)
      }
      if(stockerror!==null && typeof(stockerror) === 'string')
      {
          tostE(stockerror)
      }
      

      resolve()

    })
    x.then(()=>{
    
    dispatch(CleanOutOfStockData())
    dispatch(CleanStockData())
    dispatch(CleanSummary())

    })

  //   return () => {
  //     console.log('******************* UNMOUNTED');
  //     dispatch(CleanStockData())
  //     dispatch(CleanSummary())
  //     dispatch(CleanOutOfStockData())

  // };


    
  }, [ Delmsg  ,Delerror, editmsg , editerror, stockmsg, stockerror, modifymsg, modifyerror, viewmsg, viewerror])




  const handlePageChange = (e , x) =>{
    const parameter = {page : e , per_page : pagination.per_page}
    var u   = new Promise((resolve ,reject)=>{
      
      if(x !== null  && x !== undefined )
      {
        dispatch(GetStock(parameter))
        
      }
      
      resolve()
      
    })
    u.then(()=>{
      
      setPagination({...pagination , ['page']  : e})
    })
  
  }
  
  const handleRPP = (e) =>{
    
    const parameter = {page : pagination.page , per_page : e}
  
    var u   = new Promise((resolve ,reject)=>{
      
     
        dispatch(GetStock(parameter))
        
      
      
      resolve()
      
    })
    u.then(()=>{
      
      setPagination({...pagination , ['per_page']  : e})

    })
  
  }



  const navigate = useNavigate();

const onEdit = (olData) =>{
 
  navigate('/app/StockEdit/'+olData.purchase_id , { state: olData })
}
const onShowStock = (olData) =>{

  // var t  =  new Promise((resolve,reject)=>{

  //   dispatch(GetSummary(olData.card_number))
  //   resolve()

  // })
  // t.then(()=>{
    navigate('/app/ShowStock/'+olData.card_number , { state: olData })

  // })
  // console.log(olData)

  // console.log(olData)

}

const onView = (olData) =>{
  // console.log(olData)
  navigate('/app/StockDetail/'+olData.purchase_id , { state: olData })
}




const onDel = (oldata) =>{
  dispatch(DelStock(oldata.purchase_id ,oldata.tableData.id ))
}
 

const onModify = (olData) =>{
  setOpen2(true);
  setModify({
    ...modify,    
    ['price']:olData.price,
    ['quantity']:olData.quantity,
    ['discount']:olData.discount,
    ['party_id']:olData.party_id,
    ['card_number']:olData.card_number,
    ['remark']:olData.remark,
    ['other_detail']:olData.other_detail,
    ['purchase_id']:olData.purchase_id    
  })

}
const handleChangeModify = (event) =>{
  setModify({
    ...modify,
    [event.target.name]: event.target.value,
  });
}

const onChangeStock = (olData) => {  
  const data = {
    
    'purchase_id':olData.purchase_id,
    'status':!olData.is_stock_out,
    "card_number" : olData.card_number,
    "ndata" : olData
   };
  dispatch(MarkOutOfStock(data));
}

const onGalleryView = (olData) => {
  const data = {
    'purchase_id' : olData.purchase_id,
    'show_in_gallery' : !olData.show_in_gallery,
    'arg' : 0

  }
  dispatch(UpdateStockVisibility(data));
}

const onNewArrivalView = (olData) => {
  const data = {
    'purchase_id' : olData.purchase_id,
    'show_in_new_arrivals' : !olData.show_in_new_arrivals,
    'arg' : 1

  }
  dispatch(UpdateStockVisibility(data));
  
}

const onOfferCardView = (olData) => {
  const data = {
    'purchase_id' : olData.purchase_id,
    'show_in_offer_cards' : !olData.show_in_offer_cards,
    'arg' : 2

  }
  dispatch(UpdateStockVisibility(data));
  
}

const handleConfirm = () =>{

  if(values.purchase_id!=="" && values.show_in_gallery=='' && values.show_in_new_arrivals=='' && values.show_in_offer_cards==''){
    const data = {purchase_id:values.purchase_id, status:values.status}
    dispatch(MarkOutOfStock(data));
  }else{
    tostW("There is an Error !!");
  }
  
  setValues({
    ...values,
    ['purchase_id']:'',
    ['status']:'',
    
  });
  setOpen(false);
}
const PData = useSelector((state) =>
state.AddParty &&
state.AddParty.getdata &&
  state.AddParty.getdata.length &&
  Array.isArray(state.AddParty.getdata)
    ? state.AddParty.getdata
    : []
);

const handleSubmit = () => {
  if(rdio === "other" && (modify.comment === "" || modify.comment === null || modify.comment === undefined))
  {
    tostE("Please Enter Discription for Entry")
  }
  else if(rdio === "party" && (modify.comment_party === "" || modify.comment_party === null || modify.comment_party === undefined))
  {
    tostE("Please Select Party Name for Entry")

  }
  else
  {

  
  
  if(modify.purchase_id!=="" && modify.quantity!=="" && modify.price!==''){

    var io  = {
      price : modify.price,
      admin_id : modify.admin_id,
      quantity :modify.quantity,
      discount : modify.discount,
      party_id : modify.party_id,
      card_number:modify.card_number,
      remark : modify.remark,
      other_detail : modify.other_detail,
      purchase_id :modify.purchase_id,
      comment : rdio === 'other' ?  modify.comment  :  rdio === 'admin'  ? `Added By Admin` : `Purchased By ${modify.comment_party}`
       
    }
    dispatch(ModifyStock(io));
    setModify({
      ...modify,    
      ['price']:'',
      ['quantity']:'',
      ['discount']:'',
      ['party_id']:'',
      ['card_number']:'',
      ['remark']:'',
      ['other_detail']:'',
      ['purchase_id']:'', 
      ['comment']  :"",
      ['comment_party']  :""
    })
    setOpen2(false);

  }else{
    tostW("Quantity and Price can't be Blank!!")
  }

}
  
  

};


  const addNewBornbutton = () =>{
    navigate('/app/StockAdd')
    
  }


  const handlePartyMenu = (c) =>{
    // var y  = `${modify.quantity} Quantity Purchased By  ${c.target.value}`
    setModify({...modify ,  ['comment_party'] : c.target.value})

  }

  return (
    <>
      <Page className={classes.root} title="Stock">
      <ToastContainer    />
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are you sure you want to perform this action??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog disableBackdropClick={true} open={open2}  onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modify Stock</DialogTitle>
        <DialogContent style={{minWidth:400}}>     
        <FormControl component="fieldset">
      <FormLabel component="legend">Select Type of Entry</FormLabel>
      <RadioGroup row aria-label="type" name="type" value={rdio} onChange={handleChangeRadio}>
        <FormControlLabel value="admin" control={<Radio />} label="By Admin" />
        <FormControlLabel value="other" control={<Radio />} label="By Other" />
        <FormControlLabel value="party" control={<Radio />} label="By Party" />
      </RadioGroup>
    </FormControl> 
    <br/>
    <br/>
    <br/>
    {rdio === 'admin' ?    
    <>
         <TextField
            fullWidth
            id="quantity"
            label="Quantity"
            type="number"
            name="quantity"
            value={modify.quantity}
            onChange={handleChangeModify}
            required
            variant="outlined"
          />
          <br /><br />
          <TextField
            fullWidth
            id="price"
            label="Price"
            type="number"
            name="price"
            value={modify.price}
            onChange={handleChangeModify}
            required
            variant="outlined"
            />  
            </> : rdio === 'other' ?   
            
            <>
            <TextField
            fullWidth
            id="quantity"
            label="Quantity"
            type="number"
            name="quantity"
            value={modify.quantity}
            onChange={handleChangeModify}
            required
            variant="outlined"
          />
          <br /><br />
          <TextField
            fullWidth
            id="price"
            label="Price"
            type="number"
            name="price"
            value={modify.price}
            onChange={handleChangeModify}
            required
            variant="outlined"
            />
             <br /><br />
          <TextField
            fullWidth
            id="comment"
            multiline={true}
            label="Type Your Remark"
            type="text"
            name="comment"
            value={modify.comment}
            onChange={handleChangeModify}
            required
            variant="outlined"
            />   
            </>  :   
              <>
              <TextField
              fullWidth
              id="quantity"
              label="Quantity"
              type="number"
              name="quantity"
              value={modify.quantity}
              onChange={handleChangeModify}
              required
              variant="outlined"
            />
            <br /><br />
            <TextField
              fullWidth
              id="price"
              label="Price"
              type="number"
              name="price"
              value={modify.price}
              onChange={handleChangeModify}
              required
              variant="outlined"
              />
               <br /><br />
               <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Party*</InputLabel>
                <Select
                  fullWidth
                  label = "Party"              
                  value={values.comment_party}
                  onChange={handlePartyMenu}
                  name="comment_party"
                  required
                  variant="outlined"
                >
                <MenuItem value="">
                <em>None</em>
                </MenuItem>
                {PData.map((data , i)=>
                  <MenuItem key={i} value={data.name}>{data.name}</MenuItem>
                  )}   
                </Select>
              </FormControl>
              </>
              }


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Modify Stock
          </Button>
        </DialogActions>
      </Dialog>
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add to Stock
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

              <MaterialTable
                icons={tableIcons}      
                tableRef={tableRef}                          
                title="Added Cards"
                columns={[
                  { title: 'Sr. No', editable: "never", render: rowData => (
                    
                    rowData.tableData.id  + 1
            
                  )
                },
                  {
                    title: 'Image',
                    field: 'images',
                    export: false,
                    editable: "never",
                    render: rowData => (
                      <img
                      // className={useStyles.avatar}
                      style={{width:'50px' , height:"50px"}}

                      src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ?  rowData.images[0] : ''}
                    />
              
                    ),
                  },
                  { title: 'Card Number', field: 'card_number',editable: "never", },
                  { title: 'Quantity', field: 'quantity',editable: "never", },     
                  { title: 'Rate(Admin)', field: 'rate',editable: "never", },     
                  { title: 'Card Type', field: 'card_type',editable: "never", },
                  { 
                    title: 'Stock Status',  export: false,
                    render: rowData =>(
                      
                      rowData.is_stock_out ? <Chip label="Out of Stock" style={{background:"#ff0000"}} size="small" /> : <Chip label="In Stock" style={{background:"#00ff00"}} size="small" />

                    )
                  },               
                  {
                    title: 'Action', export: false,
                    render: rowData => (
                      <Grid container className={classes.root2}>
                        <Grid item xs={4}>

                        <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                        </Grid>
                        <Grid item xs={4}>
                          <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
                        </Grid>
                        <Grid item xs={4}>
                          <VisibilityIcon onClick={()=>{onView(rowData)}} style={{cursor:"pointer"}} />
                        </Grid>

                      </Grid>
                    ),
                  },
                  {
                    title: 'Check Stock', export: false,
                    render: rowData => (
                      <Grid container className={classes.root2}>
                        <Grid item xs={12}>

                        <AccountBalanceIcon onClick={()=>{onShowStock(rowData)}} style={{cursor:"pointer"}} />
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Modify Stock', export: false,
                    render: rowData => (
                      <Grid container className={classes.root2}>
                        <Grid item xs={4}>

                        <RestorePageIcon onClick={()=>{onModify(rowData)}} style={{cursor:"pointer"}} />
                        </Grid>              
                      </Grid>
                    ),
                  },
                  { title: "Mark As Out of Stock", export: false,
                    render:rowData => (
                      <Grid container className={classes.root2}>
                          <Grid item xs={4}>   

                            <Checkbox checked = {rowData.is_stock_out ? true:false} onChange={()=>{onChangeStock(rowData)}} style={{cursor:"pointer"}} />
                          </Grid>
                      </Grid>
                    )  
                
                  },
                  { title: "View in Gallery", export: false,
                    render:rowData => (
                      <Grid container className={classes.root2}>
                          <Grid item xs={4}>   

                            <Checkbox checked = {rowData.show_in_gallery ? true:false} onChange={()=>{onGalleryView(rowData)}} style={{cursor:"pointer"}} />
                          </Grid>
                      </Grid>
                    )  
                
                  },
                  { title: "View in New Arrivals", export: false,
                    render:rowData => (
                      <Grid container className={classes.root2}>
                          <Grid item xs={4}>   

                            <Checkbox checked = {rowData.show_in_new_arrivals ? true:false} onChange={()=>{onNewArrivalView(rowData)}} style={{cursor:"pointer"}} />
                          </Grid>
                      </Grid>
                    )  
                
                  },
                  { title: "View in Offer Cards", export: false,
                    render:rowData => (
                      <Grid container className={classes.root2}>
                          <Grid item xs={4}>   

                            <Checkbox checked = {rowData.show_in_offer_cards ? true:false} onChange={()=>{onOfferCardView(rowData)}} style={{cursor:"pointer"}} />
                          </Grid>
                      </Grid>
                    )  
                
                  },    

                ]}
                data={listData}
                isLoading={listLoding}
                
                options={{exportButton: true,
                        // selection: true,
                        
                        
                        textAlign: "center",
                        headerStyle: { textAlign: "left" },
                        rowStyle: { textAlign: "center" },
                        pageSizeOptions:[5,10,20,50,CountD],    // rows selection options

                      }}
                components={{
                  Pagination: props => (
                    <TablePagination
                        {...props}                                      
                        count={CountD}
                        page={CountF}
                      
                      />
                    ),
                }}
              onChangeRowsPerPage={(e)=>handleRPP(e)}
              onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              

              />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
