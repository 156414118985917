import 'react-perfect-scrollbar/dist/css/styles.css';
import React , {useState} from 'react';


import {Provider , connect} from 'react-redux'
import { useRoutes , Route , BrowserRouter , Routes ,  } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from '../src/components/GlobalStyles';
import '../src/mixins/chartjs';
import theme from '../src/theme';
import routes from './Routes/routes';
import routes2 from './Routes/routes1';
import history from './Config/history';


import PropTypes from 'prop-types'

import store from './Store/Store';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import UPS from './Load'


const appAuth = true;














const App = () => {
 

  store.subscribe(() => {
    localStorage.setItem('reduxStore' , JSON.stringify(store.getState()));
    console.log(store.getState().login.data.token)
  })
  
  const routing = useRoutes(routes);
  const routing2 = useRoutes(routes2);

 




  return (
    
    <Provider store={store} history={history}>
      <UPS/>
    <ThemeProvider theme={theme}>
  
      <GlobalStyles />
      {!store.getState().login.data.token ?
      routing
      :
      routing2
}  



    </ThemeProvider>
    </Provider>   
  );

};



App.propTypes = {
  auth: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
  auth: state.setuser.data.name 
});

export default App;
