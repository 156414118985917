import {PLACE_ORDER_LOADING, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE, CLEAN_PLACE_ORDER_DATA} from '../Actions/Types';
import {GET_RATE_CARD_LOADING  ,GET_RATE_CARD_SUCCESS  ,GET_RATE_CARD_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA} from '../Actions/Types';

const RateCard = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
         
           cardData: [{}],
           cardError: {},

       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
        
           cardData: [{}],
           cardError: {},

       }

 
   case GET_RATE_CARD_LOADING:
      return {
        ...state,
        loading: true,
        cardData: [{}],
        cardError:{},
      };
   case GET_RATE_CARD_SUCCESS:
       return {
         ...state,
         loading: false,
         cardData: action.data,
         cardError: {},
       };
   case GET_RATE_CARD_FAILURE:
       return {
         ...state,
         loading: false,
         cardError: action.error,
         cardData: [{}],
       };   

  
     
   default:
       return state

}
}



export default RateCard;
