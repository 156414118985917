import {GET_CITY_LOADING , GET_CITY_SUCCESS , GET_CITY_FAILURE} from '../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_CITY_DATA} from '../Actions/Types';



const GetCity = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getdata: [{}],
            geterror: {},
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},
        }

    case CLEAN_CITY_DATA:
        return {
                ...state,
                loading:false,
                getdata: [{}],
                geterror: {},
                
            }
    

        case GET_CITY_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_CITY_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_CITY_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };
      
    default:
        return state

}
}



export default GetCity;
