import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes, { element } from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import tableIcons from '../../Config/IconsFile'
import CancelIcon from '@material-ui/icons/Cancel';

import {AddBox , ArrowDownward} from '@material-ui/icons'

import {AddOrders, GetOrders, DelOrders, EditOrders} from '../../Actions/Orders'
import {CleanOrdersData , CleanMtl} from '../../Actions/ActionCreators'
import {moveToLooseAPI} from '../../Actions/OrdersModify'

import {  ChangeOrderStatus } from '../../Actions/Orders'
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const  TabPanel = (props)=> {
   const { children, value, index, ...other } = props;
 
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`simple-tabpanel-${index}`}
       aria-labelledby={`simple-tab-${index}`}
       {...other}
     >
       {value === index && (
         <Box p={3}>
           <Typography>{children}</Typography>
         </Box>
       )}
     </div>
   );
 }
 
 TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
 };
 
 function a11yProps(index) {
   return {
     id: `simple-tab-${index}`,
     'aria-controls': `simple-tabpanel-${index}`,
   };
 }
 


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rootn: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,

 },
 
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();
  

  // const listData = (state.order_details && Array.isArray(state.order_details) && state.order_details.length ? state.order_details : []);
 
  const cat = (dr) =>{
    console.log("?????????????????????????")
    console.log(state.ord_id)
    console.log(dr.AddOrders.getdata)

    console.log("?????????????????????????")
    // dr.AddOrders.getdata.forEach(element => {
      

    //   if(element.ord_id === state.ord_id )
    //   {
    //     console.log(element.ord_id)
    //     console.log(element)
    //     return element.order_details
    //     // return true
    //   }
    //   return true
      
    // });
    for(let i =0  ; i < dr.AddOrders.getdata.length ; i++)
    {
     var  element = dr.AddOrders.getdata[i]
      if(element.ord_id === state.ord_id )
      {
        console.log(element.ord_id)
        console.log(element)
        return element.order_details
        break;
        
      }
    }

  }
 
 const listData =  useSelector((state) => cat(state) )
  const [data, setData] = useState(listData);



const onEdit = (olData) =>{
  // navigate('/app/NewArrivalEdit/'+olData.ar_id , { state: olData })
}


const handleComplete = () => {
  // console.log(values, "***********")

    dispatch(ChangeOrderStatus({order_status : 1 , ord_id : state.ord_id }));
  }
  
  const Statusmsg = useSelector(state => state.AddOrders.statusdata && state.AddOrders.statusdata.message ? state.AddOrders.statusdata.message : null)
    const Statuserror = useSelector(state => state.AddOrders.statuserror && state.AddOrders.statuserror.message ? state.AddOrders.statuserror.message : null)


    


    const msg1 = useSelector(state => state.ModifyOrder.data && state.ModifyOrder.data.message ? state.ModifyOrder.data.message : null)
    const error1 = useSelector(state => state.ModifyOrder.error && state.ModifyOrder.error.message ? state.ModifyOrder.error.message : null)




useEffect(() => {


  let x = new Promise((resolve, reject) => {

    if (Statusmsg !== null && typeof (Statusmsg) === 'string') {
      tostS(Statusmsg)
      navigate('/app/Orders')
    }
    if (Statuserror !== null && typeof (Statuserror) === 'string') {
      tostE(Statuserror)
    }
    
    if (msg1 !== null && typeof (msg1) === 'string') {
        tostS(msg1)
        dispatch(GetOrders())


        // navigate('/app/Orders')
      }
      if (error1 !== null && typeof (error1) === 'string') {
        tostE(error1)
      }
      
    
    
    
    resolve()
    
  })
  x.then(() => {
    dispatch(GetOrders())
    dispatch(CleanMtl())
  })



}, [ Statusmsg, Statuserror , msg1 , error1])




const moveToLoose = (g ) =>{
  var total_data  =  listData
  var deleted  = g
  var mod  = []
  console.log(g)
  

 

    // var temp  =  listData 


let p =  new Promise((resolve , reject)=>{

//     const dataDelete = [...data];
//     const index = g.tableData.id;
//     dataDelete.splice(index, 1);
//     mod  =  [...dataDelete]
    
//     setData([...dataDelete]);


for(let i = 0  ; i < total_data.length ; i++)
{
  if(total_data[i].card_number === g.card_number)
  {
    total_data.splice( i , 1)
    mod = total_data
    // const dataDelete = [...data];
    // const index = g.tableData.id;
    // dataDelete.splice(index, 1);
    // setData([...dataDelete]);
    break
  }
  
}




   

resolve()
})
p.then(()=>{
    var hd  =  {
        status : 1,
        n_data  : mod,
        del_d : deleted,
        state_d :  state

    }

 dispatch(moveToLooseAPI(hd))   


}).then(()=>{
    if (msg1 !== null && typeof (msg1) === 'string') {
         setData([...mod]);
      }
})
console.log(deleted)
console.log(mod)



      
}


const [columns, setColumns] = useState([
        {
            title: 'Move To Loose Stock',
            render: rowData => (
              <Grid container className={classes.root2}>

                <Grid item xs={3}>
                  <VisibilityIcon onClick={() => { moveToLoose(rowData ) }} style={{ cursor: "pointer" }} />
                </Grid>


              </Grid>
            ),
          },
        { title: 'Card Number', field: 'card_number',type: 'numeric'},
       { title: 'Price', field:'price',type: 'numeric' },     
       { title: 'Discount', field: 'discount',type: 'numeric' },     
       { title: 'Quantity', field: 'quantity',type: 'numeric' }               
     
  ]);


  

 

  return (
   <Page className={classes.root} title="Order Details">
      <ToastContainer    />
   
      <Container maxWidth="lg">
         
      <Card sx={{ maxWidth: 345 }}>
     <CardHeader subheader="" title="Order Details" />
     <Divider />
     <CardContent>
     <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Typography>Order ID : {state.ord_id} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Order Date : {moment(state.order_date).format("DD/MM/YYYY")}  </Typography>

      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Order Time : {moment(state.order_date).format("hh:mm A")}  </Typography>

      </Grid>
      <Grid item md={6} xs={12}>
        <Typography>Order Number : {state.order_number}</Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Order Status : {state.order_status==0 ? 'Pending' : state.order_status==1 ? 'Complete' : state.order_status==2 ? 'Dispatched' : state.order_status==3 ? 'Rejected': null} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Bilti Number : {state.bilti_no} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Name : {state.name} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Mobile : {state.mobile} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Firm Name : {state.name_of_firm} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>User Remark : {state.user_remark &&  Array.isArray(state.user_remark)  && state.user_remark.length ? state.user_remark[0] : "NA"} </Typography>
      </Grid>
      {state.order_status== 0 ?
      <Grid item md={12} xs={12}>
          <Button onClick={handleComplete} color="primary" variant="contained">
            Mark As Complete
          </Button>
      </Grid>
      :null}
     </Grid>
       
     </CardContent>
     
    
   </Card>
      <Box mt={3} maxWidth="lg">
         <Card md={12}>
         {/* <MaterialTable
            icons={tableIcons}      
            title="Material Details"
            columns={[
               { title: 'Card Number', field: 'card_number',editable: "never", },
              { title: 'Price', field:'price',editable: "never", },     
              { title: 'Discount', field: 'discount',editable: "never", },     
              { title: 'Quantity', field: 'quantity',editable: "never", },                    
            
            ]}
            data={listData}
              options={{
                      selection: false,
                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}
                 
          /> */}
           <MaterialTable
      title="Order Details"
      columns={columns}
      icons={tableIcons}  
      data={listData}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         setData([...data, newData]);
              
      //         resolve();
      //       }, 1000)
      //     }),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         const dataUpdate = [...data];
      //         const index = oldData.tableData.id;
      //         dataUpdate[index] = newData;
      //         setData([...dataUpdate]);

      //         resolve();
      //       }, 1000)
      //     }),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         const dataDelete = [...data];
      //         const index = oldData.tableData.id;
      //         dataDelete.splice(index, 1);
      //         setData([...dataDelete]);
              
      //         resolve()
      //       }, 1000)
      //     }),
      // }}
    />
          </Card>
         
      </Box>    
     
   </Container>
   </Page>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);



