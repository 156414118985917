import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import tableIcons from '../../Config/IconsFile'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,

  makeStyles,
  Container,
  Grid,

  Avatar,

  Button,
  TextField,
  Card,
  Typography

} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {GetMsgs} from '../../Actions/SendMsg'
import {CleanUsersData, CleanPlaceOrderData} from '../../Actions/ActionCreators'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {

    padding: theme.spacing(2, 4, 3),

    
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()



  




  const listData = useSelector((state) =>
  state.Msgs.getdata &&
    state.Msgs.getdata.length &&
    Array.isArray(state.Msgs.getdata)
      ? state.Msgs.getdata
      : []
  );




  


useEffect( () => {

    dispatch(GetMsgs())


   


    
  }, [])


  const navigate = useNavigate();







  


  const addNewBornbutton = () =>{
    navigate('/app/msgs/add')
    
  }







  return (
    <>
      <Page className={classes.root} title="Users">

    

    
      <ToastContainer    />
        <Container maxWidth={false}>
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
           
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Send Message
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Notifications"
      columns={[
        { title: 'ID', field: 'not_id',editable: "never", },
        { title: 'Title', field: 'title',editable: "never", },
        { title: 'Body', field: 'body',editable: "never", },
        { title: 'From', 
            render: rowData => (
             
            <Typography>Admin</Typography>
          
            )
        },
        { title: 'To', 
            render: rowData => (
             
            <Typography> {rowData.user_id === "" || rowData.user_id === null || rowData.user_id === undefined ? "All"  : "User"}</Typography>
          
            )
        }

        
       

      ]}
      data={listData}
        options={{
          exportButton: true,

                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
