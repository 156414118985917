import {SET_USER_LOADING , SET_USER_SUCCESS , SET_USER_FAILURE  } from './Types' ;

import {LOGIN_USER_LOADING , LOGIN_USER_SUCCESS , LOGIN_USER_FAILURE } from './Types' ;
import {CLEAN_LOGIN_DATA} from './Types'

import {REFRESH_TOKEN_LOADING , REFRESH_TOKEN_SUCCESS , REFRESH_TOKEN_FAILURE } from './Types' ;
import {LOGOUT_USER_LOADING , LOGOUT_USER_SUCCESS , LOGOUT_USER_FAILURE } from './Types' ;

import { GET_DASHBOARD_LOADING , GET_DASHBOARD_SUCCESS , GET_DASHBOARD_FAILURE } from './Types'
import { GET_COUNTRY_LOADING , GET_COUNTRY_SUCCESS , GET_COUNTRY_FAILURE } from './Types'
import { GET_STATES_LOADING , GET_STATES_SUCCESS , GET_STATES_FAILURE } from './Types'
// import { GET_CITY_LOADING , GET_CITY_SUCCESS , GET_CITY_FAILURE } from './Types'
import { GET_SUMMARY_LOADING , GET_SUMMARY_SUCCESS , GET_SUMMARY_FAILURE } from './Types'

import {CLEAN_COUNTRY_DATA} from './Types' ;
import {CLEAN_STATES_DATA} from './Types' ;
import {CLEAN_CITY_DATA} from './Types' ;

import {CLEAN_ALL_DATA , CLEAN_SUMMARY } from './Types' ;


// *********************************************************************************************************//
import { ADD_NEW_ARRIVAL_FAILURE, ADD_NEW_ARRIVAL_LOADING, ADD_NEW_ARRIVAL_SUCCESS } from './Types';
import { GET_NEW_ARRIVAL_FAILURE, GET_NEW_ARRIVAL_LOADING, GET_NEW_ARRIVAL_SUCCESS } from './Types';
import { EDIT_NEW_ARRIVAL_FAILURE, EDIT_NEW_ARRIVAL_LOADING, EDIT_NEW_ARRIVAL_SUCCESS } from './Types';
import { DEL_NEW_ARRIVAL_FAILURE, DEL_NEW_ARRIVAL_LOADING, DEL_NEW_ARRIVAL_SUCCESS } from './Types';
import { SHIFT_TO_GALLERY_FAILURE, SHIFT_TO_GALLERY_LOADING, SHIFT_TO_GALLERY_SUCCESS } from './Types';
import { LTD_FAILURE, LTD_LOADING, LTD_SUCCESS } from './Types';

import {CLEAN_NEW_ARRIVAL_DATA , CLEAN_MTL } from './Types' ;

import { ADD_GALLERY_FAILURE, ADD_GALLERY_LOADING, ADD_GALLERY_SUCCESS } from './Types';
import { GET_GALLERY_FAILURE, GET_GALLERY_LOADING, GET_GALLERY_SUCCESS } from './Types';
import { EDIT_GALLERY_FAILURE, EDIT_GALLERY_LOADING, EDIT_GALLERY_SUCCESS } from './Types';
import { DEL_GALLERY_FAILURE, DEL_GALLERY_LOADING, DEL_GALLERY_SUCCESS } from './Types';

import {CLEAN_GALLERY_DATA } from './Types' ;

import { ADD_UPI_FAILURE, ADD_UPI_LOADING, ADD_UPI_SUCCESS } from './Types';
import { GET_UPI_FAILURE, GET_UPI_LOADING, GET_UPI_SUCCESS } from './Types';
import { EDIT_UPI_FAILURE, EDIT_UPI_LOADING, EDIT_UPI_SUCCESS } from './Types';
import { DEL_UPI_FAILURE, DEL_UPI_LOADING, DEL_UPI_SUCCESS } from './Types';

import {CLEAN_UPI_DATA } from './Types' ;

import { ADD_USERS_FAILURE, ADD_USERS_LOADING, ADD_USERS_SUCCESS } from './Types';
import { GET_USERS_FAILURE, GET_USERS_LOADING, GET_USERS_SUCCESS } from './Types';
import { EDIT_USERS_FAILURE, EDIT_USERS_LOADING, EDIT_USERS_SUCCESS } from './Types';
import { DEL_USERS_FAILURE, DEL_USERS_LOADING, DEL_USERS_SUCCESS } from './Types';

import {CLEAN_USERS_DATA } from './Types' ;

import { GET_CARD_DATA_FAILURE, GET_CARD_DATA_LOADING, GET_CARD_DATA_SUCCESS } from './Types';
import { PLACE_ORDER_FAILURE, PLACE_ORDER_LOADING, PLACE_ORDER_SUCCESS, CLEAN_PLACE_ORDER_DATA } from './Types';

import { ADD_ORDERS_FAILURE, ADD_ORDERS_LOADING, ADD_ORDERS_SUCCESS } from './Types';
import { GET_ORDERS_FAILURE, GET_ORDERS_LOADING, GET_ORDERS_SUCCESS } from './Types';
import { EDIT_ORDERS_FAILURE, EDIT_ORDERS_LOADING, EDIT_ORDERS_SUCCESS } from './Types';
import { DEL_ORDERS_FAILURE, DEL_ORDERS_LOADING, DEL_ORDERS_SUCCESS } from './Types';
import { CHANGE_ORDER_STATUS_FAILURE, CHANGE_ORDER_STATUS_LOADING, CHANGE_ORDER_STATUS_SUCCESS } from './Types';

import {CLEAN_ORDERS_DATA } from './Types' ;

import { ADD_OFFER_CARD_FAILURE, ADD_OFFER_CARD_LOADING, ADD_OFFER_CARD_SUCCESS } from './Types';
import { GET_OFFER_CARD_FAILURE, GET_OFFER_CARD_LOADING, GET_OFFER_CARD_SUCCESS } from './Types';
import { EDIT_OFFER_CARD_FAILURE, EDIT_OFFER_CARD_LOADING, EDIT_OFFER_CARD_SUCCESS } from './Types';
import { DEL_OFFER_CARD_FAILURE, DEL_OFFER_CARD_LOADING, DEL_OFFER_CARD_SUCCESS } from './Types';

import {CLEAN_OFFER_CARD_DATA } from './Types' ;

import {OUT_OF_STOCK_LOADING, OUT_OF_STOCK_SUCCESS, OUT_OF_STOCK_FAILURE, CLEAN_OUT_OF_STOCK_DATA} from './Types'

import { ADD_STOCK_FAILURE, ADD_STOCK_LOADING, ADD_STOCK_SUCCESS } from './Types';
import { GET_STOCK_FAILURE, GET_STOCK_LOADING, GET_STOCK_SUCCESS } from './Types';
import { EDIT_STOCK_FAILURE, EDIT_STOCK_LOADING, EDIT_STOCK_SUCCESS } from './Types';
import { MODIFY_STOCK_FAILURE, MODIFY_STOCK_LOADING, MODIFY_STOCK_SUCCESS } from './Types';
import { DEL_STOCK_FAILURE, DEL_STOCK_LOADING, DEL_STOCK_SUCCESS } from './Types';
import { UPDATE_STOCK_VISIBILITY_FAILURE, UPDATE_STOCK_VISIBILITY_LOADING, UPDATE_STOCK_VISIBILITY_SUCCESS } from './Types';

import {CLEAN_STOCK_DATA } from './Types' ;

import { ADD_PARTY_FAILURE, ADD_PARTY_LOADING, ADD_PARTY_SUCCESS } from './Types';
import { GET_PARTY_FAILURE, GET_PARTY_LOADING, GET_PARTY_SUCCESS } from './Types';
import { EDIT_PARTY_FAILURE, EDIT_PARTY_LOADING, EDIT_PARTY_SUCCESS } from './Types';
import { DEL_PARTY_FAILURE, DEL_PARTY_LOADING, DEL_PARTY_SUCCESS } from './Types';

import {CLEAN_PARTY_DATA   ,CLEAN_LTD} from './Types' ;


import { GET_PURCHASE_HISTORY_FAILURE, GET_PURCHASE_HISTORY_LOADING, GET_PURCHASE_HISTORY_SUCCESS, CLEAN_PURCHASE_HISTORY_DATA} from './Types';
import { GET_SELL_HISTORY_FAILURE, GET_SELL_HISTORY_LOADING, GET_SELL_HISTORY_SUCCESS, CLEAN_SELL_HISTORY_DATA} from './Types';
import { CHANGE_PASS_FAILURE, CHANGE_PASS_LOADING, CHANGE_PASS_SUCCESS } from './Types';
import { MOVE_TO_LOOSE_FAILURE, MOVE_TO_LOOSE_LOADING, MOVE_TO_LOOSE_SUCCESS } from './Types';
import { GET_LOOSE_FAILURE, GET_LOOSE_LOADING, GET_LOOSE_SUCCESS } from './Types';
import { SEND_MSG_FAILURE, SEND_MSG_LOADING, SEND_MSG_SUCCESS } from './Types';
import { GET_MSG_FAILURE, GET_MSG_LOADING, GET_MSG_SUCCESS  , CLEAN_MSG} from './Types';
import { ADD_BILTI_LOADING , ADD_BILTI_SUCCESS , ADD_BILTI_FAILURE} from './Types';


import { ADD_ORD_SEQ_LOADING , ADD_ORD_SEQ_SUCCESS , ADD_ORD_SEQ_FAILURE} from './Types';
import { GET_ORD_SEQ_LOADING , GET_ORD_SEQ_SUCCESS , GET_ORD_SEQ_FAILURE} from './Types';
import { EDIT_ORD_SEQ_LOADING , EDIT_ORD_SEQ_SUCCESS , EDIT_ORD_SEQ_FAILURE , CLEAN_ORD_SEQ} from './Types';
import {GET_RATE_CARD_LOADING , GET_RATE_CARD_SUCCESS , GET_RATE_CARD_FAILURE} from './Types';




import {GET_P_BILLS_LOADING , GET_P_BILLS_SUCCESS , GET_P_BILLS_FAILURE} from './Types';


export const cleanCountryData = () => ({
    type : CLEAN_COUNTRY_DATA,
  })
  
export const cleanStateData = () => ({
    type : CLEAN_STATES_DATA,
  })
  
export const cleanCityData = () => ({
    type : CLEAN_CITY_DATA,
  })
  



export const setUserBegin = () => ({
    type : SET_USER_LOADING
})

export const setUsrSuccess = (data) => ({
    type : SET_USER_SUCCESS,
    data ,
})

export const setUsrFailure = (error) => ({
    type : SET_USER_FAILURE,
    error
    
})



export const cleanAllData = () => ({
  type : CLEAN_ALL_DATA,
})


export const loginUsrBegin = () => ({
    type : LOGIN_USER_LOADING
})

export const loginUsrSuccess = (logindata) => ({
    type : LOGIN_USER_SUCCESS,
    logindata ,
})

export const loginUsrFailure = (loginerror) => ({
    type : LOGIN_USER_FAILURE,
    loginerror
    
})


export const cleanLoginData = () => ({
    type : CLEAN_LOGIN_DATA,
  })
  
export const logoutUsrBegin = () => ({
    type : LOGOUT_USER_LOADING
})

export const logoutUsrSuccess = (logoutdata) => ({
    type : LOGOUT_USER_SUCCESS,
    logoutdata ,
})

export const logoutUsrFailure = (logouterror) => ({
    type : LOGOUT_USER_FAILURE,
    logouterror
    
})

export const refreshTokenBegin = () => ({
    type : REFRESH_TOKEN_LOADING
  })
  
  export const  refreshTokenSuccess = (token) => ({
  type : REFRESH_TOKEN_SUCCESS,
  token,
  })
  
  export const refreshTokenFailure = (error) => ({
  type :REFRESH_TOKEN_FAILURE,
  error
  })

export const getDashboardBegin = () => ({
    type : GET_DASHBOARD_LOADING
})

export const getDashboardSuccess = (getdata) => ({
    type : GET_DASHBOARD_SUCCESS,
    getdata ,
})

export const getDashboardFailure = (geterror) => ({
    type : GET_DASHBOARD_FAILURE,
    geterror
    
})



export const getPBillsBegin = () => ({
    type : GET_P_BILLS_LOADING
})

export const getPBillsSuccess = (getdata) => ({
    type : GET_P_BILLS_SUCCESS,
    getdata ,
})

export const getPBillsFailure = (geterror) => ({
    type : GET_P_BILLS_FAILURE,
    geterror
    
})




export const getCountryBegin = () => ({
    type : GET_COUNTRY_LOADING
})

export const getCountrySuccess = (getdata) => ({
    type : GET_COUNTRY_SUCCESS,
    getdata ,
})

export const getCountryFailure = (geterror) => ({
    type : GET_COUNTRY_FAILURE,
    geterror
    
})




export const getStatesBegin = () => ({
    type : GET_STATES_LOADING
})

export const getStatesSuccess = (getdata) => ({
    type : GET_STATES_SUCCESS,
    getdata ,
})

export const getStatesFailure = (geterror) => ({
    type : GET_STATES_FAILURE,
    geterror
    
})






// ************************************************************************************************************//


export const AddNewArrivalBegin = () => ({
    type : ADD_NEW_ARRIVAL_LOADING
})

export const AddNewArrivalSuccess = (data) => ({
    type : ADD_NEW_ARRIVAL_SUCCESS,
    data ,
})

export const AddNewArrivalFailure = (error) => ({
    type : ADD_NEW_ARRIVAL_FAILURE,
    error
    
})


export const GetNewArrivalBegin = () => ({
    type : GET_NEW_ARRIVAL_LOADING
})

export const GetNewArrivalSuccess = (data) => ({
    type : GET_NEW_ARRIVAL_SUCCESS,
    data ,
})

export const GetNewArrivalFailure = (error) => ({
    type : GET_NEW_ARRIVAL_FAILURE,
    error
    
})


export const EditNewArrivalBegin = () => ({
    type : EDIT_NEW_ARRIVAL_LOADING
})

export const EditNewArrivalSuccess = (data) => ({
    type : EDIT_NEW_ARRIVAL_SUCCESS,
    data ,
})

export const EditNewArrivalFailure = (error) => ({
    type : EDIT_NEW_ARRIVAL_FAILURE,
    error
    
})


export const DelNewArrivalBegin = () => ({
    type : DEL_NEW_ARRIVAL_LOADING
})

export const DelNewArrivalSuccess = (data) => ({
    type : DEL_NEW_ARRIVAL_SUCCESS,
    data ,
})

export const DelNewArrivalFailure = (error) => ({
    type : DEL_NEW_ARRIVAL_FAILURE,
    error
    
})


export const ShiftToGalleryBegin = () => ({
    type : SHIFT_TO_GALLERY_LOADING
})

export const ShiftToGallerySuccess = (data) => ({
    type : SHIFT_TO_GALLERY_SUCCESS,
    data ,
})

export const ShiftToGalleryFailure = (error) => ({
    type : SHIFT_TO_GALLERY_FAILURE,
    error
    
})


export const CleanNewArrivalData = () => ({
    type : CLEAN_NEW_ARRIVAL_DATA,
})

export const AddGalleryBegin = () => ({
    type : ADD_GALLERY_LOADING
})

export const AddGallerySuccess = (data) => ({
    type : ADD_GALLERY_SUCCESS,
    data ,
})

export const AddGalleryFailure = (error) => ({
    type : ADD_GALLERY_FAILURE,
    error
    
})


export const GetGalleryBegin = () => ({
    type : GET_GALLERY_LOADING
})

export const GetGallerySuccess = (data) => ({
    type : GET_GALLERY_SUCCESS,
    data ,
})

export const GetGalleryFailure = (error) => ({
    type : GET_GALLERY_FAILURE,
    error
    
})



export const EditGalleryBegin = () => ({
    type : EDIT_GALLERY_LOADING
})

export const EditGallerySuccess = (data) => ({
    type : EDIT_GALLERY_SUCCESS,
    data ,
})

export const EditGalleryFailure = (error) => ({
    type : EDIT_GALLERY_FAILURE,
    error
    
})


export const DelGalleryBegin = () => ({
    type : DEL_GALLERY_LOADING
})

export const DelGallerySuccess = (data) => ({
    type : DEL_GALLERY_SUCCESS,
    data ,
})

export const DelGalleryFailure = (error) => ({
    type : DEL_GALLERY_FAILURE,
    error
    
})


export const CleanGalleryData = () => ({
    type : CLEAN_GALLERY_DATA,
})


export const AddUpiBegin = () => ({
    type : ADD_UPI_LOADING
})

export const AddUpiSuccess = (data) => ({
    type : ADD_UPI_SUCCESS,
    data ,
})

export const AddUpiFailure = (error) => ({
    type : ADD_UPI_FAILURE,
    error
    
})


export const GetUpiBegin = () => ({
    type : GET_UPI_LOADING
})

export const GetUpiSuccess = (data) => ({
    type : GET_UPI_SUCCESS,
    data ,
})

export const GetUpiFailure = (error) => ({
    type : GET_UPI_FAILURE,
    error
    
})



export const GetSummaryBegin = () => ({
    type : GET_SUMMARY_LOADING
})

export const GetSummarySuccess = (getdata) => ({
    type : GET_SUMMARY_SUCCESS,
    getdata ,
})

export const GetSummaryFailure = (geterror) => ({
    type : GET_SUMMARY_FAILURE,
    geterror
    
})


export const CleanSummary = () => ({
    type : CLEAN_SUMMARY,
  
    
})







export const EditUpiBegin = () => ({
    type : EDIT_UPI_LOADING
})

export const EditUpiSuccess = (data) => ({
    type : EDIT_UPI_SUCCESS,
    data ,
})

export const EditUpiFailure = (error) => ({
    type : EDIT_UPI_FAILURE,
    error
    
})


export const DelUpiBegin = () => ({
    type : DEL_UPI_LOADING
})

export const DelUpiSuccess = (data) => ({
    type : DEL_UPI_SUCCESS,
    data ,
})

export const DelUpiFailure = (error) => ({
    type : DEL_UPI_FAILURE,
    error
    
})

export const CleanUpiData = () => ({
    type : CLEAN_UPI_DATA,
})



export const AddUsersBegin = () => ({
    type : ADD_USERS_LOADING
})

export const AddUsersSuccess = (data) => ({
    type : ADD_USERS_SUCCESS,
    data ,
})

export const AddUsersFailure = (error) => ({
    type : ADD_USERS_FAILURE,
    error
    
})


export const GetUsersBegin = () => ({
    type : GET_USERS_LOADING
})

export const GetUsersSuccess = (data) => ({
    type : GET_USERS_SUCCESS,
    data ,
})

export const GetUsersFailure = (error) => ({
    type : GET_USERS_FAILURE,
    error
    
})

export const EditUsersBegin = () => ({
    type : EDIT_USERS_LOADING
})

export const EditUsersSuccess = (data) => ({
    type : EDIT_USERS_SUCCESS,
    data ,
})

export const EditUsersFailure = (error) => ({
    type : EDIT_USERS_FAILURE,
    error
    
})


export const DelUsersBegin = () => ({
    type : DEL_USERS_LOADING
})

export const DelUsersSuccess = (data) => ({
    type : DEL_USERS_SUCCESS,
    data ,
})

export const DelUsersFailure = (error) => ({
    type : DEL_USERS_FAILURE,
    error
    
})





export const CleanUsersData = () => ({
    type : CLEAN_USERS_DATA,
})





export const AddOrdersBegin = () => ({
    type : ADD_ORDERS_LOADING
})

export const AddOrdersSuccess = (data) => ({
    type : ADD_ORDERS_SUCCESS,
    data ,
})

export const AddOrdersFailure = (error) => ({
    type : ADD_ORDERS_FAILURE,
    error
    
})


export const GetOrdersBegin = () => ({
    type : GET_ORDERS_LOADING
})

export const GetOrdersSuccess = (data) => ({
    type : GET_ORDERS_SUCCESS,
    data ,
})

export const GetOrdersFailure = (error) => ({
    type : GET_ORDERS_FAILURE,
    error
    
})

export const EditOrdersBegin = () => ({
    type : EDIT_ORDERS_LOADING
})

export const EditOrdersSuccess = (data) => ({
    type : EDIT_ORDERS_SUCCESS,
    data ,
})

export const EditOrdersFailure = (error) => ({
    type : EDIT_ORDERS_FAILURE,
    error
    
})


export const DelOrdersBegin = () => ({
    type : DEL_ORDERS_LOADING
})

export const DelOrdersSuccess = (data) => ({
    type : DEL_ORDERS_SUCCESS,
    data ,
})

export const DelOrdersFailure = (error) => ({
    type : DEL_ORDERS_FAILURE,
    error
    
})


export const ChangeOrderStatusBegin = () => ({
    type : CHANGE_ORDER_STATUS_LOADING
})

export const ChangeOrderStatusSuccess = (data) => ({
    type : CHANGE_ORDER_STATUS_SUCCESS,
    data ,
})

export const ChangeOrderStatusFailure = (error) => ({
    type : CHANGE_ORDER_STATUS_FAILURE,
    error
    
})



export const CleanOrdersData = () => ({
    type : CLEAN_ORDERS_DATA,
})




export const AddOfferCardBegin = () => ({
    type : ADD_OFFER_CARD_LOADING
})

export const AddOfferCardSuccess = (data) => ({
    type : ADD_OFFER_CARD_SUCCESS,
    data ,
})

export const AddOfferCardFailure = (error) => ({
    type : ADD_OFFER_CARD_FAILURE,
    error
    
})


export const GetOfferCardBegin = () => ({
    type : GET_OFFER_CARD_LOADING
})

export const GetOfferCardSuccess = (data) => ({
    type : GET_OFFER_CARD_SUCCESS,
    data ,
})

export const GetOfferCardFailure = (error) => ({
    type : GET_OFFER_CARD_FAILURE,
    error
    
})

export const EditOfferCardBegin = () => ({
    type : EDIT_OFFER_CARD_LOADING
})

export const EditOfferCardSuccess = (data) => ({
    type : EDIT_OFFER_CARD_SUCCESS,
    data ,
})

export const EditOfferCardFailure = (error) => ({
    type : EDIT_OFFER_CARD_FAILURE,
    error
    
})


export const DelOfferCardBegin = () => ({
    type : DEL_OFFER_CARD_LOADING
})

export const DelOfferCardSuccess = (data) => ({
    type : DEL_OFFER_CARD_SUCCESS,
    data ,
})

export const DelOfferCardFailure = (error) => ({
    type : DEL_OFFER_CARD_FAILURE,
    error
    
})

export const CleanOfferCardData = () => ({
    type : CLEAN_OFFER_CARD_DATA,
})



export const GetCardDataBegin = () => ({
    type : GET_CARD_DATA_LOADING
})

export const GetCardDataSuccess = (data) => ({
    type : GET_CARD_DATA_SUCCESS,
    data ,
})

export const GetCardDataFailure = (error) => ({
    type : GET_CARD_DATA_FAILURE,
    error
    
})


export const PlaceOrderBegin = () => ({
    type : PLACE_ORDER_LOADING
})

export const PlaceOrderSuccess = (data) => ({
    type : PLACE_ORDER_SUCCESS,
    data ,
})

export const PlaceOrderFailure = (error) => ({
    type : PLACE_ORDER_FAILURE,
    error
    
})


export const CleanPlaceOrderData = () => ({
    type : CLEAN_PLACE_ORDER_DATA,
})



export const OutOfStockBegin = () => ({
    type : OUT_OF_STOCK_LOADING
})

export const OutOfStockSuccess = (data) => ({
    type : OUT_OF_STOCK_SUCCESS,
    data ,
})

export const OutOfStockFailure = (error) => ({
    type : OUT_OF_STOCK_FAILURE,
    error
    
})



export const CleanOutOfStockData = () => ({
    type : CLEAN_OUT_OF_STOCK_DATA,
})



export const AddStockBegin = () => ({
    type : ADD_STOCK_LOADING
})

export const AddStockSuccess = (data) => ({
    type : ADD_STOCK_SUCCESS,
    data ,
})

export const AddStockFailure = (error) => ({
    type : ADD_STOCK_FAILURE,
    error
    
})


export const GetStockBegin = () => ({
    type : GET_STOCK_LOADING
})

export const GetStockSuccess = (data) => ({
    type : GET_STOCK_SUCCESS,
    data ,
})

export const GetStockFailure = (error) => ({
    type : GET_STOCK_FAILURE,
    error
    
})

export const EditStockBegin = () => ({
    type : EDIT_STOCK_LOADING
})

export const EditStockSuccess = (data) => ({
    type : EDIT_STOCK_SUCCESS,
    data ,
})

export const EditStockFailure = (error) => ({
    type : EDIT_STOCK_FAILURE,
    error
    
})

export const ModifyStockBegin = () => ({
    type : MODIFY_STOCK_LOADING
})

export const ModifyStockSuccess = (data) => ({
    type : MODIFY_STOCK_SUCCESS,
    data ,
})

export const ModifyStockFailure = (error) => ({
    type : MODIFY_STOCK_FAILURE,
    error
    
})

export const DelStockBegin = () => ({
    type : DEL_STOCK_LOADING
})

export const DelStockSuccess = (data) => ({
    type : DEL_STOCK_SUCCESS,
    data ,
})

export const DelStockFailure = (error) => ({
    type : DEL_STOCK_FAILURE,
    error
    
})

export const UpdateStockVisibilityBegin = () => ({
    type : UPDATE_STOCK_VISIBILITY_LOADING
})

export const UpdateStockVisibilitySuccess = (data) => ({
    type : UPDATE_STOCK_VISIBILITY_SUCCESS,
    data ,
})

export const UpdateStockVisibilityFailure = (error) => ({
    type : UPDATE_STOCK_VISIBILITY_FAILURE,
    error
    
})

export const CleanStockData = () => ({
    type : CLEAN_STOCK_DATA,
})



export const AddPartyBegin = () => ({
    type : ADD_PARTY_LOADING
})

export const AddPartySuccess = (data) => ({
    type : ADD_PARTY_SUCCESS,
    data ,
})

export const AddPartyFailure = (error) => ({
    type : ADD_PARTY_FAILURE,
    error
    
})


export const GetPartyBegin = () => ({
    type : GET_PARTY_LOADING
})

export const GetPartySuccess = (data) => ({
    type : GET_PARTY_SUCCESS,
    data ,
})

export const GetPartyFailure = (error) => ({
    type : GET_PARTY_FAILURE,
    error
    
})

export const EditPartyBegin = () => ({
    type : EDIT_PARTY_LOADING
})

export const EditPartySuccess = (data) => ({
    type : EDIT_PARTY_SUCCESS,
    data ,
})

export const EditPartyFailure = (error) => ({
    type : EDIT_PARTY_FAILURE,
    error
    
})


export const DelPartyBegin = () => ({
    type : DEL_PARTY_LOADING
})

export const DelPartySuccess = (data) => ({
    type : DEL_PARTY_SUCCESS,
    data ,
})

export const DelPartyFailure = (error) => ({
    type : DEL_PARTY_FAILURE,
    error
    
})

export const CleanPartyData = () => ({
    type : CLEAN_PARTY_DATA,
})


export const GetPurchaseHistoryBegin = () => ({
    type : GET_PURCHASE_HISTORY_LOADING
})

export const GetPurchaseHistorySuccess = (data) => ({
    type : GET_PURCHASE_HISTORY_SUCCESS,
    data ,
})

export const GetPurchaseHistoryFailure = (error) => ({
    type : GET_PURCHASE_HISTORY_FAILURE,
    error
    
})

export const CleanPurchaseHistoryData = () => ({
    type : CLEAN_PURCHASE_HISTORY_DATA,
})


export const GetSellHistoryBegin = () => ({
    type : GET_SELL_HISTORY_LOADING
})

export const GetSellHistorySuccess = (data) => ({
    type : GET_SELL_HISTORY_SUCCESS,
    data ,
})

export const GetSellHistoryFailure = (error) => ({
    type : GET_SELL_HISTORY_FAILURE,
    error
    
})

export const CleanSellHistoryData = () => ({
    type : CLEAN_SELL_HISTORY_DATA,
})

export const ChangePassBegin = () => ({
    type : CHANGE_PASS_LOADING
})

export const ChangePassSuccess = (pdata) => ({
    type : CHANGE_PASS_SUCCESS,
    pdata ,
})

export const ChangePassFailure = (perror) => ({
    type : CHANGE_PASS_FAILURE,
    perror
    
})


export const MoveToLooseBegin = () => ({
    type : MOVE_TO_LOOSE_LOADING
})

export const MoveToLooseSuccess = (mtl) => ({
    type : MOVE_TO_LOOSE_SUCCESS,
    mtl ,
})

export const MoveToLooseFailure = (mtlerr) => ({
    type : MOVE_TO_LOOSE_FAILURE,
   mtlerr
    
})


export const getLooseBegin = () => ({
    type : GET_LOOSE_LOADING
})

export const getLooseSuccess = (lsd) => ({
    type : GET_LOOSE_SUCCESS,
    lsd ,
})

export const getLooseFailure = (lse) => ({
    type : GET_LOOSE_FAILURE,
   lse
    
})


export const CleanMtl = () => ({
    type : CLEAN_MTL,

    
})



export const sendMsgBegin = () => ({
    type : SEND_MSG_LOADING
})

export const sendMsgSuccess = (data) => ({
    type : SEND_MSG_SUCCESS,
    data ,
})

export const sendMsgFailure = (err) => ({
    type : SEND_MSG_FAILURE,
   err
    
})


export const CleanMsg = () => ({
    type : CLEAN_MSG,

    
})



export const getMsgBegin = () => ({
    type : GET_MSG_LOADING
})

export const getMsgSuccess = (mdata) => ({
    type : GET_MSG_SUCCESS,
    mdata ,
})

export const getMsgFailure = (merr) => ({
    type : GET_MSG_FAILURE,
   merr
    
})


export const addBiltiBegin = () => ({
    type : ADD_BILTI_LOADING
})

export const addBiltiSuccess = (bdata) => ({
    type : ADD_BILTI_SUCCESS,
    bdata ,
})

export const addBiltiFailure = (berr) => ({
    type : ADD_BILTI_FAILURE,
   berr
    
})


export const ltdBegin = () => ({
    type : LTD_LOADING
})

export const ltdSuccess = (data) => ({
    type : LTD_SUCCESS,
    data ,
})

export const ltdFailure = (err) => ({
    type : LTD_FAILURE,
   err
    
})


export const CleanLtd = () => ({
    type : CLEAN_LTD,

    
})







export const addOrderSeqBegin = () => ({
    type : ADD_ORD_SEQ_LOADING
})

export const addOrderSeqSuccess = (data) => ({
    type : ADD_ORD_SEQ_SUCCESS,
    data ,
})

export const addOrderSeqFailure = (err) => ({
    type : ADD_ORD_SEQ_FAILURE,
   err
    
})


export const cleanOrderSeq = () => ({
    type : CLEAN_ORD_SEQ,
  
    
})



export const getOrderSeqBegin = () => ({
    type : GET_ORD_SEQ_LOADING
})

export const getOrderSeqSuccess = (data) => ({
    type : GET_ORD_SEQ_SUCCESS,
    data ,
})

export const getOrderSeqFailure = (err) => ({
    type : GET_ORD_SEQ_FAILURE,
   err
    
})


export const getRateCardBegin = () => ({
    type : GET_RATE_CARD_LOADING
})

export const getRateCardSuccess = (data) => ({
    type : GET_RATE_CARD_SUCCESS,
    data ,
})

export const getRateCardFailure = (err) => ({
    type : GET_RATE_CARD_FAILURE,
   err
    
})






export const editOrderSeqBegin = () => ({
    type : EDIT_ORD_SEQ_LOADING
})

export const editOrderSeqSuccess = (data) => ({
    type : EDIT_ORD_SEQ_SUCCESS,
    data ,
})

export const editOrderSeqFailure = (err) => ({
    type : EDIT_ORD_SEQ_FAILURE,
   err
    
})