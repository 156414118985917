import 'react-perfect-scrollbar/dist/css/styles.css';
import React , {useState , Component , useEffect} from 'react';
import Page from './components/Page';


import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {Provider , connect} from 'react-redux'
import { useRoutes , Route , BrowserRouter , Routes ,  } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from '../src/components/GlobalStyles';
import '../src/mixins/chartjs';
import theme from '../src/theme';
import routes from './Routes/routes';
import routes2 from './Routes/routes1';
import { Link as RouterLink, Navigate, useNavigate   } from 'react-router-dom';

import {
  Box,

  makeStyles,
  Container,
  Grid,

  Avatar,

  Button,
  Card

} from "@material-ui/core";


import history from './Config/history';


import PropTypes from 'prop-types'

import store from './Store/Store';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {


    padding: theme.spacing(2, 4, 3),
    
  },
}));
















const App = () => {

  const classes = useStyles();


  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  
















 
  const Loading1 = useSelector((state) => state.Home.loading );
  const Loading2 = useSelector((state) => state.GetCity.loading );
  const Loading3 = useSelector((state) => state.GetCountry.loading );
  const Loading4 = useSelector((state) => state.AddUsers.loading );
  const Loading5 = useSelector((state) => state.GetState.loading );
  const Loading6 = useSelector((state) => state.login.loading );
  const Loading7 = useSelector((state) => state.AddGallery.loading );
  const Loading8 = useSelector((state) => state.AddNewArrival.loading );
  const Loading9 = useSelector((state) => state.AddOfferCard.loading );
  const Loading10 = useSelector((state) => state.AddOrders.loading );
  const Loading11 = useSelector((state) => state.AddParty.loading );
  const Loading12 = useSelector((state) => state.AddSeq.loading );
  const Loading13 = useSelector((state) => state.AddStock.loading );
  const Loading14 = useSelector((state) => state.AddUpi.loading );
  const Loading15 = useSelector((state) => state.History.loading );
  const Loading16 = useSelector((state) => state.LooseStock.loading );
  const Loading17 = useSelector((state) => state.Ltd.loading );
  const Loading18 = useSelector((state) => state.ModifyOrder.loading );
  const Loading19 = useSelector((state) => state.Msgs.loading );
  const Loading20 = useSelector((state) => state.OutOfStock.loading );
  const Loading21 = useSelector((state) => state.PlaceOrder.loading );
  const Loading22 = useSelector((state) => state.RateCard.loading );
  const Loading23 = useSelector((state) => state.StockSummary.loading );




  
  useEffect( () => {


if(
     Loading1 === true || Loading2 === true || Loading3 === true || Loading4 === true || Loading5 === true 
  || Loading6 === true || Loading7 === true || Loading8 === true || Loading9 === true || Loading10 === true ||
   Loading11 === true || Loading12 === true || Loading13 === true || Loading14 === true || Loading15 === true ||
  Loading16 === true || Loading17 === true || Loading18 === true || Loading19 === true || Loading20 === true ||
  Loading21 === true || Loading22 === true || Loading23 === true 
  )
{
  setOpen(true);
  setTimeout(function(){ setOpen(false); }, 5000);
}
  else
  {
    setOpen(false);
  }


 

  
     
    }, [Loading1,Loading2,Loading3,Loading4,Loading5, Loading6])



  return (

   <>
   

   
<div>
      <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            disableBackdropClick={true}
            BackdropProps={{

              // invisible:true,
              

            }}
      >
              <Fade in={open}>
                <Loader type="Bars" color="#00BFFF" height={80} width={80} visible={true} />
              </Fade>
      </Modal>
    </div>
   
   
   </>
  );

};










// class Load extends Component {




//   constructor(props  ) {

  
//     super(props);
//     this.state = {

//        open : true
    
//         };







       

       
//   }


// UNSAFE_componentWillReceiveProps(nextProps)
// {
//  if(nextProps.Loading1 === true)
//  {
//    this.setState({open:true})
//   }
//   else
//   {
//     this.setState({open:false})

//  }
    
// }





//  handleOpen = () => {
//     this.setState({open:true})
//   };
  
//   handleClose = () => {
//     this.setState({open:false})
//   };

 




 

// componentDidMount()
// {
// }

// render(){

//   const classes = useStyles;

//   return (
//     <Page className={classes.root} title="Login">

//    <>
// <div>
//       <Modal
//             className={useStyles.modal}
//             open={true}
//             onClose={this.handleClose}
//             closeAfterTransition
//             BackdropComponent={Backdrop}
//             disableBackdropClick={true}
//             BackdropProps={{

//               invisible:true,
              

//             }}
//       >
//               <Fade in={this.state.open}>
//                 <Loader type="Bars" color="#00BFFF" height={80} width={80} visible={true} />
//               </Fade>
//       </Modal>
//     </div>
   
   

  
//    </>
//    </Page>
//   );
// };

// }

// const mapStateToProps = state => ({


//   Loading1 : state.Home.loading

 

// });

// export default connect(
//   mapStateToProps,
//   { }
// )(Load);








export default App;
