import {GET_PURCHASE_HISTORY_LOADING , GET_PURCHASE_HISTORY_SUCCESS , GET_PURCHASE_HISTORY_FAILURE, CLEAN_PURCHASE_HISTORY_DATA} from '../Actions/Types';
import {GET_SELL_HISTORY_LOADING , GET_SELL_HISTORY_SUCCESS , GET_SELL_HISTORY_FAILURE, CLEAN_SELL_HISTORY_DATA} from '../Actions/Types';


const History = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            selldata: [{}],
            sellerror: {},
            purchasedata: [{}],
            purchaseerror: {},
        }
    }


    
switch(action.type) {
    
    case CLEAN_PURCHASE_HISTORY_DATA:
        return {
          ...state,
          loading:false,
          purchasedata: [{}],
          purchaseerror: {},
        };
    case CLEAN_SELL_HISTORY_DATA:
        return {
          ...state,
          loading:false,
          selldata: [{}],
          sellerror: {},
        };
    case GET_SELL_HISTORY_LOADING:
        return {
          ...state,
         loading: true,
         selldata: [{}],
         sellerror: {},
        };
    case GET_SELL_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          sellerror: {},
          selldata: action.data,
        };
    case GET_SELL_HISTORY_FAILURE:
        return {
          ...state,
          loading: false,
          sellerror: action.error,
          selldata: [{}],
        };  
        
      case GET_PURCHASE_HISTORY_LOADING:
         return {
           ...state,
          loading: true,
          purchasedata: [{}],
          purchaseerror: {},
         };
     case GET_PURCHASE_HISTORY_SUCCESS:
         return {
           ...state,
           loading: false,
           purchaseerror: {},
           purchasedata: action.data,
         };
     case GET_PURCHASE_HISTORY_FAILURE:
         return {
           ...state,
           loading: false,
           purchaseerror: action.error,
           purchasedata: [{}],
         };  
    default:
        return state

}
}



export default History;
