
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { ltdBegin , ltdSuccess , ltdFailure } from './ActionCreators'

import { Base_URL } from '../Config/BaseUrlConfig'




export const ltd = (data) => {
       

      return (dispatch) => {
        dispatch(ltdBegin());

        axios.post(Base_URL + '/admin/loosetodispatch', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(ltdSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ltdFailure(err.response.data));

                }
            })
    }

}





