
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { addOrderSeqBegin ,addOrderSeqSuccess ,addOrderSeqFailure } from './ActionCreators'
import { getOrderSeqBegin , getOrderSeqSuccess , getOrderSeqFailure } from './ActionCreators'
import { editOrderSeqBegin , editOrderSeqSuccess , editOrderSeqFailure } from './ActionCreators'




import { Base_URL } from '../Config/BaseUrlConfig'




export const AddSeq = (data) => {
    

  
    

      return (dispatch) => {
        dispatch(addOrderSeqBegin());

        axios.post(Base_URL + '/admin/addOrdSeq', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(addOrderSeqSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addOrderSeqFailure(err.response.data));

                }
            })
    }

}


export const GetOrdSeq = () => {
    return (dispatch) => {
      dispatch(getOrderSeqBegin());

      axios.get(Base_URL + '/admin/getOrdSeq', )
          .then((res) => {
            //   console.log(res)
              dispatch(getOrderSeqSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(getOrderSeqFailure(err.response.data));

              }
          })
  }

}



export const EditSeq = (data) => {
    

  
    

    return (dispatch) => {
      dispatch(editOrderSeqBegin());

      axios.post(Base_URL + '/admin/updateOrderSeq', data)
          .then((res) => {
              // console.log(res.data)
              dispatch(editOrderSeqSuccess(res.data));
          }).catch((err) => {
              // console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(editOrderSeqFailure(err.response.data));

              }
          })
  }

}

