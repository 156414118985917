import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import tableIcons from '../../Config/IconsFile'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,

  makeStyles,
  Container,
  Grid,

  Avatar,
  TablePagination,
  Button,
  TextField,
  Card

} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {GetUsers, DelUsers , ChangePass} from '../../Actions/users'
import {CleanUsersData, CleanPlaceOrderData} from '../../Actions/ActionCreators'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {

    padding: theme.spacing(2, 4, 3),

    
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  
  const tableRef = React.createRef();  




  const listData = useSelector((state) =>
    state.AddUsers.getdata &&
    Object.keys(state.AddUsers.getdata) &&
    Object.keys(state.AddUsers.getdata).length &&
    Array.isArray(state.AddUsers.getdata.data)
      ? state.AddUsers.getdata.data
      : []
  );

  const CountD= useSelector((state) =>
    state.AddUsers.getdata &&
    Object.keys(state.AddUsers.getdata) &&
    Object.keys(state.AddUsers.getdata).length &&
    state.AddUsers.getdata.total_length
    ? parseInt(state.AddUsers.getdata.total_length)
      : 0
  );



  const CountF= useSelector((state) =>
    state.AddUsers.getdata &&
    Object.keys(state.AddUsers.getdata) &&
    Object.keys(state.AddUsers.getdata).length &&
    state.AddUsers.getdata.page
    ? parseInt(state.AddUsers.getdata.page)
      : 0
  );

  const [pagination , setPagination] = useState({
    per_page : 5,
    page : CountF,

  })




  const editmsg = useSelector(state => state.AddUsers && state.AddUsers.editdata && state.AddUsers.editdata.message ? state.AddUsers.editdata.message : null  )
  const editerror = useSelector(state => state.AddUsers && state.AddUsers.editerror && state.AddUsers.editerror.message ? state.AddUsers.editerror.message : null  )

  

  const ordermsg = useSelector(state => state.PlaceOrder && state.PlaceOrder.data && state.PlaceOrder.data.message ? state.PlaceOrder.data.message : null  )
  const ordererror = useSelector(state => state.PlaceOrder && state.PlaceOrder.error && state.PlaceOrder.error.message ? state.PlaceOrder.error.message : null  )

  
 
  const Delmsg = useSelector(state => state.AddUsers.deldata && state.AddUsers.deldata.message ? state.AddUsers.deldata.message : null  )
  const Delerror = useSelector(state => state.AddUsers.delerror && state.AddUsers.delerror.message ? state.AddUsers.delerror.message : null  )


  const pmsg = useSelector(state => state.AddUsers.passData && state.AddUsers.passData.message ? state.AddUsers.passData.message : null  )
  const perror = useSelector(state => state.AddUsers.passError && state.AddUsers.passError.message ? state.AddUsers.passError.message : null  )


useEffect( () => {

  var t  =  {page : pagination.page , per_page : pagination.per_page}
  dispatch(GetUsers(t))


    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg!==null && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror!==null && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(editmsg!==null && typeof(editmsg) === 'string')
      {
        tostS(editmsg)
      }
      if(editerror!==null && typeof(editerror) === 'string')
      {
          tostE(editerror)
      }
      if(ordermsg !== null && typeof(ordermsg) === 'string')
      {
        tostS(ordermsg)
      }
      if(ordererror !==null)
      {
        tostE(ordererror)
      }
      if(pmsg !==null && typeof(pmsg) === 'string')
      {
        tostS(pmsg)
      }
      if(perror!==null && typeof(perror) === 'string')
      {
          tostE(perror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(CleanUsersData())
    dispatch(CleanPlaceOrderData())
    })


    
  }, [ Delmsg  ,Delerror, editmsg, editerror,ordermsg, ordererror , pmsg , perror ])

  const handlePageChange = (e , x) =>{
    const parameter = {page : e , per_page : pagination.per_page}
    var u   = new Promise((resolve ,reject)=>{
      
      if(x !== null  && x !== undefined )
      {
        dispatch(GetUsers(parameter))
        
      }
      
      resolve()
      
    })
    u.then(()=>{
      
      setPagination({...pagination , ['page']  : e})
    })
  
  }
  
  const handleRPP = (e) =>{
    
    const parameter = {page : pagination.page , per_page : e}
  
    var u   = new Promise((resolve ,reject)=>{
      
     
        dispatch(GetUsers(parameter))
        
      
      
      resolve()
      
    })
    u.then(()=>{
      
      setPagination({...pagination , ['per_page']  : e})

    })
  
  }
  
  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/users/edit/'+olData.id , { state: olData })
}


const onDel = (oldata) =>{

  dispatch(DelUsers(oldata.id ,oldata.tableData.id ))
}
 





const PlaceOrder = (olData) =>{
  navigate('/app/users/placeorder/'+olData.id, {state:olData})
}

  


  const addNewBornbutton = () =>{
    navigate('/app/users/add')
    
  }
  const [open2, setOpen2] = React.useState(false);

  const [modify, setModify] = useState({
   id:"",
   password : ""
  })

  const onChangePaass = (olData) =>{
    setOpen2(true);
    setModify({
      ...modify,    
      ['id']:olData.id,
      ['password']:olData.password
    })
  
  }

  const handleChangeModify = (event) =>{
    setModify({
      ...modify,
      [event.target.name]: event.target.value,
    });
  }


  const handleSubmit = () => {
    if(modify.password !=="" ){
      dispatch(ChangePass(modify));
      console.log(modify.password)
    }else{
      tostW("Password can't be Blank!!")
    }
    
    setModify({
      ...modify,    
      ['password']:'', 
    })
    setOpen2(false);
  
  };
  


  const handleClose2 = () => {
    setModify({
      ...modify,    
      ['password']:'', 
    })
    setOpen2(false);
  };



  return (
    <>
      <Page className={classes.root} title="Users">

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Pasword</DialogTitle>
        <DialogContent>         
          <TextField
            fullWidth
            id="Password"
            label="Password"
            type="number"
            name="password"
            value={modify.password}
            onChange={handleChangeModify}
            required
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>


    
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Users
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
              icons={tableIcons}    
              tableRef={tableRef}                    
              title="Added Person"
              columns={[
                { title: 'ID', field: 'id',editable: "never", },
                { title: 'Name', field: 'name',editable: "never", },
                { title: 'Mobile', field: 'mobile',editable: "never", },
                
                // { title: 'Email', field: 'email',editable: "never", },     
                { title: 'Address', field: 'address',editable: "never", },     
                { title: 'Name of Firm', field: 'name_of_firm',editable: "never", },     
                // { title: 'Is Verified', field: 'is_verified',editable: "never",
                // render: rowData => (
                //     rowData.is_verified === true ? "Yes" : "No"
                //   ), },     
                  {title: 'Action',
                  render: rowData => (
                    <Grid container className={classes.root2}>
                      <Grid item xs={4}>
                          
                      <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                      </Grid>
                      <Grid item xs={4}>
                        <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
                      </Grid>
                      <Grid item xs={4}>
                        <VpnKeyIcon onClick={()=>{onChangePaass(rowData)}} style={{cursor:"pointer"}} />
                      </Grid>
              
                    </Grid>
                  ),},
                { title: 'Place Order', 
                    render: rowData => (
                    
                    <ShoppingCartIcon onClick={()=>{PlaceOrder(rowData)}} style={{cursor:"pointer"}} />
                  
                    )
                }

              ]}
              data={listData}
              options={{
                exportButton: true,

                      selection: false,
                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" },
                      pageSizeOptions:[5,10,20,50,CountD],    // rows selection options

                    }}
              components={{
                Pagination: props => (
                            <TablePagination
                                {...props}                                      
                                count={CountD}
                                page={CountF}
                              
                              />
                    ),
              }}
            onChangeRowsPerPage={(e)=>handleRPP(e)}
            onChangePage={(e)=>handlePageChange(e , tableRef.current && tableRef.current)}              

            />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
