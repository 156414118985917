import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";



import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import tableIcons from '../../Config/IconsFile'
import CancelIcon from '@material-ui/icons/Cancel';

import { AddBox, ArrowDownward } from '@material-ui/icons'

import {GetPurchaseHistory, GetSellHistory } from '../../Actions/history'
import { CleanPurchaseHistoryData, CleanSellHistoryData } from "../../Actions/ActionCreators";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rootn: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },

  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [tvalue, setTValue] = React.useState(0);
 
  const tableRef = React.createRef();  
  
  
  const sellData = useSelector((state) =>
    state.History &&
      Object.keys(state.History.selldata) &&
      Object.keys(state.History.selldata).length &&
      Array.isArray(state.History.selldata.data)
      ? state.History.selldata.data
      : []
  );

  const purchaseData = useSelector((state) =>
    state.History &&
      Object.keys(state.History.purchasedata) &&
      Object.keys(state.History.purchasedata).length &&
      Array.isArray(state.History.purchasedata.data)
      ? state.History.purchasedata.data
      : []
  );


  const PCountD= useSelector((state) =>
  state.History &&
      Object.keys(state.History.purchasedata) &&
      Object.keys(state.History.purchasedata).length &&
      Array.isArray(state.History.purchasedata.data) &&
      state.History.purchasedata.total_length
      ? parseInt(state.History.purchasedata.total_length)
      : 0
  );



  const PCountF= useSelector((state) =>
  state.History &&
      Object.keys(state.History.purchasedata) &&
      Object.keys(state.History.purchasedata).length &&
      Array.isArray(state.History.purchasedata.data) &&
      state.History.purchasedata.page
      ? parseInt(state.History.purchasedata.page)
      : 0
  );
  
  const SCountD= useSelector((state) =>
  state.History &&
      Object.keys(state.History.selldata) &&
      Object.keys(state.History.selldata).length &&
      Array.isArray(state.History.selldata.data) &&
      state.History.selldata.total_length
      ? parseInt(state.History.selldata.total_length)
      : 0
  );



  const SCountF= useSelector((state) =>
  state.History &&
  Object.keys(state.History.selldata) &&
  Object.keys(state.History.selldata).length &&
  Array.isArray(state.History.selldata.data) &&
  state.History.selldata.page
  ? parseInt(state.History.selldata.page)
  : 0
  );
  
  const [PurchasePagination , setPurchasePagination] = useState({
    per_page : 5,
    page : PCountF,

  })
  
  const [SellPagination , setSellPagination] = useState({
    per_page : 5,
    page : SCountF,

  })
  
  
  useEffect(() => {
    if(tvalue===0){
      const t  =  {page : PurchasePagination.page , per_page : PurchasePagination.per_page}
      dispatch(GetPurchaseHistory(t));
      dispatch(CleanSellHistoryData());

    }else{
      const t  =  {page : SellPagination.page , per_page : SellPagination.per_page}
      dispatch(GetSellHistory(t))
      dispatch(CleanPurchaseHistoryData())

    }


  }, [tvalue])


  const handlePageChangePurchase = (e , x) =>{
    const parameter = {page : e , per_page : PurchasePagination.per_page}
    var u   = new Promise((resolve ,reject)=>{
      
      if(x !== null  && x !== undefined )
      {
        dispatch(GetPurchaseHistory(parameter))
        
      }
      
      resolve()
      
    })
    u.then(()=>{
      
      setPurchasePagination({...PurchasePagination , ['page']  : e})
    })
  
  }
  
  const handleRPPurchase = (e) =>{
    
    const parameter = {page : PurchasePagination.page , per_page : e}
  
    var u   = new Promise((resolve ,reject)=>{
      
     
        dispatch(GetPurchaseHistory(parameter))
        
      
      
      resolve()
      
    })
    u.then(()=>{
      
      setPurchasePagination({...PurchasePagination , ['per_page']  : e})

    })
  
  }
  


  const handlePageChangeSell = (e , x) =>{
    const parameter = {page : e , per_page : SellPagination.per_page}
    var u   = new Promise((resolve ,reject)=>{
      
      if(x !== null  && x !== undefined )
      {
        dispatch(GetSellHistory(parameter))
        
      }
      
      resolve()
      
    })
    u.then(()=>{
      
      setSellPagination({...SellPagination , ['page']  : e})
    })
  
  }
  
  const handleRPSell = (e) =>{
    
    const parameter = {page : SellPagination.page , per_page : e}
  
    var u   = new Promise((resolve ,reject)=>{
      
     
        dispatch(GetSellHistory(parameter))
        
      
      
      resolve()
      
    })
    u.then(()=>{
      
      setSellPagination({...SellPagination , ['per_page']  : e})

    })
  
  }
  

  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
    if(tvalue===0){
      const parameter = {page : PurchasePagination.page , per_page : PurchasePagination.per_page}
      dispatch(GetPurchaseHistory(parameter));
      dispatch(CleanSellHistoryData());

    }else{
      const parameter = {page : SellPagination.page , per_page : SellPagination.per_page}
      dispatch(GetSellHistory(parameter))
      dispatch(CleanPurchaseHistoryData())

    }
  };


  return (
    <>
      <Page className={classes.root} title="History">
        <ToastContainer />
        
        <div className={classes.rootn}>
          <AppBar position="static">

            <Tabs value={tvalue} onChange={handleChangetab} aria-label="simple tabs example">
              <Tab label="Purchase History" {...a11yProps(0)} />
              <Tab label="Sell History" {...a11yProps(1)} />              

            </Tabs>

          </AppBar>

          {/* Purchase History */}

          <TabPanel value={tvalue} index={0}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    tableRef={tableRef}

                    icons={tableIcons}
                    title="Purchase History"
                    columns={[

                      { title: 'Party Name', field: 'name', editable: "never", },
                      {
                        title: 'Order Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.timestamp).format("DD-MM-YYYY")
                        ),
                      },
                      { title: 'Card Number', field: 'card_number', editable: "never", },
                      { title: 'Card Type', field: 'card_type', editable: "never", },
                      { title: 'Quantity', field: 'quantity', editable: "never", },
                      { title: 'Price', field: 'price', editable: "never", },
                      { title: 'Discount', field: 'discount', editable: "never", },
                      
                    ]}
                    data={purchaseData}
                    options={{
                      selection: false,
                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" },
                    }}
                    components={{
                            Pagination: props => (
                                        <TablePagination
                                            {...props}                                      
                                            count={PCountD}
                                            page={PCountF}
                                          
                                          />
                                ),
                          }}

                  onChangeRowsPerPage={(e)=>handleRPPurchase(e)}
                  onChangePage={(e)=>handlePageChangePurchase(e , tableRef.current && tableRef.current)}              



                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>

          {/* Sell History */}

          <TabPanel value={tvalue} index={1}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Sell History"
                    tableRef={tableRef}                    
                    columns={[
                      
                      { title: 'Card Number', field: 'card_number', editable: "never", },
                      { title: 'Quantity', field: 'quantity', editable: "never", },
                      

                      {
                        title: 'Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.timestamp).format("DD-MM-YYYY")
                        ),
                      },
                      { title: 'Price', field: 'price', editable: "never", },
                      { title: 'Firm Name', field: 'name_of_firm', editable: "never", },
                      
                    ]}
                    data={sellData}
                    options={{
                      selection: false,
                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" },
                    }}
                    components={{
                      Pagination: props => (
                                  <TablePagination
                                      {...props}                                      
                                      count={SCountD}
                                      page={SCountF}
                                    
                                    />
                          ),
                    }}
                  onChangeRowsPerPage={(e)=>handleRPSell(e)}
                  onChangePage={(e)=>handlePageChangeSell(e , tableRef.current && tableRef.current)}              

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>          
        </div>

      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
