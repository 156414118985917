import React ,  {useRef , useState , useEffect , Component} from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {Navigate, useLocation , useNavigate} from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../Config/Toast'
import {ToastContainer} from 'react-toastify'




import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';





import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {GetParty} from '../../Actions/Party'
import {EditStock} from '../../Actions/Stock'
import {GetSummary} from '../../Actions/StockSummary'
import {CleanStockData} from '../../Actions/ActionCreators'
import { conforms } from 'lodash-es';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Card Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  }

}));





const AddBanners = ({ className, ...rest }) => {



 





 

  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();



  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  price :state.price,
  quantity :state.quantity,
  discount:state.discount,
  card_number:state.card_number,
  card_type:state.card_type,
  remark:state.remark,
  other_detail:state.other_detail,
  is_stock_out:state.is_stock_out,
  show_in_gallery:state.show_in_gallery,
  show_in_new_arrivals:state.show_in_new_arrivals,
  show_in_offer_cards:state.show_in_offer_cards,
  party_id:state.party_id,
  img : "",
  images:state.images && state.images.length && Array.isArray(state.images) ? state.images[0] : "",

  fixImg : "/static/images/avatars/upload.png",
  o_stock : "",
  c_stock : "",
  full_stock:[]
  });




  





 const handleSubmit = () =>{
   window.print()

   }


 

   const handl = (c) =>{
if(values.o_stock === "")
{

  var  l = c.length
        for(let i = 0 ;  i < c.length ; i++)
        {
          if(c[i].remark === 'Opening Stock'  && c.length === 1 )
          {
            setValues({...values , ['o_stock']: c[i].new_stock , ['c_stock']: c[i].new_stock})
            break;
          }
          else if(c[i].remark === 'Opening Stock' )
          {
            console.log("test 4")
            setValues({...values , ['o_stock']: c[i].new_stock , ['c_stock'] : c[l-1].new_stock})
            break;

          }
          else{
            console.log("gh")
          }
        }
}
  
  }


const SumData = useSelector(state => state.StockSummary && state.StockSummary.getdata && state.StockSummary.getdata.length && Array.isArray(state.StockSummary.getdata) ? handl(state.StockSummary.getdata) : [])
const fullData = useSelector(state => state.StockSummary && state.StockSummary.getdata && state.StockSummary.getdata.length && Array.isArray(state.StockSummary.getdata) ? state.StockSummary.getdata : [])
const SumLoad = useSelector(state => state.StockSummary.loading)
 





useEffect( () => {
  
          
          dispatch(GetSummary(values.card_number))
     
    }, [ values  ])





  return (
    <Page
      className={classes.root}
      title="Add Gallery Items"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root , className )}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Stock Summary" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>            
            
          <Grid item md={6} xs={12}>
              <h4>Card Number : {values.card_number}</h4>
            </Grid>
            <Grid item md={6} xs={12}>
              <h4>Card Type : {values.card_type}</h4>
            </Grid>
            <Grid item md={6} xs={12}>
            <h4>Opening Stock : {values.o_stock}</h4>
            </Grid>
            <Grid item md={6} xs={12}>
              <h4>Closing Stock : {values.c_stock}</h4>
            </Grid>
          
            


            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>

            <StyledTableCell>Remark/Details</StyledTableCell>
            <StyledTableCell align="right" >Credit</StyledTableCell>
            <StyledTableCell align="right">Debit</StyledTableCell>
            <StyledTableCell align="right">Balance / Updated Stock</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {fullData.length !== 0 ?
          <>
          {fullData.map((row ,i) => (
            <StyledTableRow key={i}>
           
              <StyledTableCell align="left">{row.remark}</StyledTableCell>
              <StyledTableCell align="right" style={{color:"green" , fontWeight:"bold"}}>{row.incre_stock !== '0' || 0 ? "+  " + row.incre_stock : row.incre_stock }</StyledTableCell>
              <StyledTableCell align="right" style={{color:"red", fontWeight:"bold"}}>{ row.decre_stock  !== '0' || 0 ? "-  " +  row.decre_stock : row.decre_stock}</StyledTableCell>
              <StyledTableCell align="right">{row.new_stock}</StyledTableCell>
            </StyledTableRow>
          ))}
          </>
          : null}
        </TableBody>
      </Table>
    </TableContainer>
           
            
           
           
          </Grid>
        </CardContent>
        <Divider />
        {/* <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box> */}
      </Card>
    </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};




export default connect()(AddBanners);


