
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { AddPartyBegin , AddPartySuccess , AddPartyFailure } from './ActionCreators'
import { GetPartyBegin , GetPartySuccess , GetPartyFailure } from './ActionCreators'
import { EditPartyBegin , EditPartySuccess , EditPartyFailure } from './ActionCreators'
import { DelPartyBegin , DelPartySuccess , DelPartyFailure } from './ActionCreators'



import { Base_URL } from '../Config/BaseUrlConfig'




export const AddParty = (data) => {
       

      return (dispatch) => {
        dispatch(AddPartyBegin());

        axios.post(Base_URL + '/admin/addparty', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(AddPartySuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(AddPartyFailure(err.response.data));

                }
            })
    }

}


export const GetParty = () => {
   
    return (dispatch) => {
      dispatch(GetPartyBegin());

      axios.get(Base_URL + '/admin/getparty')
          .then((res) => {
             dispatch(GetPartySuccess(res.data));
             console.log(res.data,'**********')
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetPartyFailure(err.response.data));

              }
          })
  }

}


export const EditParty = (data) => {   
    
    return (dispatch) => {
        dispatch(EditPartyBegin());

        
        axios.post(Base_URL + '/admin/editParty' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(EditPartySuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(EditPartyFailure(err.response.data));

                }
            })
    }
}

export const DelParty = (x) => {

    return (dispatch) => {
        dispatch(DelPartyBegin());

        
        axios.get(Base_URL + '/admin/delParty' , {params :{id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(DelPartySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(DelPartyFailure(err.response.data));

                }
            })
    }
}