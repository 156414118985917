import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import VisibilityIcon from '@material-ui/icons/Visibility';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import tableIcons from '../../Config/IconsFile'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,

  makeStyles,
  Container,
  Grid,

  Avatar,

  Button,
  TextField,
  Card

} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {GetUsers, DelUsers , ChangePass} from '../../Actions/users'
import {AddSeq , GetOrdSeq} from '../../Actions/BillSequence'
import {CleanUsersData, CleanPlaceOrderData  ,cleanOrderSeq} from '../../Actions/ActionCreators'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {

    padding: theme.spacing(2, 4, 3),

    
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  




  const listData = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) && 
  state.AddSeq.getdata.length 
      ? state.AddSeq.getdata[0].total_seq_gen
      : []
  );


  const current_order_seq_data  = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) &&
  state.AddSeq.getdata.length
      ?  state.AddSeq.getdata[0].current_order_seq
      : "NA"
  );

  const last_order_seq_data  = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) &&
  state.AddSeq.getdata.length
      ?  state.AddSeq.getdata[0].last_order_seq
      : "NA"
  );




  const Addmsg = useSelector(state => state.AddSeq && state.AddSeq.data && state.AddSeq.data.message ? state.AddSeq.data.message : null  )
  const Adderror = useSelector(state => state.AddSeq && state.AddSeq.error && state.AddSeq.error.message ? state.AddSeq.error.message : null  )

  

 


useEffect( () => {

    dispatch(GetOrdSeq())


    let x  = new Promise((resolve ,reject)=>{
      if(Addmsg!==null && typeof(Addmsg) === 'string')
      {
        tostS(Addmsg)
      }
      if(Adderror!==null && typeof(Adderror) === 'string')
      {
          tostE(Adderror)
      }
     
      resolve()

    })
    x.then(()=>{
    dispatch(cleanOrderSeq())
    dispatch(GetOrdSeq())

    })


    
  }, [ Addmsg , Adderror ])


  const navigate = useNavigate();





 







  


 
  const [open2, setOpen2] = React.useState(false);

  const [modify, setModify] = useState({
   current_order_seq : ""
  })

  const onChangePaass = (olData) =>{
    setOpen2(true);
    setModify({
      ...modify,    
      ['current_order_seq']:""
    })
  
  }

  const handleChangeModify = (event) =>{
    setModify({
      ...modify,
      [event.target.name]: event.target.value,
    });
  }


  const handleSubmit = () => {
    if(modify.current_order_seq !=="" ){
      dispatch(AddSeq(modify));
      console.log(modify.current_order_seq)
    }else{
      tostW("current order seq can't be Blank!!")
    }
    
    setModify({
      ...modify,    
      ['current order seq']:'', 
    })
    setOpen2(false);
  
  };
  


  const handleClose2 = () => {
    setModify({
      ...modify,    
      ['current_order_seq']:'', 
    })
    setOpen2(false);
  };


  const addNewBornbutton = () =>{

  }


  return (
    <>
      <Page className={classes.root} title="Users">

      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add/Modify Order Sequence</DialogTitle>
        <DialogContent>         
          <TextField
            fullWidth
            id="current_order_seq"
            label="Orders Sequence"
            type="number"
            name="current_order_seq"
            value={modify.current_order_seq}
            onChange={handleChangeModify}
            required
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>


    
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid item md={4} xs={4}>
              <TextField
            fullWidth
            id="current_order_seq"
            label="Current Sequence"
        
            name="current_order_seq"
            disabled
            value={current_order_seq_data}
            required
            variant="outlined"
          />
                  </Grid>
              <Grid item md={4} xs={4}>
              <TextField
            fullWidth
            id="current_order_seq"
            label="Last Bill Number"
        
            disabled
            name="current_order_seq"
            value={last_order_seq_data}
          
            required
            variant="outlined"
          />
                  </Grid>
              <Grid item md={4} xs={4}>
              <Button onClick={onChangePaass} color="primary" variant="contained">
                Add/Modify Sequence
              </Button>
           
                  </Grid>
                  </Grid>
           
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Bill Seq"
      columns={[
        { title: 'ID', field: 'id',editable: "never", },
        { title: 'Bill Number', field: 'number',editable: "never", },
        {
            title: 'Bill Date', field: "date", editable: "never",
            render: rowData => (
              moment(rowData.data).format("DD/MM/YYYY")
            ),
          },
       
          
       

      ]}
      data={listData}
        options={{
          exportButton: true,

                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
