import {ADD_ORD_SEQ_LOADING , ADD_ORD_SEQ_SUCCESS , ADD_ORD_SEQ_FAILURE} from '../Actions/Types';
import {GET_ORD_SEQ_LOADING , GET_ORD_SEQ_SUCCESS , GET_ORD_SEQ_FAILURE} from '../Actions/Types';
import {EDIT_ORD_SEQ_LOADING , EDIT_ORD_SEQ_SUCCESS , EDIT_ORD_SEQ_FAILURE} from '../Actions/Types';


import {CLEAN_ALL_DATA , CLEAN_UPI_DATA , CLEAN_ORD_SEQ} from '../Actions/Types';



const AddSeq = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata : [{}],
            editerror : {}
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata : [{}],
            editerror : {}
        }

    case CLEAN_ORD_SEQ:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                editdata : [{}],
            editerror : {}
             
                
            }
    

    case ADD_ORD_SEQ_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_ORD_SEQ_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_ORD_SEQ_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.err,
          data: [{}],
        };
      
    case GET_ORD_SEQ_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_ORD_SEQ_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_ORD_SEQ_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.err,
          getdata: [{}],
        };

    case EDIT_ORD_SEQ_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_ORD_SEQ_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_ORD_SEQ_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.err,
          editdata: [{}],
        };
          
    

    default:
        return state

}
}



export default AddSeq;
