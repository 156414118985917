import {GET_COUNTRY_LOADING , GET_COUNTRY_SUCCESS , GET_COUNTRY_FAILURE} from '../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_COUNTRY_DATA} from '../Actions/Types';



const GetCountry = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getdata: [{}],
            geterror: {},



        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},
            
        }

    case CLEAN_COUNTRY_DATA:
        return {
                ...state,
                loading:false,
                getdata: [{}],
                geterror: {},
                
            }
    

        case GET_COUNTRY_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_COUNTRY_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_COUNTRY_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };
      
    default:
        return state

}
}



export default GetCountry;
