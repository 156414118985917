import {ADD_ORDERS_LOADING , ADD_ORDERS_SUCCESS , ADD_ORDERS_FAILURE} from '../Actions/Types';
import {GET_ORDERS_LOADING , GET_ORDERS_SUCCESS , GET_ORDERS_FAILURE} from '../Actions/Types';
import {EDIT_ORDERS_LOADING , EDIT_ORDERS_SUCCESS , EDIT_ORDERS_FAILURE} from '../Actions/Types';
import {DEL_ORDERS_LOADING , DEL_ORDERS_SUCCESS , DEL_ORDERS_FAILURE} from '../Actions/Types';
import { CHANGE_ORDER_STATUS_LOADING, CHANGE_ORDER_STATUS_SUCCESS, CHANGE_ORDER_STATUS_FAILURE } from '../Actions/Types';
import { ADD_BILTI_LOADING , ADD_BILTI_SUCCESS , ADD_BILTI_FAILURE } from '../Actions/Types';
import {GET_P_BILLS_LOADING  , GET_P_BILLS_SUCCESS , GET_P_BILLS_FAILURE } from '../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_ORDERS_DATA} from '../Actions/Types';



const AddOrders = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            statusdata: [{}],
            statuserror: {},
            biltiData : [{}],
            biltiErr : {},
            BillsData:[{}],
            BillsError : {}
             
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            biltiData : [{}],
            biltiErr : {},
            BillsData:[{}],
            BillsError:{}
        }

    case CLEAN_ORDERS_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                statusdata: [{}],
                statuserror: {},
                biltiData : [{}],
                biltiErr : {},
                BillsData:[{}],
                BillsError:{}
                
            }
    

    case ADD_ORDERS_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_ORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_ORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
      
    case GET_ORDERS_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_ORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_ORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };
          
    case EDIT_ORDERS_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_ORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_ORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };

    case DEL_ORDERS_LOADING:
        return {
          ...state,
          loading: true,
          delerror: {},
          deldata:[{}],
        };
    case DEL_ORDERS_SUCCESS:
        return {
          ...state,
          loading: false,
          delerror: {},
          deldata: action.data,
        };
    case DEL_ORDERS_FAILURE:
        return {
          ...state,
          loading: false,
          delerror: action.error,
          deldata: [{}],
        };

    case CHANGE_ORDER_STATUS_LOADING:
        return {
          ...state,
          loading: true,
          statuserror: {},
          statusdata:[{}],
        };
    case CHANGE_ORDER_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          statuserror: {},
          statusdata: action.data,
        };
    case CHANGE_ORDER_STATUS_FAILURE:
        return {
          ...state,
          loading: false,
          statuserror: action.error,
          statusdata: [{}],
        };


        case ADD_BILTI_LOADING:
          return {
            ...state,
            loading: true,
            biltiErr: {},
            biltiData:[{}],
          };
      case ADD_BILTI_SUCCESS:
          return {
            ...state,
            loading: false,
            biltiErr: {},
            biltiData: action.bdata,
          };
      case ADD_BILTI_FAILURE:
          return {
            ...state,
            loading: false,
            biltiErr: action.berr,
            biltiData: [{}],
          };



          case GET_P_BILLS_LOADING:
            return {
              ...state,
              loading: true,
              BillsError: {},
              BillsData:[{}],
            };
        case GET_P_BILLS_SUCCESS:
            return {
              ...state,
              loading: false,
              BillsError: {},
              BillsData: action.getdata,
            };
        case GET_P_BILLS_FAILURE:
            return {
              ...state,
              loading: false,
              BillsError: action.geterror,
              BillsData: [{}],
            };
  

      
    default:
        return state

}
}



export default AddOrders;
