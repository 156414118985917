
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { AddUsersBegin , AddUsersSuccess , AddUsersFailure } from './ActionCreators'
import { GetUsersBegin , GetUsersSuccess , GetUsersFailure } from './ActionCreators'
import { EditUsersBegin , EditUsersSuccess , EditUsersFailure } from './ActionCreators'
import { DelUsersBegin , DelUsersSuccess , DelUsersFailure } from './ActionCreators'
import { ChangePassBegin , ChangePassSuccess , ChangePassFailure } from './ActionCreators'



import { Base_URL } from '../Config/BaseUrlConfig'




export const AddUsers = (data) => {
       

      return (dispatch) => {
        dispatch(AddUsersBegin());

        axios.post(Base_URL + '/admin/adduser', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(AddUsersSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(AddUsersFailure(err.response.data));

                }
            })
    }

}


export const GetUsers = (data) => {
    return (dispatch) => {
      dispatch(GetUsersBegin());

      axios.get(Base_URL + '/admin/getusers', {params:data})
          .then((res) => {
            //   console.log(res)
              dispatch(GetUsersSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetUsersFailure(err.response.data));

              }
          })
  }

}


export const EditUsers = (data) => {   
    
    return (dispatch) => {
        dispatch(EditUsersBegin());

        
        axios.post(Base_URL + '/admin/editusers' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(EditUsersSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(EditUsersFailure(err.response.data));

                }
            })
    }
}


export const ChangePass = (data) => {   
    
    return (dispatch) => {
        dispatch(ChangePassBegin());

        
        axios.post(Base_URL + '/admin/changePass' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(ChangePassSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ChangePassFailure(err.response.data));

                }
            })
    }
}

export const DelUsers = (x) => {

    return (dispatch) => {
        dispatch(DelUsersBegin());

        
        axios.get(Base_URL + '/admin/delusers' , {params :{id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(DelUsersSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(DelUsersFailure(err.response.data));

                }
            })
    }
}