
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { AddStockBegin , AddStockSuccess , AddStockFailure } from './ActionCreators'
import { GetStockBegin , GetStockSuccess , GetStockFailure } from './ActionCreators'
import { EditStockBegin , EditStockSuccess , EditStockFailure } from './ActionCreators'
import { DelStockBegin , DelStockSuccess , DelStockFailure } from './ActionCreators'

import {UpdateStockVisibilityBegin, UpdateStockVisibilitySuccess, UpdateStockVisibilityFailure} from './ActionCreators'

import {ModifyStockBegin, ModifyStockSuccess, ModifyStockFailure} from './ActionCreators';

import { Base_URL } from '../Config/BaseUrlConfig'




export const AddStock = (data) => {
       
      var formData = new FormData()

      formData.append('admin_id', data.admin_id);
      formData.append('price', data.price);
      formData.append('quantity', data.quantity);
      formData.append('discount', data.discount);
      formData.append('card_number', data.card_number);
      formData.append('card_type', data.card_type);
      formData.append('remark', data.remark);
      formData.append('other_detail', data.other_detail);
      formData.append('is_stock_out', data.is_stock_out);
      formData.append('show_in_gallery', data.show_in_gallery);
      formData.append('show_in_new_arrivals', data.show_in_new_arrivals);
      formData.append('show_in_offer_cards', data.show_in_offer_cards);
      formData.append('party_id', data.party_id);
      formData.append('rate', data.rate);
      formData.append('images', data.images);
      formData.append('img', data.img);
      return (dispatch) => {
        dispatch(AddStockBegin());

        axios.post(Base_URL + '/admin/addtostock', formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(AddStockSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(AddStockFailure(err.response.data));

                }
            })
    }

}


export const GetStock = (data) => {
    return (dispatch) => {
      dispatch(GetStockBegin());

      axios.get(Base_URL + '/admin/getstock',{params:data} )
          .then((res) => {
            //   console.log(res)
              dispatch(GetStockSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetStockFailure(err.response.data));

              }
          })
  }

}


export const ModifyStock = (data) => {   

    console.log(data)
    
    return (dispatch) => {
        dispatch(ModifyStockBegin());

        
        axios.post(Base_URL + '/admin/ModifyStock' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(ModifyStockSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ModifyStockFailure(err.response.data));

                }
            })
    }
}

export const EditStock = (data) => { 


    console.log(data)


    var formData = new FormData()

    formData.append('admin_id', data.admin_id);
    formData.append('price', data.price);
    formData.append('purchase_id', data.purchase_id);
    formData.append('quantity', data.quantity);
    formData.append('discount', data.discount);
    formData.append('card_number', data.card_number);
    formData.append('card_type', data.card_type);
    formData.append('remark', data.remark);
    formData.append('other_detail', data.other_detail);
    formData.append('is_stock_out', data.is_stock_out);
    formData.append('show_in_gallery', data.show_in_gallery);
    formData.append('show_in_new_arrivals', data.show_in_new_arrivals);
    formData.append('show_in_offer_cards', data.show_in_offer_cards);
    formData.append('party_id', data.party_id);
    formData.append('rate', data.rate);
    formData.append('images', data.images);
    formData.append('img', data.img);
    
    

    
    return (dispatch) => {
        dispatch(EditStockBegin());

        
        axios.post(Base_URL + '/admin/editStock' , formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(EditStockSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(EditStockFailure(err.response.data));

                }
            })
    }
}

export const DelStock = (x) => {

    return (dispatch) => {
        dispatch(DelStockBegin());

        
        axios.get(Base_URL + '/admin/delfromstock' , {params :{purchase_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(DelStockSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(DelStockFailure(err.response.data));

                }
            })
    }
}

export const UpdateStockVisibility = (data) => {
    
   return (dispatch) => {
       dispatch(UpdateStockVisibilityBegin());

       
       axios.post(Base_URL + '/admin/updateStockVisible' , data)
           .then((res) => {
               console.log(res.data)
               dispatch(UpdateStockVisibilitySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                //    alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(UpdateStockVisibilityFailure(err.response.data));

               }
           })
   }
}