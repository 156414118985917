import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import {tostS  , tostW , tostE } from '../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import MaterialTable from 'material-table';
import tableIcons from '../../Config/IconsFile'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import CardView from './CardComponent';


import {GetCardData, PlaceOrder} from '../../Actions/PlaceOrder'
import {CleanPlaceOrderData} from '../../Actions/ActionCreators'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Card Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  }

}));

const AddBanners = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const {state} = useLocation();

  const id = useSelector(state => state.login.data.admin_id);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

  };


  const [values, setValues] = useState({
    user_id:state.id,
    mobile:state.mobile,
    order_status:0,
    name:state.name,
    flag:true,
    order_details:[],
    temp  :  0
    
  });
  
  const [inputList, setInputList] = useState([{card_number:"", quantity:"", price:"", discount:"", cardDetail:false, remark:""  , getData : []}])
  const setData = (x) =>{
    console.log(x)
    console.log(Object.keys(x).length)
   
    if (x && Object.keys(x).length){
      inputList[values.temp].cardDetail = true;
      inputList[values.temp].discount = x.discount;
      inputList[values.temp].price = x.price;
      inputList[values.temp].getData =  x
     
      console.log(inputList, "*********inputlist***********")
      dispatch(CleanPlaceOrderData())
    
  }
  
  }
  

  const carddata = useSelector((state) => state.PlaceOrder.cardData && state.PlaceOrder.cardData.obj && state.PlaceOrder.cardData.obj[0] ?setData(state.PlaceOrder.cardData.obj[0]) :{});
  const cardErr = useSelector((state) => state.PlaceOrder.cardError && state.PlaceOrder.cardError.message  ?state.PlaceOrder.cardError.message :null);
console.log("_________________")
console.log(carddata)
console.log("_________________")




  
  const handleGetCard = (i) =>{
    setValues({...values , ['temp']:i})
    if(inputList[i].card_number === "")
    {
      alert("Please Enter Card Number")
    }
    else if(inputList.length > 1)
    {
      console.log("66666666666666666666 - 0")
console.log(inputList)

      var flag = true ;
      for(let j = 0  ; j< inputList.length  ; j++)
      {
        if(inputList[j].getData.card_number === inputList[i].card_number )
        {
          console.log("66666666666666666666 - 1")

          alert("This card Number has already used")
          flag = false;
          break;
        }
      }
      if (flag)
      {
        dispatch(GetCardData(inputList[i].card_number)); 
      }
    }
    else
    {
      console.log("66666666666666666666")


    
      dispatch(GetCardData(inputList[i].card_number));

 
  }
}




  const ordermsg = useSelector(state => state.PlaceOrder && state.PlaceOrder.data && state.PlaceOrder.data.message ? state.PlaceOrder.data.message : null  )
  const ordererror = useSelector(state => state.PlaceOrder && state.PlaceOrder.error && state.PlaceOrder.error.message ? state.PlaceOrder.error.message : null  )

  
useEffect( () => {

    // dispatch(GetNewArrival())

    dispatch(CleanPlaceOrderData())
    let x  = new Promise((resolve ,reject)=>{
      
      if(ordermsg !== null && typeof(ordermsg) === 'string')
      {
        tostS(ordermsg)
      }
      if(ordererror !==null)
      {
        tostE(ordererror)
      }
      if(cardErr !== null){
        tostE(cardErr)



      }


      resolve()

    })
    x.then(()=>{

    
    
    
    dispatch(CleanPlaceOrderData())
    })


    
  }, [ ordermsg, ordererror , cardErr])


  const navigate = useNavigate();

  

  const handlePlaceOrder = () => {

    const ls = [...inputList];
    setOpen(true);
    setValues({...values, order_details:ls});

    console.log(values, "**********finalvalues*****************")
  }



  const handleConfirm = () =>{
    console.log(values, "**********finalValuesConfirm*****************")
    if(values.order_details.length && values.order_details.filter(oq => oq.quantity!=='')){
      dispatch(PlaceOrder(values));
      navigate('/app/users')
    }else{
      tostW("There is an Error in Placing Order!!");
    }
    
    setOpen(false);
    
  }

  
  // handle input change

  const handleInputChange = (event, index) => {



    if(event.target.name === 'card_number')
    {

    
    const {name, value} = event.target;

    const list = [...inputList];
    list[index][name] = value;
    list[index]['getData'] = [];
    list[index]['cardDetail'] = false
    setInputList(list);
    }
    else
    {
      const {name, value} = event.target;

      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
    // }
    
  };

  // handle click event of remove


  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click of add button

  const handleAddClick = () => {

      if(inputList[inputList.length -1].card_number === "")
      {
        alert(`Please complete Order ${inputList.length} `)
      }
      else if(inputList[inputList.length -1].quantity === "")
      {
        alert(`Please complete Order ${inputList.length} `)
        
      }  
      else
      {

        setInputList([...inputList, {card_number:"", quantity:"", price:"", discount:"", cardDetail:false, remark:"" , getData:[]}])
      }    
  }
  


  return (
    <Page className={classes.root} title="Place Order">
      <ToastContainer    />
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Are You Sure you want to Place This Order??
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
         
          <Grid spacing={3} item lg={12} md={12} xs={12}>
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
              <Card>
                <CardHeader subheader="" title="Create Purchase order" />
                <Divider />
                <CardContent>
       
                  <Grid container spacing={3} mb={3}>
                     
          
          
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        disabled={true}                
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Mobile"
                        name="mobile"
                        disabled={true}
                        required
                        value={values.mobile}
                        variant="outlined"
                      />
                    </Grid>
          
                  </Grid>
                </CardContent>
              </Card>   
              {inputList.map((x, i) => {
                return (
                  <Card style={{border:"4px solid gray"}}>
                    <CardContent style={{borderBottomWidth:1 , borderBottomColor:"black"}}>
                    {/* <Grid container spacing={3} mb={3}>
                     <Grid item md={6} xs={12}>
                     <TextField
                            fullWidth
                            label="Card Number"
                            name="card_number"
                            value = {x.card_number}
                            onChange={e => handleInputChange(e, i)}
                            required
                            variant="outlined"
                          /> 
                       </Grid>
                     <Grid item md={6} xs={12}>
                     <TextField
                            fullWidth
                            label="Card Number"
                            name="card_number"
                            value = {x.card_number}
                            onChange={e => handleInputChange(e, i)}
                            required
                            variant="outlined"
                          /> 
                       </Grid>

                       </Grid> */}
                      <Grid container spacing={3} mb={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Card Number"
                            name="card_number"
                            value = {x.card_number}
                            onChange={e => handleInputChange(e, i)}
                            required
                            variant="outlined"
                          /> 
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button onClick={() => handleGetCard(i)} color="primary" variant="contained">
                              Get Card
                            </Button>
                          </Box>            
                        </Grid> 
                      
                                  
                        {i!==0 ? <Grid item md={3} xs={12}>
                          <Box display="flex" justifyContent="flex-end" p={2}>
                        
                            <Button onClick={() => handleRemoveClick(i)} color="secondary" variant="contained">
                              Delete
                            </Button>
                          </Box>
                        </Grid>:<></>}
                    

                      { x.cardDetail ? 
                          
                          <>
                          <Grid container spacing={3}>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Quantity: {x.getData.quantity}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Price: {x.getData.price}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Discount: {x.getData.discount}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Card Number:{x.getData.card_number}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Remark: {x.getData.remark}
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography md={3}>
                                Other Details: {x.getData.other_detail}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                            <TextField
                                fullWidth
                                label="Quantity"
                                name="quantity"
                                value = {x.quantity}
                                onChange={e => handleInputChange(e, i)}
                                required
                                variant="outlined"
                              />   
                            </Grid>
                            <Grid item md={6}>
                            <TextField
                                fullWidth
                                label="Remark"
                                name="remark"
                                value = {x.remark}
                                onChange={e => handleInputChange(e, i)}
                                required
                                variant="outlined"
                              />   
                            </Grid>
                          </Grid>
                        </>
                        : <></>
                        
                       } 
                        
                       
                        
                      </Grid>
                    </CardContent>
                  </Card>
                  
                )
              })}
              <Box display="flex" justifyContent="flex-end" p={2}>

                <Button onClick={() => handleAddClick()} color="primary" variant="contained">
                  Add Card
                </Button>
              </Box>                              
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button color="primary" onClick = {() => handlePlaceOrder()} variant="contained">
                  Place Order
                </Button>
              </Box>
     
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBanners;
