import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../src/layouts/DashboardLayout';
import MainLayout from '../../src/layouts/MainLayout';
import DashboardView from '../../src/views/reports/DashboardView/index';
import LoginView from '../../src/views/auth/LoginView';
import NotFoundView from '../../src/views/errors/NotFoundView';
import ProductListView from '../../src/views/product/ProductListView';
import RegisterView from '../../src/views/auth/RegisterView';
import SettingsView from '../../src/views/settings/SettingsView';
import LooseStock from '../../src/views/orders/LooseStock';
import SendMsg from '../../src/views/SendMsg/SendMsg';
import MsgList from '../../src/views/SendMsg/List';



import Gallery from '../views/Gallery/List'
import NewArrival from '../views/NewArrival/List'
import OfferCard from '../views/OfferCards/List'

import Users from '../views/Users/List'
import UsersAdd from '../views/Users/Add'
import UsersEdit from '../views/Users/Edit'
import PlaceOrder from '../views/Users/PlaceOrder'


import Upi from '../views/UPI/List'
import UpiAdd from '../views/UPI/Add'
import UpiEdit from '../views/UPI/Edit'

import Order from '../views/orders/List'
import OrderView from '../views/orders/View'
import OrderViewPrint from '../views/orders/print'
import OrderViewPrint2 from '../views/orders/print2'
import BillPrint from '../views/orders/gBill'

import MasterView from '../views/MasterView/MasterView'

import Stock from '../views/Stock/List'
import StockAdd from '../views/Stock/Add'
import StockEdit from '../views/Stock/Edit'
import StockDetail from '../views/Stock/Detail'
import ShowStock from '../views/Stock/ShowStock'


import Party from '../views/MasterView/Party/List'
import PartyAdd from '../views/MasterView/Party/Add'
import PartyEdit from '../views/MasterView/Party/Edit'
import AddSeq from '../views/Bill_Seq/List'


import History from '../views/History/List'
// Admin


const routes1 = [

  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
     
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      
      
      { path: 'users', element:<Users />},
      { path: 'msgs', element:<MsgList />},
      { path: 'msgs/add', element:<SendMsg />},
      { path: 'users/add', element:<UsersAdd />},
      { path: 'users/edit/:id', element:<UsersEdit />},
      { path: 'users/placeorder/:id', element:<PlaceOrder />},
      
      { path: 'NewArrival', element:<NewArrival />},
      { path: 'OfferCard', element:<OfferCard />},
      { path: 'gallery', element:<Gallery />},
     
      
      { path: 'UPI', element:<Upi />},
      { path: 'BillSequence', element:<AddSeq />},
      { path: 'UPIAdd', element:<UpiAdd />},
      { path: 'UPIEdit/:id', element:<UpiEdit />},
      
      { path: 'Orders', element:<Order />},
      { path: 'Orders/view/:id', element:<OrderView />},
      { path: 'Orders/lstck/:id', element:<LooseStock />},
      { path: 'Orders/print/:id', element:<OrderViewPrint />},
      { path: 'Orders/Bill/:id', element:<BillPrint/>},
      { path: 'Orders/print/orerslist', element:<OrderViewPrint2 />},
      
      { path: 'Stock', element:<Stock />},
      { path: 'StockAdd', element:<StockAdd />},
      { path: 'StockEdit/:id', element:<StockEdit />},
      { path: 'StockDetail/:id', element:<StockDetail />},
      { path: 'ShowStock/:id', element:<ShowStock/>},
      
      
      { path: 'masterview', element: <MasterView /> },

      { path: 'Master/Party', element:<Party />},
      { path: 'Master/PartyAdd', element:<PartyAdd />},
      { path: 'Master/PartyEdit/:id', element:<PartyEdit />},


      { path: 'history', element:<History />},

      { path: '404', element: <NotFoundView/> },
      // { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },


      


    ]
  },

  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView/> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
  
];

export default routes1;
