import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';

import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


// import {addMsgs} from '../../Actions/Msg'
// import {cleanMsgsData} from '../../Actions/ActionCreators'


import { createMuiTheme,  ThemeProvider  ,withStyles} from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  margin: {
    margin: theme.spacing(1),
    width:"100%"
  },

}));




const BootstrapButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#0063cc',
      borderColor: '#0063cc',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })(Button);
  
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[100],
      },
    },
  }))(Button);

  
  




const AddBanners = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()


  useEffect( () => { 
    }, [])


    
  const navigate = useNavigate();

  const onButton1 = (olData) =>{
    navigate('/app/Master/Party')
  }


  const onButton2 = (olData) =>{
    // navigate('/app/getContactCategory/')
  }
  const onButton3 = (olData) =>{
    // navigate('/app/getSamajCategory/')
  }


  const onButton4 = (olData) =>{
    // navigate('/app/getVlinks/')
  }




  

  return (
    <Page
      className={classes.root}
      title="Masters"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
         

          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Master Modules" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
           
            <Grid item md={12} xs={12}>

              <ColorButton onClick={onButton1}  variant="contained" color="primary" className={classes.margin}>
                Party management Master
              </ColorButton>
              
            </Grid>
        
            {/* <Grid item md={12} xs={12}>
                
            <ColorButton onClick={onButton2}   variant="contained" color="primary" className={classes.margin}>
      Phone Category Master
      </ColorButton>
            
            </Grid>


            <Grid item md={12} xs={12}>
                
            <ColorButton onClick={onButton3}   variant="contained" color="primary" className={classes.margin}>
      Socity Master
      </ColorButton>
            
            </Grid>

            <Grid item md={12} xs={12}>
                
            <ColorButton onClick={onButton4}   variant="contained" color="primary" className={classes.margin}>
      Video Master
      </ColorButton>
            
            </Grid> */}

            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {/* <Button color="primary" variant="contained">
            Save details
          </Button> */}
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBanners;
