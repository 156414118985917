import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';

import tableIcons from '../../Config/IconsFile'


import {AddBox , ArrowDownward} from '@material-ui/icons'


import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import {GetUpi, DelUpi} from '../../Actions/UPI'
import { CleanUpiData } from "../../Actions/ActionCreators";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  




  const listData = useSelector((state) =>
  state.AddUpi &&
  state.AddUpi.getdata &&
    state.AddUpi.getdata.length &&
    Array.isArray(state.AddUpi.getdata)
      ? state.AddUpi.getdata
      : []
  );





  
 
  const Delmsg = useSelector(state => state.AddUpi.deldata && state.AddUpi.deldata.status ? state.AddUpi.deldata.status : null  )
  const Delerror = useSelector(state => state.AddUpi.delerror && state.AddUpi.delerror.message ? state.AddUpi.delerror.message : null  )
  const editmsg = useSelector(state => state.AddUpi && state.AddUpi.editdata && state.AddUpi.editdata.message ? state.AddUpi.editdata.message : null  )
  const editerror = useSelector(state => state.AddUpi && state.AddUpi.editerror && state.AddUpi.editerror.message ? state.AddUpi.editerror.message : null  )


  // const stmsg = useSelector(state => state.AddNews.stData && state.AddNews.stData.msg ? state.AddNews.stData.msg : null  )
  // const sterror = useSelector(state => state.AddNews.stError && state.AddNews.stError.msg ? state.AddNews.stError.msg : null  )






  
useEffect( () => {

    dispatch(GetUpi())

    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg!==null && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror!==null && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS("edited Successfully")
      }
      if(editerror !==null)
      {
        tostE(editerror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(CleanUpiData())
    dispatch(GetUpi())

    })


    
  }, [ Delmsg  ,Delerror, editmsg , editerror])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  // console.log(olData)
  navigate('/app/UPIEdit/'+olData.upi_id , { state: olData })
}


const onDel = (oldata) =>{
  dispatch(DelUpi(oldata.upi_id ,oldata.tableData.id ))
}
 



  const addNewBornbutton = () =>{
    navigate('/app/UPIAdd')
    
  }

  return (
    <>
      <Page className={classes.root} title="UPI">
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addNewBornbutton} color="primary" variant="contained">
                Add Upi Details
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Cards"
      columns={[
        {
          title: 'Images',
          field: 'images',
          editable: "never",
          render: rowData => (
            <Avatar
            className={useStyles.avatar}
            src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ?  rowData.images[0] : ''}
          >
          </Avatar>
          ),
        },
        { title: 'Account Number', field: 'acc_no',editable: "never", },
        { title: 'IFSC CODE', field: 'ifsc_code',editable: "never", },     
        { title: 'Contact Person', field: 'contact_person',editable: "never", },     
        { title: 'Contact Number', field: 'contact_number',editable: "never", },     
        
        {
          title: 'Action',
          render: rowData => (
            <Grid container className={classes.root2}>
      <Grid item xs={4}>
        
      <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      <Grid item xs={4}>
        <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
      </Grid>
      
      </Grid>
          ),
        },

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
