
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { AddOrdersBegin , AddOrdersSuccess , AddOrdersFailure } from './ActionCreators'
import { GetOrdersBegin , GetOrdersSuccess , GetOrdersFailure } from './ActionCreators'
import { EditOrdersBegin , EditOrdersSuccess , EditOrdersFailure } from './ActionCreators'
import { DelOrdersBegin , DelOrdersSuccess , DelOrdersFailure } from './ActionCreators'
import { ChangeOrderStatusBegin , ChangeOrderStatusSuccess , ChangeOrderStatusFailure } from './ActionCreators'
import { getLooseBegin ,getLooseSuccess , getLooseFailure} from "./ActionCreators";
import { addBiltiBegin, addBiltiSuccess, addBiltiFailure} from "./ActionCreators";
import { getPBillsBegin , getPBillsSuccess , getPBillsFailure} from "./ActionCreators";

import { Base_URL } from '../Config/BaseUrlConfig'




export const AddOrders = (data) => {
       

      return (dispatch) => {
        dispatch(AddOrdersBegin());

        axios.post(Base_URL + '/admin/adduser', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(AddOrdersSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(AddOrdersFailure(err.response.data));

                }
            })
    }

}


export const GetOrders = (data) => {
    return (dispatch) => {
      dispatch(GetOrdersBegin());

      axios.get(Base_URL + '/admin/getAllOrders',{params:data} )
          .then((res) => {
            //   console.log(res)
              dispatch(GetOrdersSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetOrdersFailure(err.response.data));

              }
          })
  }

}


export const GetLooseStock = () => {
    return (dispatch) => {
      dispatch(getLooseBegin());

      axios.get(Base_URL + '/admin/getLooseStock', )
          .then((res) => {
            //   console.log(res)
              dispatch(getLooseSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(getLooseFailure(err.response.data));

              }
          })
  }

}



export const EditOrders = (data) => {   
    
    return (dispatch) => {
        dispatch(EditOrdersBegin());

        
        axios.post(Base_URL + '/admin/editOrders' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(EditOrdersSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(EditOrdersFailure(err.response.data));

                }
            })
    }
}

export const DelOrders = (x) => {

    return (dispatch) => {
        dispatch(DelOrdersBegin());

        
        axios.get(Base_URL + '/admin/delOrders' , {params :{ord_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(DelOrdersSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(DelOrdersFailure(err.response.data));

                }
            })
    }
}

export const ChangeOrderStatus = (data) => {
    console.log(data,"###########")
    return (dispatch) => {
        dispatch(ChangeOrderStatusBegin());        
        axios.post(Base_URL + '/admin/updateOrderStatus' , data)
            .then((res) => {
                console.log(res.data)
                dispatch(ChangeOrderStatusSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(ChangeOrderStatusFailure(err.response.data));

                }
            })
    }
}



export const getBillsData = (data) => {
    console.log(data,"###########")
    return (dispatch) => {
        dispatch(getPBillsBegin());        
        axios.get(Base_URL + '/admin/getprintedbills' )
            .then((res) => {
                console.log(res.data)
                dispatch(getPBillsSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getPBillsFailure(err.response.data));

                }
            })
    }
}



export const AddBilti = (data) => {   
    
    return (dispatch) => {
        dispatch(addBiltiBegin());

        
        axios.post(Base_URL + '/admin/addBilti' , data)
            .then((res) => {
                // console.log(res.data)
                dispatch(addBiltiSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addBiltiFailure(err.response.data));

                }
            })
    }
}


