import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import tableIcons from '../../Config/IconsFile'
import CancelIcon from '@material-ui/icons/Cancel';

import {AddBox , ArrowDownward} from '@material-ui/icons'

import {AddOrders, GetOrders, DelOrders, EditOrders} from '../../Actions/Orders'
import {CleanOrdersData} from '../../Actions/ActionCreators'

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const  TabPanel = (props)=> {
   const { children, value, index, ...other } = props;
 
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`simple-tabpanel-${index}`}
       aria-labelledby={`simple-tab-${index}`}
       {...other}
     >
       {value === index && (
         <Box p={3}>
           <Typography>{children}</Typography>
         </Box>
       )}
     </div>
   );
 }
 
 TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
 };
 
 function a11yProps(index) {
   return {
     id: `simple-tab-${index}`,
     'aria-controls': `simple-tabpanel-${index}`,
   };
 }
 


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rootn: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,

 },
 
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();
  

  return (
   <Page className={classes.root} title="Purchase Order Details">
      <ToastContainer    />
   
      <Container maxWidth="lg">
         
      <Card sx={{ maxWidth: 345 }}>
     <CardHeader subheader="" title="Purchase Order Details" />
     <Divider />
     <CardContent>
     <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Typography>Purchase ID : {state.purchase_id} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Purchase Date : {moment(state.timestamp).format("DD/MM/YYYY")}  </Typography>

      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Party Name : {state.name}  </Typography>

      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Party Contact : {state.mobile}  </Typography>

      </Grid>
      {/* <Grid item md={6} xs={12}>
        <Typography>Order Number : {state.order_number}</Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Order Status : {state.order_status==0 ? 'Pending' : state.order_status==1 ? 'Complete' : state.order_status==2 ? 'Dispatched' : state.order_status==3 ? 'Rejected': null} </Typography>
      </Grid> */}
      <Grid item md={6} xs={12}>
         <Typography>Name : {state.name} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Mobile : {state.mobile} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Card Number : {state.card_number} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Quantity : {state.quantity} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Card Type : {state.card_type} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Price : {state.price} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Discount : {state.discount} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Remark : {state.remark} </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
         <Typography>Other Detail : {state.other_detail} </Typography>
      </Grid>
      
     </Grid>
       
     </CardContent>
     
    
   </Card>
     
     
   </Container>
   </Page>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);



