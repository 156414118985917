import React, { useRef, useState, useEffect } from "react";
import Page from "../../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';

import tableIcons from '../../../Config/IconsFile'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import {AddBox , ArrowDownward} from '@material-ui/icons'


import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import {GetParty, DelParty, AddParty, EditParty} from '../../../Actions/Party'
import { CleanPartyData } from "../../../Actions/ActionCreators";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const listData = useSelector((state) =>
  state.AddParty &&
  state.AddParty.getdata &&
    state.AddParty.getdata.length &&
    Array.isArray(state.AddParty.getdata)
      ? state.AddParty.getdata
      : []
  );

  const [values, setValues] = useState({
    party_id:'',
    name:"",
    mobile:"",
    address:""
  
    });
  
    const handleChange = (event) => {
      //  console.log(event.target.value);
       setValues({
         ...values,
         [event.target.name]: event.target.value,
       });
     };
  

    
  const addmsg = useSelector(state => state.AddParty && state.AddParty.data && state.AddParty.data.message ? state.AddParty.data.message : null  )
  const adderr = useSelector(state => state.AddParty && state.AddParty.error && state.AddParty.error.message ? state.AddParty.error.message : null  )
      
  const Delmsg = useSelector(state => state.AddParty.deldata && state.AddParty.deldata.status ? state.AddParty.deldata.status : null  )
  const Delerror = useSelector(state => state.AddParty.delerror && state.AddParty.delerror.message ? state.AddParty.delerror.message : null  )
  const editmsg = useSelector(state => state.AddParty && state.AddParty.editdata && state.AddParty.editdata.message ? state.AddParty.editdata.message : null  )
  const editerror = useSelector(state => state.AddParty && state.AddParty.editerror && state.AddParty.editerror.message ? state.AddParty.editerror.message : null  )

  


  
useEffect( () => {

    dispatch(GetParty())

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string'){
        tostS(addmsg)
       }          
      if(adderr !== null){
        tostE(adderr)
       }
      if(Delmsg!==null && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror!==null && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }
      if(editmsg !== null && typeof(editmsg) === 'string')
      {
        tostS("edited Successfully")
      }
      if(editerror !==null)
      {
        tostE(editerror)
      }
     
      

      resolve()

    })
    x.then(()=>{
    
    
    dispatch(CleanPartyData())
    dispatch(GetParty())

    })


    
  }, [ Delmsg  ,Delerror, editmsg , editerror, addmsg, adderr])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  // console.log(olData)
  setOpen2(true)
  setValues({
    ...values,
    ['name']:olData.name,
    ['mobile']:olData.mobile,
    ['address']:olData.address,
    ['party_id']:olData.party_id
  })
  // navigate('/app/PartyEdit/'+olData.item_id , { state: olData })
}


const onDel = (oldata) =>{
  dispatch(DelParty(oldata.party_id ,oldata.tableData.id ))
}
const [open, setOpen] = React.useState(false);
const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setValues({
      ...values,
      ['name']:"",
      ['mobile']:"",
      ['address']:"",
      ['party_id']:""
    })
    setOpen(true);

  };
  const handleClickOpen2 = () => {
    setOpen2(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setValues({
      ...values,
      ['name']:"",
      ['mobile']:"",
      ['address']:"",
      ['party_id']:""
    })
  }
    const handleSubmit = () =>{
      //  console.log(values)
      if (values.party_id !== ''){
        dispatch(EditParty(values))
      }
      else
      {
        dispatch(AddParty(values))
      }
      setValues({
        ...values,
        ['name'] : "",
        ['mobile']:"",
        ['address']:"",
        
      });
    setOpen(false);
    setOpen2(false);
    }; 
  const addNewBornbutton = () =>{
    navigate('/app/Master/PartyAdd')
    
  }

  return (
    <>
      <Page className={classes.root} title="Party">
      <ToastContainer    />      
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          
          <div className={clsx(classes.root2, className)} {...rest}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add Party
            </Button>
          </Box>
      <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add parties</DialogTitle>
        <DialogContent>                    
          <TextField
            fullWidth
            id="name"
            label="Name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            required
            variant="outlined"
            
          />
          <br /><br />
          <TextField
            fullWidth
            id="mobile"
            label="Mobile"
            type="text"
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
            required
            variant="outlined"
            
          />
          <br /><br />
          <TextField
            fullWidth
            id="address"
            label="Address"
            type="text"
            name="address"
            value={values.address}
            onChange={handleChange}
            required
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Party
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Party Details</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="name"
            label="Name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            required
            variant="outlined"
          />
          <br /><br />
          <TextField
            fullWidth
            id="mobile"
            label="Mobile"
            type="text"
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
            required
            variant="outlined"
          />
          <br /><br />
          <TextField
            fullWidth
            id="address"
            label="Address"
            type="text"
            name="address"
            value={values.address}
            onChange={handleChange}
            required
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update Drawing Type
          </Button>
        </DialogActions>
      </Dialog>
    </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Parties List"
      columns={[
        // {
        //   title: 'Images',
        //   field: 'images',
        //   editable: "never",
        //   render: rowData => (
        //     <Avatar
        //     className={useStyles.avatar}
        //     src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ?  rowData.images[0] : ''}
        //   >
        //   </Avatar>
        //   ),
        // },
        { title: 'Party ID', field: 'party_id',editable: "never", },
        { title: 'Name', field: 'name',editable: "never", },     
        { title: 'Mobile', field: 'mobile',editable: "never", },     
        { title: 'Address', field: 'address',editable: "never", },                
        {
          title: 'Action',
          render: rowData => (
                            <Grid container className={classes.root2}>
                                <Grid item xs={4}>

                              <EditIcon onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer"}} />
                              </Grid>
                              <Grid item xs={4}>
                                <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />
                              </Grid>

                            </Grid>
                            ),
        },
        
      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
