import {PLACE_ORDER_LOADING, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE, CLEAN_PLACE_ORDER_DATA} from '../Actions/Types';
import {GET_CARD_DATA_LOADING, GET_CARD_DATA_SUCCESS, GET_CARD_DATA_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA} from '../Actions/Types';

const PlaceOrder = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
           data: [{}],
           error: {},
           cardData: [{}],
           cardError: {},

       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],
           error: {},
           cardData: [{}],
           cardError: {},

       }

   case CLEAN_PLACE_ORDER_DATA:
       return {
         ...state,
         loading:false,
         data: [{}],
         error: {},
         cardData: [{}],
         cardError: {},

           }
   case GET_CARD_DATA_LOADING:
      return {
        ...state,
        loading: true,
        cardData: [{}],
        cardError:{},
      };
   case GET_CARD_DATA_SUCCESS:
       return {
         ...state,
         loading: false,
         cardData: action.data,
         cardError: {},
       };
   case GET_CARD_DATA_FAILURE:
       return {
         ...state,
         loading: false,
         cardError: action.error,
         cardData: [{}],
       };   

   case PLACE_ORDER_LOADING:
       return {
         ...state,
         loading: true,
         error: {},
         data:[{}],
       };
   case PLACE_ORDER_SUCCESS:
       return {
         ...state,
         loading: false,
         error: {},
         data: action.data,
       };
   case PLACE_ORDER_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.error,
         data: [{}],
       };
     
   default:
       return state

}
}



export default PlaceOrder;
