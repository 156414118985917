
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { AddUpiBegin , AddUpiSuccess , AddUpiFailure } from './ActionCreators'
import { GetUpiBegin , GetUpiSuccess , GetUpiFailure } from './ActionCreators'
import { EditUpiBegin , EditUpiSuccess , EditUpiFailure } from './ActionCreators'
import { DelUpiBegin , DelUpiSuccess , DelUpiFailure } from './ActionCreators'



import { Base_URL } from '../Config/BaseUrlConfig'




export const AddUpi = (data) => {
    
    var formData = new FormData()

    formData.append('acc_no', data.acc_no);
    formData.append('ifsc_code', data.ifsc_code);
    formData.append('contact_person', data.contact_person);
    formData.append('contact_number', data.contact_number);
    formData.append('bank_name', data.bank_name);
    formData.append('img', data.img);
    

      return (dispatch) => {
        dispatch(AddUpiBegin());

        axios.post(Base_URL + '/admin/addUpiData', formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(AddUpiSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(AddUpiFailure(err.response.data));

                }
            })
    }

}


export const GetUpi = (data) => {
    return (dispatch) => {
      dispatch(GetUpiBegin());

      axios.get(Base_URL + '/admin/getUpiData', )
          .then((res) => {
            //   console.log(res)
              dispatch(GetUpiSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetUpiFailure(err.response.data));

              }
          })
  }

}


export const EditUpi = (data) => {
   var formData = new FormData()
   formData.append('upi_id', data.upi_id);
   formData.append('acc_no', data.acc_no);
   formData.append('ifsc_code', data.ifsc_code);
   formData.append('contact_person', data.contact_person);
   formData.append('contact_number', data.contact_number);
   formData.append('bank_name', data.bank_name);
   formData.append('images', data.images);
   formData.append('img', data.img);
    
    return (dispatch) => {
        dispatch(EditUpiBegin());

        
        axios.post(Base_URL + '/admin/editUpiData' , formData)
            .then((res) => {
                // console.log(res.data)
                dispatch(EditUpiSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(EditUpiFailure(err.response.data));

                }
            })
    }
}

export const DelUpi = (x) => {

    return (dispatch) => {
        dispatch(DelUpiBegin());

        
        axios.get(Base_URL + '/admin/delUpiData' , {params :{upi_id : x}})
            .then((res) => {
                console.log(res.data)
                dispatch(DelUpiSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(DelUpiFailure(err.response.data));

                }
            })
    }
}