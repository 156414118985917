import React , { useRef, useState, useEffect } from 'react';

import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../../src/components/Page';
import Arrivals from './Arrivals';
import Users from './Users';
import Gallery from './Gallery';
import Orders from './Orders';

import { connect } from "react-redux";
import { ToastContainer} from 'react-toastify';
import {tostE , tostS , tostW} from '../../../Config/Toast';
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { getDashboard } from '../../../Actions/Home';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch =  useDispatch();

  
  const DashData = useSelector(state=>state.Home.getdata && state.Home.getdata.data && 
    Object.keys(state.Home.getdata.data) &&  Object.keys(state.Home.getdata.data).length
    ? state.Home.getdata.data : null)


  useEffect( () => {
    
    dispatch(getDashboard())
  
  
}, [])


  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <ToastContainer    />
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <Arrivals data = {DashData!==null && DashData.new_arrivals ? DashData.new_arrivals : 0}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <Gallery data = {DashData!==null && DashData.gallery ? DashData.gallery : 0} />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <Users data = {DashData!==null && DashData.users ? DashData.users : 0} />
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Orders data = {DashData}/>
          </Grid>
         </Grid> 
      </Container>
    </Page>
  );
};



export default Dashboard;
