import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import { DatePicker } from "@material-ui/pickers";

import {createMuiTheme} from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import tableIcons from '../../Config/IconsFile'
import CancelIcon from '@material-ui/icons/Cancel';
import NoteAdd from '@material-ui/icons/NoteAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AddBox, ArrowDownward } from '@material-ui/icons'
import {GetLooseStock} from '../../Actions/Orders'

import { AddOrders, GetOrders, DelOrders, EditOrders, ChangeOrderStatus , AddBilti} from '../../Actions/Orders'
import { CleanOrdersData } from '../../Actions/ActionCreators'

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {ltd} from '../../Actions/Loose'
import {CleanLtd} from '../../Actions/ActionCreators'

import {ThemeProvider} from '@material-ui/styles'
import { conforms } from "lodash";
import { GetStock, DelStock, UpdateStockVisibility, ModifyStock } from '../../Actions/Stock';
import { GetRateCards} from '../../Actions/StockSummary';
import { getBillsData  } from '../../Actions/Orders';


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E36A26',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E36A26',
      },
      daySelected: {
        backgroundColor: '#E36A26',
      },
      current: {
        color: '#E36A26',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E36A26',
      },
    },
  },
});


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rootn: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },

  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [date, changeDate] = useState(new Date());


  const [tvalue, setTValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const handleClose = () => {
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setOpen3(false);

  };
  const handleClose2 = () => {
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setOpen3(false);

  };
  const handleClose3 = () => {
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setOpen3(false);

  };
  const handleClose4 = () => {
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setOpen3(false);

  };


  const handleChangetab = (event, newValue) => {
    setTValue(newValue);
  };

  const [values, setValues] = useState({

    ord_id: "",
    order_status: "",
    selected_customer:"All",
    cust_list : [],
    date : new Date(Date.now()),
    selected_rows : [],
    start_date : new Date(Date.now()),
    o_start_date : new Date(Date.now()),
    end_date : new Date(Date.now()),
    o_end_date : new Date(Date.now()),


  });



 


  const listData = useSelector((state) =>
    state.AddOrders &&
      state.AddOrders.getdata &&
      state.AddOrders.getdata.length &&
      Array.isArray(state.AddOrders.getdata)
      ? state.AddOrders.getdata
      : []
  );


  const BillGet = useSelector((state) =>
    state.AddOrders &&
      state.AddOrders.BillsData &&
      state.AddOrders.BillsData.length &&
      Array.isArray(state.AddOrders.BillsData)
      ? state.AddOrders.BillsData
      : []
  );



  const LooseStockData  =  useSelector((state) => state.LooseStock.data && Array.isArray(state.LooseStock.data) && state.LooseStock.data.length ? state.LooseStock.data : [])
  // moment(rowData.order_date).format("DD/MM/YYYY")
  const DlistData = (listData.filter(OrderData => OrderData.order_status == 2))
  const ClistData = (listData.filter(OrderData => 
    values.selected_customer === 'All' ? 
    // OrderData.order_status == 1 &&  moment(OrderData.order_date).format("DD/MM/YYYY") ===  moment(values.date).format("DD/MM/YYYY") 
    OrderData.order_status == 1 
    // && ( (moment(OrderData.order_date).isSame(values.start_date) || moment(OrderData.order_date).isAfter(values.start_date) ) || (moment(OrderData.order_date).isSame(values.end_date) || moment(OrderData.order_date).isBefore(values.end_date) ))

    :
    OrderData.order_status == 1 
    // &&  moment(OrderData.order_date).format("DD/MM/YYYY") ===  moment(values.date).format("DD/MM/YYYY")  
    && OrderData.name_of_firm === values.selected_customer
    
    ))
  const RlistData = (listData.filter(OrderData => OrderData.order_status == 3))
  const PlistData = (listData.filter(OrderData => OrderData.order_status == 0))



  const filter_Customers = (data , x) =>{
    
    var cList = []
    data.forEach((el, i)=>{
      cList.push(el.name_of_firm)

    })
    console.log([...new Set(cList)])
    
    return [...new Set(cList)]
    // setValues({...values , ['cust_list']:cList})

  } 

 var Customers  = filter_Customers(ClistData , values.selected_customer)
 var Customers2  = filter_Customers(ClistData , values.selected_customer)

 
  const handleChangeType = (event) => {
  
    setValues({
      ...values,
      ['selected_customer']: event.target.value,
      
    });

   
  };




  const Delmsg = useSelector(state => state.AddOrders.deldata && state.AddOrders.deldata.status ? state.AddOrders.deldata.status : null)
  const Delerror = useSelector(state => state.AddOrders.delerror && state.AddOrders.delerror.message ? state.AddOrders.delerror.message : null)

  const editmsg = useSelector(state => state.AddOrders && state.AddOrders.editdata && state.AddOrders.editdata.message ? state.AddOrders.editdata.message : null)
  const editerror = useSelector(state => state.AddOrders && state.AddOrders.editerror && state.AddOrders.editerror.message ? state.AddOrders.editerror.message : null)

  const Statusmsg = useSelector(state => state.AddOrders.statusdata && state.AddOrders.statusdata.message ? state.AddOrders.statusdata.message : null)
  const Statuserror = useSelector(state => state.AddOrders.statuserror && state.AddOrders.statuserror.message ? state.AddOrders.statuserror.message : null)


  const biltiData = useSelector(state => state.AddOrders.biltiData && state.AddOrders.biltiData.message ? state.AddOrders.biltiData.message : null)
  const biltiErr = useSelector(state => state.AddOrders.biltiErr && state.AddOrders.biltiErr.message ? state.AddOrders.biltiErr.message : null)



  const lsdmsg = useSelector(state => state.Ltd && state.Ltd.data && state.Ltd.data.message ? state.Ltd.data.message : null)
  const lsderror = useSelector(state => state.Ltd && state.Ltd.error && state.Ltd.error.message ? state.Ltd.error.message : null)



  


  useEffect(() => {
    dispatch(GetStock())
    dispatch(GetRateCards())
    dispatch(GetOrders())
    dispatch(GetLooseStock())

    dispatch(getBillsData())




    let x = new Promise((resolve, reject) => {

      if (lsdmsg !== null && typeof (lsdmsg) === 'string') {
        tostS(lsdmsg)
      }
      if (lsderror !== null && typeof (lsderror) === 'string') {
        tostE(lsderror)
      }

      if (Delmsg !== null && typeof (Delmsg) === 'string') {
        tostS(Delmsg)
      }
      if (Delerror !== null && typeof (Delerror) === 'string') {
        tostE(Delerror)
      }
      if (Statusmsg !== null && typeof (Statusmsg) === 'string') {
        tostS(Statusmsg)
      }
      if (Statuserror !== null && typeof (Statuserror) === 'string') {
        tostE(Statuserror)
      }

      if (biltiData !== null && typeof (biltiData) === 'string') {
        tostS(biltiData)
      }
      if (biltiErr !== null && typeof (biltiErr) === 'string') {
        tostE(biltiErr)
      }


      if (editmsg !== null && typeof (editmsg) === 'string') {
        tostS("edited Successfully")
      }
      if (editerror !== null) {
        tostE(editerror)
      }


      resolve()

    })
    x.then(() => {


      dispatch(GetOrders())
      dispatch(CleanLtd())
      dispatch(GetLooseStock())

      dispatch(CleanOrdersData())
    })



  }, [Delmsg, Delerror, Statusmsg, Statuserror, editmsg, editerror, biltiData, biltiErr  ,lsdmsg , lsderror])


  const navigate = useNavigate();

  const onShift = (olData) => {
    // navigate('/app/NewArrivalEdit/'+olData.ar_id , { state: olData })
  }

  const onComp = (olData) => {
    setOpen(true)
    setValues({
      ...values,
      ['ord_id']: olData.ord_id,
      ['order_status']: 1
    });


  }

  const handleComplete = () => {
    // console.log(values, "***********")
    if (values.order_status != "" && values.ord_id != "") {
      dispatch(ChangeOrderStatus(values));
    } else {
      tostW("There is an error!!");
    }
    setValues({
      ...values,
      ['ord_id']: "",
      ['order_status']: ""
    });
    setOpen(false);
  }

  const onDisp = (olData) => {
    setOpen2(true);
    setValues({
      ...values,
      ['ord_id']: [olData.ord_id],
      ['order_status']: 2
    });

  }

  const handleDispatch = () => {
    console.log(values, "***********")
    if (values.order_status != "" && values.ord_id != "") {
      dispatch(ChangeOrderStatus(values));
    } else {
      tostW("There is an error!!");
    }
    setValues({
      ...values,
      ['ord_id']: "",
      ['order_status']: ""
    });
    setOpen2(false);
  }


  const onDel = (oldata) => {

    dispatch(DelOrders(oldata.ord_id, oldata.tableData.id))
  }


  // const mod_stock_data  =  useSelector((state) =>  state.AddStock.getdata )
  const mod_stock_data  =  useSelector((state) =>  state.RateCard.cardData )


  const onPrint = (oldata) =>{



    if(values.selected_rows.length > 1)
    {
      var bill_array  = []


      
    var temp =  values.selected_rows
    console.log(temp)
    var temp2  = []
    var bill_number = "";
    var mobile_number = "";
    var firm_name = ""

    var hell =  new Promise((resolve,reject)=>{
      
   
      temp.forEach((data, i)=>{

        temp2 = [...temp2 , ...data.order_details]
        
      })

    mobile_number = temp[0].mobile
    firm_name = temp[0].name_of_firm

    for(let i =  0 ; i <  temp.length ; i++)
    {
      bill_array.push(temp[i].ord_id)

      bill_number = bill_number + String(temp[i].ord_id) +  "/"
    }

    resolve()



    })
    hell.then(()=>{
      var chkdata  =  mod_stock_data
      for(let i = 0  ;  i < temp2.length ; i++) 
      {
       
       
        console.log(chkdata)
        for(let j  = 0 ; j < chkdata.length ; j++)
        {
          // console.log(chkdata[j].card_number)
    
         
          if(temp2[i].card_number === chkdata[j].card_number)
          {
            console.log(chkdata[j].rate)
            temp2[i]['rate'] = chkdata[j].rate 
            break
          }
        }
      

     
      
       
      }
        
        console.log(temp2)
        // setFdata(temp2)
        navigate('/app/Orders/print/'+values.selected_rows[0].ord_id , { state: { 'bill_number' : bill_number , 'mobile' : mobile_number , "firm_name" : firm_name , "fullD"  :  temp2 , dData : values ,'bill_array' : bill_array}
      })
        
      })

    }
    else
    {
    //   console.log(oldata)
    //   console.log(DlistData)
    
  
    //   navigate('/app/Orders/print/'+oldata.ord_id , { state: { 'bill_number' : oldata.order_number , 'mobile' : oldata.mobile , "firm_name" : oldata.name_of_firm , "fullD"  :  oldata.order_details}
    // })
    var bill_array = []

    var temp =  [oldata]
    console.log(temp)
    var temp2  = []
    var bill_number = "";
    var mobile_number = "";
    var firm_name = ""

    var hell =  new Promise((resolve,reject)=>{
      
   
      temp.forEach((data, i)=>{
        temp2 = [...temp2 , ...data.order_details]
        
      })

    mobile_number = temp[0].mobile
    firm_name = temp[0].name_of_firm

    for(let i =  0 ; i <  temp.length ; i++)
    {
      bill_array.push(temp[i].ord_id)
      bill_number = bill_number + String(temp[i].ord_id) +  "/"
    }

    resolve()



    })
    hell.then(()=>{
      var chkdata  =  mod_stock_data
      for(let i = 0  ;  i < temp2.length ; i++) 
      {
       
       
        console.log(chkdata)
        for(let j  = 0 ; j < chkdata.length ; j++)
        {
          // console.log(chkdata[j].card_number)
    
         
          if(temp2[i].card_number === chkdata[j].card_number)
          {
            console.log(chkdata[j].rate)
            temp2[i]['rate'] = chkdata[j].rate 
            break
          }
        }
      

     
      
       
      }
        
        console.log(temp2)
        // setFdata(temp2)
        navigate('/app/Orders/print/'+oldata.ord_id , { state: { 'bill_number' : bill_number , 'mobile' : mobile_number , "firm_name" : firm_name , "fullD"  :  temp2  ,dData : values  , 'bill_array' : bill_array}
      })
        
      })
    }

  }


  const onPrint2 = (oldata) =>{
    console.log(oldata)
    var jungle  =  oldata
    var yu  = new Promise((resolve ,reject)=>{

      for(let i  =  0 ; i< jungle.length ; i++)
      {
        var io = []
        for(let j  =  0  ;  j <  jungle[i].order_details.length ; j++)
        {
            io.push({  'card_num' :jungle[i].order_details[j].card_number , 'quantity' : jungle[i].order_details[j].quantity})
        }
        jungle[i]['card_array'] = io
      }
resolve()
    })
    yu.then(()=>{

    })
    
    navigate('/app/Orders/print/orerslist' , { state: jungle })
  }

  const onView = (oldata) => {

    navigate('/app/Orders/view/' + oldata.ord_id, { state: oldata })
  }


  


  const onViewforComplete = (oldata) => {

    navigate('/app/Orders/lstck/' + oldata.ord_id, { state: oldata })
    
  }



  const onReject = (olData) => {
    setOpen3(true)
    setValues({
      ...values,
      ['ord_id']: olData.ord_id,
      ['order_status']: 3
    });

  }
  const handleReject = () => {
    console.log(values, "***********")
    if (values.order_status != "" && values.ord_id != "") {
      dispatch(ChangeOrderStatus(values));
    } else {
      tostW("There is an error!!");
    }
    setValues({
      ...values,
      ['ord_id']: "",
      ['order_status']: ""
    });
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setOpen3(false);
  }



  const handleChangeModify = (event) =>{
    setModify({
      ...modify,
      [event.target.name]: event.target.value,
    });
  }


  const [openBilti, setOpenBilti] = React.useState(false);

  const [modify, setModify] = useState({
   bilti_no : "",
   ord_id:""
  })

  const handleSubmit = () => {
    if(modify.bilti_no !=="" ){
      dispatch(AddBilti(modify));
      console.log(modify.bilti_no)
    }else{
      tostW("Please Enter Bilti Number!!")
    }
    
    setModify({
      ...modify,    
      ['bilti_no']:'', 
    })
    setOpenBilti(false);
  
  };
  


  const handleCloseBilti = () => {
    setModify({
      ...modify,    
      ['bilti_no']:'', 
    })
    setOpenBilti(false);
  };


  const onAddBilti = (oldata) => {
    setModify({
      ...modify,    
      ['ord_id']:oldata.ord_id, 
    })
    setOpenBilti(true);
 

    // navigate('/app/Orders/view/' + oldata.ord_id, { state: oldata })
  }


  const onlsd = (dataj) =>{
    console.log(dataj)
    dispatch(ltd(dataj))

  }

const   handleDateChange1 = (x) =>{
  setValues({...values ,['o_start_date'] : x, ['start_date'] : moment(x).format('DD/MM/YYYY')})
}
const   handleDateChange2 = (x) =>{
  setValues({...values ,['o_end_date']:x, ['end_date'] : moment(x).format('DD/MM/YYYY')})
}


const onGBill  = (oldata) =>{


  navigate('/app/Orders/Bill/'+oldata.bd_id, { state: { 'bill_number' :oldata.bill_data.print_bill_number , 'mobile' : oldata.bill_data.rstate.mobile, "firm_name" : oldata.bill_data.rstate.firm_name , "fullD"  :  oldata.bill_data.rstate.fullD  , 'others' : oldata.bill_data.others  ,'bill_array' : oldata.bill_data.rstate.bill_array}})


}


  return (
    <>
      <Page className={classes.root} title="Orders">
        <ToastContainer />


        <Dialog disableBackdropClick={true} open={openBilti} onClose={handleCloseBilti} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Bilti Number</DialogTitle>
        <DialogContent>         
          <TextField
            fullWidth
            id="bilti_no"
            label="Bilti Number"
            type="text"
            name="bilti_no"
            value={modify.bilti_no}
            onChange={handleChangeModify}
            required
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBilti} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>






        <Dialog disableBackdropClick={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are You Sure you want to Complete this order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleComplete} color="primary">
              Complete Order
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog disableBackdropClick={true} open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are You Sure you want to Dispatch this order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDispatch} color="primary">
              Dispatch Order
            </Button>
          </DialogActions>
        </Dialog>

        
        <Dialog disableBackdropClick={true} open={open3} onClose={handleClose3} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Are You Sure you want to Reject this order??
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} color="primary">
              Cancel
            </Button>
            <Button onClick={handleReject} color="primary">
              Reject Order
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.rootn}>
          <AppBar position="static">

            <Tabs value={tvalue} onChange={handleChangetab} aria-label="simple tabs example">
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Completed" {...a11yProps(1)} />
              <Tab label="Dispatched" {...a11yProps(2)} />
              <Tab label="Rejected" {...a11yProps(3)} />
              <Tab label="Loose Stock" {...a11yProps(4)} />
              <Tab label="Generated Bills" {...a11yProps(5)} />

            </Tabs>

          </AppBar>

          {/* Pending Oredrs */}

          <TabPanel value={tvalue} index={0}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Pending Orders"
                    columns={[

                      { title: 'Order Number', field: 'order_number', editable: "never", },
                      {
                        title: 'Order Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      {
                        title: 'Order Time', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("hh:mm A")
                        ),
                      },
                      { title: 'Mobile', field: 'mobile', editable: "never", },
                      { title: 'Name', field: 'name_of_firm', editable: "never", },
                      {
                        title: 'Action',
                        render: rowData => (
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>
                              <DeleteIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onDel(rowData) }} />

                            </Grid>


                            <Grid item xs={3}>
                              <VisibilityIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onView(rowData) }} />


                            </Grid>
                            <Grid item xs={3}>

                              <Tooltip title="Reject Orders" followCursor>
                                <CancelIcon color="primary" onClick={() => { onReject(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>



                            </Grid>

                          </Grid>
                        ),
                      },
                      // {
                      //   title: 'Download / Print',
                      //   render: rowData => (
                      //     <Grid container className={classes.root2}>

                      //       <Grid item xs={3}>
                      //         <LocalPrintshopIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onPrint(rowData) }} />

                      //       </Grid>
                      //       </Grid>

                        
                      //   ),
                      // },
                      {
                        title: "Shift To Completed",
                        render: rowData => (
                          <Grid container className={classes.root2}>
                            <Grid item xs={4}>
                              <Button color="primary" fullWidth variant="text" onClick={() => { onComp(rowData) }}>
                                <ArrowForwardIcon style={{ cursor: "pointer" }} />
                              </Button>

                            </Grid>
                          </Grid>
                        )

                      }
                    ]}
                    data={PlistData}
                    options={{
                      selection: true,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}
                    actions={[
                      {
                        tooltip: 'Download Description',
                        icon:() => (
                          <Button color="primary" fullWidth variant="text" >
                                <DescriptionIcon style={{ cursor: "pointer" }}  />
                              </Button>
                        ),
                        onClick: (evt, data) => onPrint2(data)
                      }
                    ]}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>

          {/* Completed Oredrs */}

          <TabPanel value={tvalue} index={1}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}
              <Grid container spacing={3}>
              <Grid item md={4} xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Select Customer</InputLabel>
            <Select
              fullWidth
              label = "Select Customer"              
              value={values.selected_customer}
              onChange={handleChangeType}
              name="selected_customer"
              required
              variant="outlined"
            >
              <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          {Customers.map((c,b)=>(
            
            <MenuItem value={c} key={b}>{b+1}{" "}{ c}</MenuItem>
          ))}
          
            </Select>
            </FormControl>
            </Grid>
            <Grid item  md={4}   xs={4}>
                {/* <ThemeProvider theme={materialTheme}> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      // margin="normal"
                      style={{ border: "1px  black" }}
                      id="date-picker-dialog"
                      label="Start Date"
                      format="dd/MM/yyyy"
                      // minDate={new Date}
                      value={values.o_start_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleDateChange1}
                      inputVariant="outlined"
                      variant="outlined"
                      borderColor="red"              
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                {/* </ThemeProvider> */}
              </Grid>
            <Grid item  md={4}   xs={4}>
                {/* <ThemeProvider theme={materialTheme}> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      // margin="normal"
                      style={{ border: "1px  black" }}
                      id="date-picker-dialog"
                      label="End Date"
                      format="dd/MM/yyyy"
                      // minDate={new Date}
                      value={values.o_end_date}
                      style={{width:'100%' , borderColor:"orange"}}
                      onChange={handleDateChange2}
                      inputVariant="outlined"
                      variant="outlined"
                      borderColor="red"              
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                {/* </ThemeProvider> */}
              </Grid>

              {values.selected_rows.length > 1 ?
              <Grid item md={12} xs={12}>
<Button
                    
                    // onClick={handlePrint}
                    onClick={() => { onPrint() }}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"orange" , color:"white" , width:"100%"}}
                    fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                    Merge Bill
                  </Button>

</Grid> : null }
              </Grid>

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Completed Orders"
                    columns={[
                      // {
                      //   title: 'Images',
                      //   field: 'images',
                      //   editable: "never",
                      //   render: rowData => (
                      //     <Avatar
                      //     className={useStyles.avatar}
                      //     src={rowData.images}
                      //   >
                      //   </Avatar>
                      //   ),
                      // },
                      { title: 'Order Number', field: 'order_number', editable: "never", },
                      // { title: 'Order Date', field: 'order_date', editable: "never", },
                      { title: 'Mobile', field: 'mobile', editable: "never", },

                      {
                        title: 'Order Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      {
                        title: 'Order Time', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("hh:mm A")
                        ),
                      },
                      // {
                      //   title: 'Order Time', field: "order_date", editable: "never",
                      //   render: rowData => (
                      //     moment(rowData.order_date).format("hh:mm A")
                      //   ),
                      // },
                      { title: 'Name', field: 'name_of_firm', editable: "never", },
                      {
                        title: 'Actions',
                        render: rowData => (
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>

                              <DeleteIcon onClick={() => { onDel(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={3}>

                              <VisibilityIcon onClick={() => { onView(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={3}>

                              <Tooltip title="Reject Orders" followCursor>
                                <CancelIcon onClick={() => { onReject(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>

                            </Grid>

                          </Grid>
                        ),
                      },
                      {
                        title: 'Download / Print',
                        render: rowData => (
                          
                          <Grid container className={classes.root2}>
   {values.selected_rows.length <= 1 ?
                            <Grid item xs={3}>
                              <LocalPrintshopIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onPrint(rowData) }} />

                            </Grid>
                            : null }
                            </Grid>

                        
                        ),
                      },
                      {
                        title: "Shift To Dispatched",
                        render: rowData => (
                          <Grid container className={classes.root2}>
                            <Grid item xs={4}>
                              <ArrowForwardIcon onClick={() => { onDisp(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                          </Grid>
                        )

                      }
                    ]}
                    onSelectionChange={(rows) => {
                     console.log(rows);
                     setValues({...values  ,['selected_rows'] : rows})
                    }}
                    data={ClistData}
                    options={{
                      selection: true,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>

          {/* Dispatched Orders */}
          <TabPanel value={tvalue} index={2}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Dispatched Orders"
                    columns={[
                      // {
                      //   title: 'Images',
                      //   field: 'images',
                      //   editable: "never",
                      //   render: rowData => (
                      //     <Avatar
                      //     className={useStyles.avatar}
                      //     src={rowData.images}
                      //   >
                      //   </Avatar>
                      //   ),
                      // },
                      { title: 'Order Number', field: 'order_number', editable: "never", },
                      // { title: 'Order Date', field: 'order_date', editable: "never", },
                      { title: 'Mobile', field: 'mobile', editable: "never", },

                      {
                        title: 'Order Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      {
                        title: 'Order Time', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("hh:mm A")
                        ),
                      },
                      { title: 'Name', field: 'name_of_firm', editable: "never", },
                      {
                        title: 'Action',
                        render: rowData => (
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>

                              <DeleteIcon onClick={() => { onDel(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>

                            <Grid item xs={3}>

                              <VisibilityIcon onClick={() => { onViewforComplete(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                            <Grid item xs={3}>

                              <Tooltip title="Reject Orders" followCursor>
                                <CancelIcon onClick={() => { onReject(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>

                            </Grid>
                            <Grid item xs={3}>

                              <Tooltip title="Add Bilti Number" followCursor>
                                <NoteAdd onClick={() => { onAddBilti(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>

                            </Grid>

                          </Grid>
                        ),
                      },
                       {
                        title: 'Download / Print',
                        render: rowData => (
                          <>
                       
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>
                              <LocalPrintshopIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onPrint(rowData) }} />

                            </Grid>
                            </Grid>
                            
                          </>

                        
                        ),
                      },

                    ]}
                    data={DlistData}
                    options={{
                      selection: false,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>

          {/* Rejected Orders */}


          <TabPanel value={tvalue} index={3}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Rejected orders"
                    columns={[
                      // {
                      //   title: 'Images',
                      //   field: 'images',
                      //   editable: "never",
                      //   render: rowData => (
                      //     <Avatar
                      //     className={useStyles.avatar}
                      //     src={rowData.images}
                      //   >
                      //   </Avatar>
                      //   ),
                      // },
                      { title: 'Order Number', field: 'order_number', editable: "never", },
                      // { title: 'Order Date', field: 'order_date', editable: "never", },
                      { title: 'Mobile', field: 'mobile', editable: "never", },

                      {
                        title: 'Order Date', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      { title: 'Name', field: 'name_of_firm', editable: "never", },
                      {
                        title: 'Action',
                        render: rowData => (
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>

                              <DeleteIcon onClick={() => { onDel(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>

                            <Grid item xs={3}>

                              <VisibilityIcon onClick={() => { onView(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>

                            <Grid item xs={3}>
                              <Tooltip title="Reject Orders" followCursor>
                                <CancelIcon onClick={() => { onReject(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>

                            </Grid>

                          </Grid>
                        ),
                      },

                    ]}
                    data={RlistData}
                    options={{
                      selection: false,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>


                       {/* Lose Oredrs */}

          <TabPanel value={tvalue} index={4}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Loose Stock"
                    columns={[
                      // {
                      //   title: 'Images',
                      //   field: 'images',
                      //   editable: "never",
                      //   render: rowData => (
                      //     <Avatar
                      //     className={useStyles.avatar}
                      //     src={rowData.images}
                      //   >
                      //   </Avatar>
                      //   ),
                      // },
                      { title: 'Order Number', field: 'order_number', editable: "never", },
                      // { title: 'Order Date', field: 'order_date', editable: "never", },
                      { title: 'Mobile', field: 'mobile', editable: "never", },

                      {
                        title: 'Order Date', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      {
                        title: 'Order Time', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("hh:mm A")
                        ),
                      },
                      {
                        title: 'Order Time', field: "order_date", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("hh:mm A")
                        ),
                      },
                      { title: 'Name', field: 'name_of_firm', editable: "never", },
                      {
                        title: 'Actions',
                        render: rowData => (
                          <Grid container className={classes.root2}>

                            {/* <Grid item xs={3}>

                              <DeleteIcon onClick={() => { onDel(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid> */}
                            <Grid item xs={3}>

                              <VisibilityIcon onClick={() => { onView(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                            {/* <Grid item xs={3}>

                              <Tooltip title="Reject Orders" followCursor>
                                <CancelIcon onClick={() => { onReject(rowData) }} style={{ cursor: "pointer" }} />
                              </Tooltip>

                            </Grid> */}

                          </Grid>
                        ),
                      },
                      {
                        title: "Shift To Dispatched",
                        render: rowData => (
                          <Grid container className={classes.root2}>
                            <Grid item xs={4}>
                              <ArrowForwardIcon onClick={() => { onlsd(rowData) }} style={{ cursor: "pointer" }} />
                            </Grid>
                          </Grid>
                        )

                      }
                    ]}
                    data={LooseStockData}
                    options={{
                      selection: false,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>

          <TabPanel value={tvalue} index={5}>
            <Container maxWidth="lg">
              {/* <Toolbar /> */}

              <Box mt={3}>
                <Card className={clsx(classes.root2, className)} {...rest}>

                  <MaterialTable
                    icons={tableIcons}
                    title="Genedated Bills"
                    columns={[
                      // {
                      //   title: 'Images',
                      //   field: 'images',
                      //   editable: "never",
                      //   render: rowData => (
                      //     <Avatar
                      //     className={useStyles.avatar}
                      //     src={rowData.images}
                      //   >
                      //   </Avatar>
                      //   ),
                      // },
                      // { title: 'Order Date', field: 'order_date', editable: "never", },

                      {
                        title: 'Bill Date', field: "timestamp", editable: "never",
                        render: rowData => (
                          moment(rowData.order_date).format("DD/MM/YYYY")
                        ),
                      },
                      {
                        title: 'Bill Number', editable: "never",
                        render: rowData => (
                        <>{ rowData.bill_data && rowData.bill_data.print_bill_number && rowData.bill_data.print_bill_number !== undefined ? rowData.bill_data.print_bill_number : "NA"}</> 
                        ),
                      },
                      {
                        title: 'Firm Name', editable: "never",
                        render: rowData => (
                        <>{rowData.bill_data && rowData.bill_data.rstate && rowData.bill_data.rstate.firm_name !== undefined ?   rowData.bill_data.rstate.firm_name : "NA"}</> 
                        ),
                      },
                      // { title: 'Name', field: 'name_of_firm', editable: "never", },
                     
                       {
                        title: 'Download / Print',
                        render: rowData => (
                          <>
                       
                          <Grid container className={classes.root2}>

                            <Grid item xs={3}>
                              <LocalPrintshopIcon color="primary" style={{ cursor: "pointer" }} onClick={() => { onGBill(rowData) }} />

                            </Grid>
                            </Grid>
                            
                          </>

                        
                        ),
                      },

                    ]}
                    data={BillGet}
                    options={{
                      selection: false,
                      exportButton: true,

                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

                  />

                </Card>
              </Box>
            </Container>
          </TabPanel>



        </div>

      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
