



export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAILURE = 'SET_USER_FAILURE';
export const EMPTY_USER   = 'EMPTY_USER'


export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const CLEAN_LOGIN_DATA = "CLEAN_LOGIN_DATA";



export const LOGOUT_USER_LOADING = "LOGOUT_USER_LOADING";
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';


export const GET_SUMMARY_LOADING = "GET_SUMMARY_LOADING";
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const GET_SUMMARY_FAILURE = 'GET_SUMMARY_FAILURE';


export const CLEAN_SUMMARY = 'CLEAN_SUMMARY'




export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";




export const CLEAN_ALL_DATA = 'CLEAN_ALL_DATA'



export const CLEAN_COUNTRY_DATA = "CLEAN_COUNTRY_DATA";
export const CLEAN_STATES_DATA = "CLEAN_STATES_DATA";
export const CLEAN_CITY_DATA = "CLEAN_CITY_DATA";




export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";


export const GET_COUNTRY_LOADING = "GET_COUNTRY_LOADING";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";

export const GET_STATES_LOADING = "GET_STATES_LOADING";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_CITY_LOADING = "GET_CITY_LOADING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";


// ********************************************************************************************************* //

export const ADD_NEW_ARRIVAL_LOADING = "ADD_NEW_ARRIVAL_LOADING";
export const ADD_NEW_ARRIVAL_SUCCESS = "ADD_NEW_ARRIVAL_SUCCESS";
export const ADD_NEW_ARRIVAL_FAILURE = "ADD_NEW_ARRIVAL_FAILURE";

export const GET_NEW_ARRIVAL_LOADING = "GET_NEW_ARRIVAL_LOADING";
export const GET_NEW_ARRIVAL_SUCCESS = "GET_NEW_ARRIVAL_SUCCESS";
export const GET_NEW_ARRIVAL_FAILURE = "GET_NEW_ARRIVAL_FAILURE";

export const EDIT_NEW_ARRIVAL_LOADING = "EDIT_NEW_ARRIVAL_LOADING";
export const EDIT_NEW_ARRIVAL_SUCCESS = "EDIT_NEW_ARRIVAL_SUCCESS";
export const EDIT_NEW_ARRIVAL_FAILURE = "EDIT_NEW_ARRIVAL_FAILURE";

export const DEL_NEW_ARRIVAL_LOADING = "DEL_NEW_ARRIVAL_LOADING";
export const DEL_NEW_ARRIVAL_SUCCESS = "DEL_NEW_ARRIVAL_SUCCESS";
export const DEL_NEW_ARRIVAL_FAILURE = "DEL_NEW_ARRIVAL_FAILURE";

export const SHIFT_TO_GALLERY_LOADING = "SHIFT_TO_GALLERY_LOADING";
export const SHIFT_TO_GALLERY_SUCCESS = "SHIFT_TO_GALLERY_SUCCESS";
export const SHIFT_TO_GALLERY_FAILURE = "SHIFT_TO_GALLERY_FAILURE";

export const CLEAN_NEW_ARRIVAL_DATA = "CLEAN_NEW_ARRIVAL_DATA"



export const ADD_GALLERY_LOADING = "ADD_GALLERY_LOADING";
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS";
export const ADD_GALLERY_FAILURE = "ADD_GALLERY_FAILURE";

export const GET_GALLERY_LOADING = "GET_GALLERY_LOADING";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";


export const EDIT_GALLERY_LOADING = "EDIT_GALLERY_LOADING";
export const EDIT_GALLERY_SUCCESS = "EDIT_GALLERY_SUCCESS";
export const EDIT_GALLERY_FAILURE = "EDIT_GALLERY_FAILURE";

export const DEL_GALLERY_LOADING = "DEL_GALLERY_LOADING";
export const DEL_GALLERY_SUCCESS = "DEL_GALLERY_SUCCESS";
export const DEL_GALLERY_FAILURE = "DEL_GALLERY_FAILURE";

export const CLEAN_GALLERY_DATA = "CLEAN_GALLERY_DATA"


export const ADD_UPI_LOADING = "ADD_UPI_LOADING";
export const ADD_UPI_SUCCESS = "ADD_UPI_SUCCESS";
export const ADD_UPI_FAILURE = "ADD_UPI_FAILURE";

export const GET_UPI_LOADING = "GET_UPI_LOADING";
export const GET_UPI_SUCCESS = "GET_UPI_SUCCESS";
export const GET_UPI_FAILURE = "GET_UPI_FAILURE";


export const EDIT_UPI_LOADING = "EDIT_UPI_LOADING";
export const EDIT_UPI_SUCCESS = "EDIT_UPI_SUCCESS";
export const EDIT_UPI_FAILURE = "EDIT_UPI_FAILURE";

export const DEL_UPI_LOADING = "DEL_UPI_LOADING";
export const DEL_UPI_SUCCESS = "DEL_UPI_SUCCESS";
export const DEL_UPI_FAILURE = "DEL_UPI_FAILURE";

export const CLEAN_UPI_DATA = "CLEAN_UPI_DATA"


export const ADD_USERS_LOADING = "ADD_USERS_LOADING";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_FAILURE = "ADD_USERS_FAILURE";

export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";


export const EDIT_USERS_LOADING = "EDIT_USERS_LOADING";
export const EDIT_USERS_SUCCESS = "EDIT_USERS_SUCCESS";
export const EDIT_USERS_FAILURE = "EDIT_USERS_FAILURE";

export const DEL_USERS_LOADING = "DEL_USERS_LOADING";
export const DEL_USERS_SUCCESS = "DEL_USERS_SUCCESS";
export const DEL_USERS_FAILURE = "DEL_USERS_FAILURE";


export const CLEAN_USERS_DATA = "CLEAN_USERS_DATA"


export const ADD_ORDERS_LOADING = "ADD_ORDERS_LOADING";
export const ADD_ORDERS_SUCCESS = "ADD_ORDERS_SUCCESS";
export const ADD_ORDERS_FAILURE = "ADD_ORDERS_FAILURE";

export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";


export const EDIT_ORDERS_LOADING = "EDIT_ORDERS_LOADING";
export const EDIT_ORDERS_SUCCESS = "EDIT_ORDERS_SUCCESS";
export const EDIT_ORDERS_FAILURE = "EDIT_ORDERS_FAILURE";

export const DEL_ORDERS_LOADING = "DEL_ORDERS_LOADING";
export const DEL_ORDERS_SUCCESS = "DEL_ORDERS_SUCCESS";
export const DEL_ORDERS_FAILURE = "DEL_ORDERS_FAILURE";

export const CHANGE_ORDER_STATUS_LOADING = "CHANGE_ORDER_STATUS_LOADING";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAILURE = "CHANGE_ORDER_STATUS_FAILURE";


export const CLEAN_ORDERS_DATA = "CLEAN_ORDERS_DATA"


export const ADD_OFFER_CARD_LOADING = "ADD_OFFER_CARD_LOADING";
export const ADD_OFFER_CARD_SUCCESS = "ADD_OFFER_CARD_SUCCESS";
export const ADD_OFFER_CARD_FAILURE = "ADD_OFFER_CARD_FAILURE";

export const GET_OFFER_CARD_LOADING = "GET_OFFER_CARD_LOADING";
export const GET_OFFER_CARD_SUCCESS = "GET_OFFER_CARD_SUCCESS";
export const GET_OFFER_CARD_FAILURE = "GET_OFFER_CARD_FAILURE";


export const EDIT_OFFER_CARD_LOADING = "EDIT_OFFER_CARD_LOADING";
export const EDIT_OFFER_CARD_SUCCESS = "EDIT_OFFER_CARD_SUCCESS";
export const EDIT_OFFER_CARD_FAILURE = "EDIT_OFFER_CARD_FAILURE";

export const DEL_OFFER_CARD_LOADING = "DEL_OFFER_CARD_LOADING";
export const DEL_OFFER_CARD_SUCCESS = "DEL_OFFER_CARD_SUCCESS";
export const DEL_OFFER_CARD_FAILURE = "DEL_OFFER_CARD_FAILURE";

export const CLEAN_OFFER_CARD_DATA = "CLEAN_OFFER_CARD_DATA"

export const GET_CARD_DATA_LOADING = "GET_CARD_DATA_LOADING";
export const GET_CARD_DATA_SUCCESS = "GET_CARD_DATA_SUCCESS";
export const GET_CARD_DATA_FAILURE = "GET_CARD_DATA_FAILURE";

export const PLACE_ORDER_LOADING = "PLACE_ORDER_LOADING";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const CLEAN_PLACE_ORDER_DATA = "CLEAN_PLACE_ORDER_DATA"

export const OUT_OF_STOCK_LOADING = "OUT_OF_STOCK_LOADING";
export const OUT_OF_STOCK_SUCCESS = "OUT_OF_STOCK_SUCCESS";
export const OUT_OF_STOCK_FAILURE = "OUT_OF_STOCK_FAILURE";

export const CLEAN_OUT_OF_STOCK_DATA = "CLEAN_OUT_OF_STOCK_DATA";


export const ADD_STOCK_LOADING = "ADD_STOCK_LOADING";
export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_FAILURE = "ADD_STOCK_FAILURE";

export const GET_STOCK_LOADING = "GET_STOCK_LOADING";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";
export const GET_STOCK_FAILURE = "GET_STOCK_FAILURE";


export const EDIT_STOCK_LOADING = "EDIT_STOCK_LOADING";
export const EDIT_STOCK_SUCCESS = "EDIT_STOCK_SUCCESS";
export const EDIT_STOCK_FAILURE = "EDIT_STOCK_FAILURE";

export const MODIFY_STOCK_LOADING = "MODIFY_STOCK_LOADING";
export const MODIFY_STOCK_SUCCESS = "MODIFY_STOCK_SUCCESS";
export const MODIFY_STOCK_FAILURE = "MODIFY_STOCK_FAILURE";

export const DEL_STOCK_LOADING = "DEL_STOCK_LOADING";
export const DEL_STOCK_SUCCESS = "DEL_STOCK_SUCCESS";
export const DEL_STOCK_FAILURE = "DEL_STOCK_FAILURE";

export const UPDATE_STOCK_VISIBILITY_LOADING = "UPDATE_STOCK_VISIBILITY_LOADING";
export const UPDATE_STOCK_VISIBILITY_SUCCESS = "UPDATE_STOCK_VISIBILITY_SUCCESS";
export const UPDATE_STOCK_VISIBILITY_FAILURE = "UPDATE_STOCK_VISIBILITY_FAILURE";

export const CLEAN_STOCK_DATA = "CLEAN_STOCK_DATA"

export const ADD_PARTY_LOADING = "ADD_PARTY_LOADING";
export const ADD_PARTY_SUCCESS = "ADD_PARTY_SUCCESS";
export const ADD_PARTY_FAILURE = "ADD_PARTY_FAILURE";

export const GET_PARTY_LOADING = "GET_PARTY_LOADING";
export const GET_PARTY_SUCCESS = "GET_PARTY_SUCCESS";
export const GET_PARTY_FAILURE = "GET_PARTY_FAILURE";

export const EDIT_PARTY_LOADING = "EDIT_PARTY_LOADING";
export const EDIT_PARTY_SUCCESS = "EDIT_PARTY_SUCCESS";
export const EDIT_PARTY_FAILURE = "EDIT_PARTY_FAILURE";

export const DEL_PARTY_LOADING = "DEL_PARTY_LOADING";
export const DEL_PARTY_SUCCESS = "DEL_PARTY_SUCCESS";
export const DEL_PARTY_FAILURE = "DEL_PARTY_FAILURE";


export const CLEAN_PARTY_DATA = "CLEAN_PARTY_DATA"


export const GET_PURCHASE_HISTORY_LOADING = "GET_PURCHASE_HISTORY_LOADING";
export const GET_PURCHASE_HISTORY_SUCCESS = "GET_PURCHASE_HISTORY_SUCCESS";
export const GET_PURCHASE_HISTORY_FAILURE = "GET_PURCHASE_HISTORY_FAILURE";
export const CLEAN_PURCHASE_HISTORY_DATA = "CLEAN_PURCHASE_HISTORY_DATA";

export const GET_SELL_HISTORY_LOADING = "GET_SELL_HISTORY_LOADING";
export const GET_SELL_HISTORY_SUCCESS = "GET_SELL_HISTORY_SUCCESS";
export const GET_SELL_HISTORY_FAILURE = "GET_SELL_HISTORY_FAILURE";
export const CLEAN_SELL_HISTORY_DATA = "CLEAN_SELL_HISTORY_DATA";



export const CHANGE_PASS_LOADING = "CHANGE_PASS_LOADING";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAILURE = "CHANGE_PASS_FAILURE";


export const MOVE_TO_LOOSE_LOADING = "MOVE_TO_LOOSE_LOADING";
export const MOVE_TO_LOOSE_SUCCESS = "MOVE_TO_LOOSE_SUCCESS";
export const MOVE_TO_LOOSE_FAILURE = "MOVE_TO_LOOSE_FAILURE";


export const GET_LOOSE_LOADING = "GET_LOOSE_LOADING";
export const GET_LOOSE_SUCCESS = "GET_LOOSE_SUCCESS";
export const GET_LOOSE_FAILURE = "GET_LOOSE_FAILURE";


export const CLEAN_MTL = "CLEAN_MTL";


export const SEND_MSG_LOADING = "SEND_MSG_LOADING";
export const SEND_MSG_SUCCESS = "SEND_MSG_SUCCESS";
export const SEND_MSG_FAILURE = "SEND_MSG_FAILURE";

export const GET_MSG_LOADING = "GET_MSG_LOADING";
export const GET_MSG_SUCCESS = "GET_MSG_SUCCESS";
export const GET_MSG_FAILURE = "GET_MSG_FAILURE";



export const CLEAN_MSG = "CLEAN_MSG";
export const CLEAN_LTD = "CLEAN_LTD";


export const ADD_BILTI_LOADING = "ADD_BILTI_LOADING";
export const ADD_BILTI_SUCCESS = "ADD_BILTI_SUCCESS";
export const ADD_BILTI_FAILURE = "ADD_BILTI_FAILURE";



export const LTD_LOADING = "LTD_LOADING";
export const LTD_SUCCESS = "LTD_SUCCESS";
export const LTD_FAILURE = "LTD_FAILURE";



export const ADD_ORD_SEQ_LOADING = "ADD_ORD_SEQ_LOADING";
export const ADD_ORD_SEQ_SUCCESS = "ADD_ORD_SEQ_SUCCESS";
export const ADD_ORD_SEQ_FAILURE = "ADD_ORD_SEQ_FAILURE";


export const GET_ORD_SEQ_LOADING = "GET_ORD_SEQ_LOADING";
export const GET_ORD_SEQ_SUCCESS = "GET_ORD_SEQ_SUCCESS";
export const GET_ORD_SEQ_FAILURE = "GET_ORD_SEQ_FAILURE";

export const EDIT_ORD_SEQ_LOADING = "EDIT_ORD_SEQ_LOADING";
export const EDIT_ORD_SEQ_SUCCESS = "EDIT_ORD_SEQ_SUCCESS";
export const EDIT_ORD_SEQ_FAILURE = "EDIT_ORD_SEQ_FAILURE";


export const GET_RATE_CARD_LOADING = "GET_RATE_CARD_LOADING";
export const GET_RATE_CARD_SUCCESS = "GET_RATE_CARD_SUCCESS";
export const GET_RATE_CARD_FAILURE = "GET_RATE_CARD_FAILURE";


export const GET_P_BILLS_LOADING = "GET_P_BILLS_LOADING";
export const GET_P_BILLS_SUCCESS = "GET_P_BILLS_SUCCESS";
export const GET_P_BILLS_FAILURE = "GET_P_BILLS_FAILURE";


export const CLEAN_ORD_SEQ = "CLEAN_ORD_SEQ";






