
import axios from "../Config/Interceptor1";


// import axios from 'axios'
import { getDashboardBegin , getDashboardSuccess , getDashboardFailure } from './ActionCreators'



import { Base_URL } from '../Config/BaseUrlConfig'




export const getDashboard = (data) => {
      return (dispatch) => {
        dispatch(getDashboardBegin());

        axios.get(Base_URL + '/admin/getDashboardCount', )
            .then((res) => {
                console.log(res)
                dispatch(getDashboardSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDashboardFailure(err.response.data));

                }
            })
    }

}


















