import axios from "../Config/Interceptor1";
import { Base_URL } from "../Config/BaseUrlConfig";

import {GetCardDataBegin, GetCardDataSuccess, GetCardDataFailure} from './ActionCreators'
import {PlaceOrderBegin, PlaceOrderSuccess, PlaceOrderFailure} from './ActionCreators'




export const GetCardData = (x) => {
   return (dispatch) => {
     dispatch(GetCardDataBegin());

     axios.get(Base_URL + '/admin/getCardByNumber', {params :{card_number:x}})
         .then((res) => {
             console.log(res.data, "response data")
             dispatch(GetCardDataSuccess(res.data));
         }).catch((err) => {
           //   console.log(err)
             if (!err.response) {
                //  alert("Something Went wrong : Please Check Network or Server Connectivity")

             }
             else {
                 dispatch(GetCardDataFailure(err.response.data));

             }
         })
 }

}


export const PlaceOrder = (data) => {
       

   return (dispatch) => {
     dispatch(PlaceOrderBegin());

     axios.post(Base_URL + '/app/purchaseOrder', data)
         .then((res) => {
             // console.log(res.data)
             dispatch(PlaceOrderSuccess(res.data));
         }).catch((err) => {
             // console.log(err)
             if (!err.response) {
                //  alert("Something Went wrong : Please Check Network or Server Connectivity")

             }
             else {
                 dispatch(PlaceOrderFailure(err.response.data));

             }
         })
 }

}