import {ADD_GALLERY_LOADING , ADD_GALLERY_SUCCESS , ADD_GALLERY_FAILURE} from '../Actions/Types';
import {GET_GALLERY_LOADING , GET_GALLERY_SUCCESS , GET_GALLERY_FAILURE} from '../Actions/Types';
import {EDIT_GALLERY_LOADING , EDIT_GALLERY_SUCCESS , EDIT_GALLERY_FAILURE} from '../Actions/Types';
import {DEL_GALLERY_LOADING , DEL_GALLERY_SUCCESS , DEL_GALLERY_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_GALLERY_DATA} from '../Actions/Types';



const AddGallery = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
        }

    case CLEAN_GALLERY_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                
            }
    

    case ADD_GALLERY_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_GALLERY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
      
    case GET_GALLERY_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_GALLERY_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };
          
    case EDIT_GALLERY_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_GALLERY_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };

    case DEL_GALLERY_LOADING:
        return {
          ...state,
          loading: true,
          delerror: {},
          deldata:[{}],
        };
    case DEL_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          delerror: {},
          deldata: action.data,
        };
    case DEL_GALLERY_FAILURE:
        return {
          ...state,
          loading: false,
          delerror: action.error,
          deldata: [{}],
        };

    default:
        return state

}
}



export default AddGallery;
