import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import MainLayout from '../layouts/MainLayout';
import DashboardView from '../views/reports/DashboardView';
import LoginView from '../views/auth/LoginView';
import NotFoundView from '../views/errors/NotFoundView';
import ProductListView from '../views/product/ProductListView';
import RegisterView from '../views/auth/RegisterView';
import SettingsView from '../views/settings/SettingsView';
import LoginPage from '../views/auth/Loginpage';



const routes = [

  {
    path: '/',
    element: <MainLayout />,
    
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <LoginPage /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }

];

export default routes;
