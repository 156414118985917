import React , {Component} from 'react';
import { Link as RouterLink, Navigate, useNavigate   } from 'react-router-dom';


import { connect } from "react-redux";

import {login} from '../../Actions/Login';



import LoginView from './LoginView'

class LoginPage extends Component {




  constructor(props  ) {

  
    super(props);
    this.state = {

       mobile:"",
       password:"",
       mobileErrorcolor:"",
       mobileErrortext :"",
       passwordErrorcolor:"",
       paswordErrorText:"",
       status : false,
    
        };







       

console.log(this.props)
       
  }


UNSAFE_componentWillReceiveProps(nextProps){
  console.log(nextProps)

    if(nextProps.loginData.token)
    {
       this.setState({status:true})
    }
    
}

 
chnageme = () =>{
    this.setState({status: !this.state.status})
}




  handleChange = (e) =>{
    this.setState({mobileErrorcolor:"" , mobileErrortext:"" , passwordErrorcolor:"", paswordErrorText:""})
    this.setState({ [e.target.name]: e.target.value });
  }


  handleSubmit = e =>{
    var phoneno = /^[6-9]\d{9}$/

    if(!this.state.mobile  && !this.state.password  )
    {
      
       this.setState({mobileErrorcolor:'red' , passwordErrorcolor:"red" , mobileErrortext:"Mobile Number is Required." , paswordErrorText:"Password is Required."})
    }
    else if (!this.state.mobile || this.state.mobile === null || this.state.mobile === '' || this.state.mobile === undefined)
    {
      this.setState({mobileErrorcolor:'red' , passwordErrorcolor:"" , mobileErrortext:"Mobile Number is Required." , paswordErrorText:""})
    }
    else if (!this.state.password || this.state.password === null || this.state.password === '' || this.state.password === undefined)
    {
      this.setState({mobileErrorcolor:'' , passwordErrorcolor:"red" , mobileErrortext:"" , paswordErrorText:"Password is required."})
    }
    else if(!this.state.mobile.match(phoneno)  ){
      this.setState({mobileErrorcolor:'red' , passwordErrorcolor:"" , mobileErrortext:"Invalid Mobile Number" , paswordErrorText:""})

    }
    else if(this.state.password.length < 6  ){
      this.setState({mobileErrorcolor:'red' , passwordErrorcolor:"" , mobileErrortext:"" , paswordErrorText:"Password should be at least 6 char long."})

    }
    else
    {
      let session = {
        mobile:this.state.mobile,
        password : this.state.password
      }
       
      this.props.login(session)
    
    }

  }


componentDidMount()
{
  console.log(this.props.data)
}

render(){

  return (
   <>
   <LoginView 
   mystate = { this.state}
   submitfun = {this.handleSubmit}
   handlechangefun = {this.handleChange}
  //  status = {this.state.status}
   change = {this.chnageme}


   />
   </>
  );
};

}

const mapStateToProps = state => ({


  // data : state.setuser.data,
  loginData : state.login.data,
  loginError :state.login.error

 

});

export default connect(
  mapStateToProps,
  {login }
)(LoginPage);


