
import axios from "../Config/Interceptor1";


// import axios from 'axios'


import { GetPurchaseHistoryBegin , GetPurchaseHistorySuccess , GetPurchaseHistoryFailure } from './ActionCreators'
import { GetSellHistoryBegin , GetSellHistorySuccess , GetSellHistoryFailure } from './ActionCreators'
import { Base_URL } from '../Config/BaseUrlConfig'



export const GetPurchaseHistory = (parameter) => {
    return (dispatch) => {
      dispatch(GetPurchaseHistoryBegin());

      axios.get(Base_URL + '/admin/getPurchaseHistory', {params:parameter})
          .then((res) => {
            //   console.log(res)
              dispatch(GetPurchaseHistorySuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  // alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetPurchaseHistoryFailure(err.response.data));

              }
          })
  }

}



export const GetSellHistory = (parameter) => {
   return (dispatch) => {
     dispatch(GetSellHistoryBegin());

     axios.get(Base_URL + '/admin/getSellHistory', {params:parameter})
         .then((res) => {
           //   console.log(res)
             dispatch(GetSellHistorySuccess(res.data));
         }).catch((err) => {
           //   console.log(err)
             if (!err.response) {
                 alert("Something Went wrong : Please Check Network or Server Connectivity")

             }
             else {
                 dispatch(GetSellHistoryFailure(err.response.data));

             }
         })
 }

}
