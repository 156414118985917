import axios from "../Config/Interceptor1";
import { Base_URL } from "../Config/BaseUrlConfig";



import {OutOfStockBegin, OutOfStockSuccess, OutOfStockFailure} from './ActionCreators'

export const MarkOutOfStock = (data) => {
   
   return (dispatch) => {
       dispatch(OutOfStockBegin());

       
       axios.post(Base_URL + '/admin/updateOutOfStock' ,data)
           .then((res) => {
               // console.log(res.data)
               dispatch(OutOfStockSuccess(res.data));
           }).catch((err) => {
               // console.log(err)
               if (!err.response) {
                //    alert("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(OutOfStockFailure(err.response.data));

               }
           })
   }
}