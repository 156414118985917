import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Config/Toast'
import {ToastContainer} from 'react-toastify'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {AddUsers} from '../../Actions/users';
import {CleanUsersData} from '../../Actions/ActionCreators'


const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Card Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  }

}));

const AddBanners = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()




  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  name :"",
  email :"",
  mobile :"",
  password:"",
  name_of_firm:"",
  address:"",
  
  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleSubmit = () =>{
      dispatch(AddUsers(values));
      setValues({
         name :"",
         email :"",
         mobile :"",
         password:"",
         name_of_firm:"",
         address:"",
  
      })

    

  }



  const addmsg = useSelector(state => state.AddUsers && state.AddUsers.data && state.AddUsers.data.message ? state.AddUsers.data.message : null  )
  const adderror = useSelector(state => state.AddUsers && state.AddUsers.error && state.AddUsers.error.message ? state.AddUsers.error.message : null  )


  useEffect( () => {

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string')
      {
        tostS("Added Successfully")
      }
      if(adderror !==null)
      {
        tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(CleanUsersData())
   

    })

  
     
    }, [addmsg , adderror])


  return (
    <Page className={classes.root} title="Add Users">
      <ToastContainer    />

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Users" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
                type="text"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
            <TextField
                type="email"
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                type="number"
                fullWidth
                label="Mobile Number"
                name="mobile"
                onChange={handleChange}
                required
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                
                label="Password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
                type="password"
              />
            </Grid>  
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label="Name of Firm"
                name="name_of_firm"
                onChange={handleChange}
                required
                value={values.name_of_firm}
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                type="address"
                fullWidth
                label="Address"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>                                           
          </Grid>

          
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBanners;
