import React,{useRef} from 'react';
import { useReactToPrint } from "react-to-print";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import moment from 'moment'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',

    color: 'black',
  border:"1px solid black"
  },
  [`&.${tableCellClasses.body}`]: {
  
    fontSize: 14,
    marginTop:100,
    border:"1px solid black"
    
    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
    marginTop:100,
    innerHeight:200,
    border:"1px solid black"
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border:"1px solid black",
    border: 0,
    marginTop:100

  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const App = () => {  
  const componentRef = useRef();
  const {state} = useLocation();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  

  return (<>
  <div class="print__section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
        <Button
                    // color="success"
                    // onClick={submitfun}
                    onClick={handlePrint}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"green" , color:"white"  }}
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    Print Sheet
                  </Button>
                 
          <div ref={componentRef} className="card">
            <div class="float__start">
              <h3 class="card-title mb-0" style={{textAlign:"center"}}>Order Information  (CP Cards)   </h3>
            </div>
            <hr />
            <div class="float__infoss">

  


               <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700  }} size="large" aria-label="customized table">
      <TableHead>
          <TableRow >
            <StyledTableCell>Card Number</StyledTableCell>
            
            <StyledTableCell align="left">Quantity</StyledTableCell>
            <StyledTableCell align="left">Firm Name</StyledTableCell>
            <StyledTableCell align="left">Order Number</StyledTableCell>
            <StyledTableCell align="left">Order Date</StyledTableCell>
          </TableRow>
        </TableHead>
      {state.map((row , i) => (
        <>
        {null}
        {row.order_details && row.order_details.length && Array.isArray(row.order_details) ?
        <>
              {row.order_details.length === 1 ?
                <>
              
                    
                      {row.order_details.map((data , j)=>(
                        <TableBody >
                        
                            <StyledTableRow style={{height:"250px" , border:"1px solid black"}}  key={j}>
                              <StyledTableCell style={{ border:"1px solid black"}}  align="left">{data.card_number}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{data.quantity}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{row.name_of_firm}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{row.order_number}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{moment(row.order_date).format("DD/MM/YYYY")}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        ))}

                </>

               :  
               <>
                        <TableBody >
              
               

                        
               {row.order_details.map((data , j)=>(

                 <>
                    {j < 1 ?
                            <StyledTableRow style={{height:"250px" , width:"100%" , border:"1px solid black"}}  >
                              <StyledTableCell style={{ border:"1px solid black"}}  align="left">

                                {/* {data.card_number} */}
                            {row.card_array.map((k , m) =>(
                              <>
                              <Typography>{m+1 + " . " + k.card_num}</Typography>
                              <br/>
                              </>    
                            ))}
                              
                                
                                </StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">

                              {row.card_array.map((k , m) =>(
                                <>
                              <Typography>{k.quantity}</Typography>
                              <br/>
                              </>    
                            ))}
                            </StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{row.name_of_firm}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{row.order_number}</StyledTableCell>
                              <StyledTableCell style={{ border:"1px solid black"}} align="left">{moment(row.order_date).format("DD/MM/YYYY")}</StyledTableCell>
                            </StyledTableRow>   : null }

                </>       
                              ))}
                            
                        </TableBody>
               </>
            }

         </>       
                
        :null}
        </>
          ))}


      </Table>
    </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>     
  )
}
export default App
