import {GET_SUMMARY_LOADING , GET_SUMMARY_SUCCESS , GET_SUMMARY_FAILURE} from '../Actions/Types';



import {CLEAN_ALL_DATA  , CLEAN_SUMMARY} from '../Actions/Types';



const StockSummary = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getdata: [{}],
            geterror: {},

        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},

        }

        case CLEAN_SUMMARY:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},

        }

    


    case GET_SUMMARY_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_SUMMARY_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.getdata,
        };
    case GET_SUMMARY_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.geterror,
          getdata: [{}],
        };
          
 
    
    default:
        return state

}
}



export default StockSummary;
