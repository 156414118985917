import {GET_DASHBOARD_LOADING , GET_DASHBOARD_SUCCESS , GET_DASHBOARD_FAILURE} from '../Actions/Types';
import {CLEAN_ALL_DATA ,} from '../Actions/Types';



const Home = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getdata: [{}],
            geterror: {},
        }
    }




switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},

        }

        case GET_DASHBOARD_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_DASHBOARD_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_DASHBOARD_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };
      
       

     

    default:
        return state






}
}



export default Home;
