import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  }
}));

const Gallery = ({ className,data, ...rest }) => {
  const classes = useStyles();
  
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL Gallery Items
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
            {data}

            </Typography>
          </Grid>
          
        </Grid>
        
      </CardContent>
    </Card>
  );
};

Gallery.propTypes = {
  className: PropTypes.string
};

export default Gallery;
