import React , {useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch , shallowEqual } from 'react-redux';
import {ToastContainer} from 'react-toastify'


import {useSelector} from 'react-redux'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from '../../../src/icons/Facebook';
import GoogleIcon from '../../../src/icons/Google';
import Page from '../src/../../components/Page';

import { makeStyles as mk } from '@material-ui/core/styles';
import {Button as Bt2} from '@material-ui/core/Button';
import { tostS, tostW, tostE } from "../../Config/Toast";

import {cleanLoginData } from '../../Actions/ActionCreators'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const useStylesw = mk({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const LoginView = ({mystate , submitfun , handlechangefun , status, change}) => {
  const classes = useStyles();
  const classes2 = useStylesw();
  const navigate = useNavigate();
  const dispatch = useDispatch();


   
  const Delmsg = useSelector(state => state.login.error && state.login.error.message ? state.login.error.message : null  )


  useEffect( () => {



    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanLoginData())

    })


    
  }, [ Delmsg  ])




  const token = useSelector(state => state.login.data.token)


  if(token || !token === null || !token === undefined || !token === '')
  {
    // console.log("test")
    navigate('/app/dashboard')
  }

  return(
    <Page className={classes.root} title="Cards || Login">
         <ToastContainer    />
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
     
              <form  style={{marginTop:10}} >
                {/* <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">Welcome in Sambhar Project</Typography>
               </Box> */}
                <Grid container spacing={3}>
                 <Grid item xs={12} md={12} >
                    <Button className={classes2.root} color="'linear-gradient(45deg, #FF1100 30%, #FFFF00 90%)'" fullWidth  size="large" variant="contained">
                    {/* <Button color="success" onClick={change} fullWidth startIcon={<FacebookIcon />}  size="large" variant="contained"> */}
                      Welcome In Sambhar Project
                    </Button>
                  </Grid>

                    {/*<Grid item xs={12} md={6} >
                    <Button fullWidth startIcon={<GoogleIcon />}  size="large" variant="contained" >Login with Google</Button>
                  </Grid> */}
                </Grid>
                {/* <Box mt={3} mb={1}>
                  {status === true ?
                  <Typography align="center" color="textSecondary" variant="body1">Checked</Typography>
                  :
                  <Typography align="center" color="textSecondary" variant="body1">Checked 2r</Typography>
}
                </Box> */}

                <TextField
                  fullWidth
                  label="Mobile Number"
                  margin="normal"
                  name="mobile"
                  onChange={handlechangefun}
                  type="number"
                  value={mystate.mobile}
                  variant="outlined"
                  
                />
                {mystate.mobileErrortext !== ''? 
                <Typography color="textSecondary" style={{color:"red"}} gutterBottom variant="body2">{'*'+ mystate.mobileErrortext}</Typography>
                :null}

                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  onChange={handlechangefun}
                  type="password"
                  value={mystate.password}
                  variant="outlined"
                />
                {mystate.paswordErrorText !== ''? 
                <Typography color="textSecondary" style={{color:"red"}} gutterBottom variant="body2">{'*'+mystate.paswordErrorText}</Typography>
                :null}
                <Box my={2}>
                  <Button
                    color="primary"
                    onClick={submitfun}
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
