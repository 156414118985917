import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Config/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';

import tableIcons from '../../Config/IconsFile'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import {AddBox , ArrowDownward} from '@material-ui/icons'


import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Chip
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


import {GetGallery} from '../../Actions/Gallery'
import { CleanGalleryData } from "../../Actions/ActionCreators";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  
  const listData = useSelector((state) =>
  state.AddGallery &&
  state.AddGallery.getdata &&
    state.AddGallery.getdata.length &&
    Array.isArray(state.AddGallery.getdata)
      ? state.AddGallery.getdata
      : []
  );



  
useEffect( () => {

    dispatch(GetGallery())

    
  }, [])


  return (
    <>
      <Page className={classes.root} title="Gallery">
      <ToastContainer    />      
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Cards"
      columns={[
        {
          title: 'Images',
          field: 'images',
          editable: "never",
          render: rowData => (
            <Avatar
            className={useStyles.avatar}
            src={rowData.images && rowData.images.length && Array.isArray(rowData.images) ?  rowData.images[0] : ''}
          >
          </Avatar>
          ),
        },
        { title: 'Card Number', field: 'card_number',editable: "never", },
        { title: 'Price', field: 'price',editable: "never", },     
        { title: 'Discount', field: 'discount',editable: "never", },     
        { title: 'Quantity', field: 'quantity',editable: "never", },     
        { title: 'Card Type', field: 'card_type',editable: "never", },
        { 
          title: 'Stock Status', 
          render: rowData =>(
            
            rowData.is_stock_out ? <Chip label="Out of Stock" style={{background:"#ff0000"}} size="small" /> : <Chip label="In Stock" style={{background:"#00ff00"}} size="small" />

          )
        },       
        
      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
