import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { loginUsrBegin , loginUsrSuccess , loginUsrFailure } from './ActionCreators'
import { refreshTokenBegin , refreshTokenSuccess , refreshTokenFailure } from './ActionCreators'
import { logoutUsrBegin , logoutUsrSuccess , logoutUsrFailure } from './ActionCreators'
import { Base_URL } from '../Config/BaseUrlConfig'





export const login = (session) => {
    console.log(session);
    return (dispatch) => {
        dispatch(loginUsrBegin());

        axios.post(Base_URL + '/admin/loginAdmin', session)
            .then((res) => {
              var temp  = res.data.obj
                if(res.data.obj)
                {
                  
                  temp['token'] = res.headers.authorization
                }

                localStorage.setItem('x-auth', res.headers.authorization);
                dispatch(loginUsrSuccess(temp));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(loginUsrFailure(err.response.data));

                }
            })
    }
}




export const refreshToken = () => {
  console.log("refrsh Toekn mai aaay")
  console.log("112")
    return (dispatch) => {
      dispatch(refreshTokenBegin());
  
      return axios.get( Base_URL + '/superadmin/refreshtoken')
      .then( (res) => {

        console.log("112")
        localStorage.setItem('x-auth', res.headers.authorization);
        dispatch(refreshTokenSuccess(res.headers.authorization));
        return Promise.resolve(res.headers.authorization);
      }).catch( (err) => {
        console.log("REfrsh token error")
        console.log(err)
        console.log("REfrsh token error")
        dispatch(refreshTokenFailure(err.response.data));
        return Promise.reject();
      })
    }
  }



export const logout = (sessionout) => {
    console.log(sessionout);
    return (dispatch) => {
        dispatch(logoutUsrBegin());

        axios.get(Base_URL + '/admin/logout', {params : {'id' : sessionout.id}})
            .then((res) => {
              console.log(res)
                dispatch(logoutUsrSuccess(res.data));
            }).catch((err) => {
              console.log(err)
                dispatch(logoutUsrFailure(err.response.data));
            })
    }
}








