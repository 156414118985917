import {SEND_MSG_LOADING , SEND_MSG_SUCCESS , SEND_MSG_FAILURE} from '../Actions/Types';
import {GET_MSG_LOADING , GET_MSG_SUCCESS , GET_MSG_FAILURE} from '../Actions/Types';


import {CLEAN_ALL_DATA , CLEAN_MSG} from '../Actions/Types';



const Msgs = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
        
            
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
 
        }

    case CLEAN_MSG:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
          
                
            }
    

    case SEND_MSG_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case SEND_MSG_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case SEND_MSG_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.err,
          data: [{}],
        };
      
    case GET_MSG_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_MSG_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.mdata,
        };
    case GET_MSG_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.merr,
          getdata: [{}],
        };
          

    default:
        return state

}
}



export default Msgs;
