import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "default",
    height: 100,
    width: 100
  },
  differenceIcon: {
    color: "default"
  },
  differenceValue: {
    color: "default",
    marginRight: theme.spacing(1)
  }
}));

const Orders = ({ className,data, ...rest }) => {
  const classes = useStyles();
  const emIcons = "/static/emigrants.png"


  
  return (
   
   <Grid container justify="space-between" spacing={3}>
      <Grid item lg={3} sm={12} md={3}>
         <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
               <Typography color="textSecondary" gutterBottom variant="h6">
                 PENDING Orders
               </Typography>
               <Typography color="textPrimary" variant="h3">
           
                  {data !== null  && data.p_order ? data.p_order : 0  }
               </Typography>
            </CardContent>
         </Card>
      </Grid>    
      <Grid item lg={3} sm={12} md={3}>
         <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
               <Typography color="textSecondary" gutterBottom variant="h6">
                 COMPLETED Orders
               </Typography>
               <Typography color="textPrimary" variant="h3">
                  {/* {data.c_order} */}
                  {data !== null  && data.c_order ? data.c_order : 0  }


               </Typography>
            </CardContent>
         </Card>
      </Grid>    
      <Grid item lg={3} sm={12} md={3}>
         <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
               <Typography color="textSecondary" gutterBottom variant="h6">
                 DISPATCHED Orders
               </Typography>
               <Typography color="textPrimary" variant="h3">
                  {/* {data.dis_order} */}
                  {data !== null  && data.dis_order ? data.dis_order : 0  }


               </Typography>
            </CardContent>
         </Card>
      </Grid>    
      <Grid item lg={3} sm={12} md={3}>
         <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
               <Typography color="textSecondary" gutterBottom variant="h6">
                 REJECTED Orders
               </Typography>
               <Typography color="textPrimary" variant="h3">
                  {/* {data.del_order} */}
                  {data !== null  && data.del_order ? data.del_order : 0 }
               </Typography>
            </CardContent>
         </Card>
      </Grid>    
   </Grid>
        
      
  );
};

Orders.propTypes = {
  className: PropTypes.string
};

export default Orders;
