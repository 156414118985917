import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Page from '../../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../../Config/Toast'
import {ToastContainer} from 'react-toastify'
import {Navigate, useLocation , useNavigate} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import {EditParty} from '../../../Actions/Party'
import {CleanPartyData} from '../../../Actions/ActionCreators'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Card Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  }

}));

const AddBanners = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const navigate = useNavigate();



  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  // const EditData = useSelector(state => state.)
  const {state} = useLocation();
  const [values, setValues] = useState({
   
  item_id :state.item_id,
  price :state.price,
  quantity :state.quantity,
  discount:state.discount,
  card_number:state.card_number,
  card_type:state.card_type,
  remark:state.remark,
  other_detail:state.other_detail,
  img : "",
  images:state.images && state.images.length && Array.isArray(state.images) ? state.images :"",
  fixImg : "/static/images/avatars/upload.png",
  tempImage1:""

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  const handleChangeType = (event) => {
    setValues({
      ...values,
      ['card_type']: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }


  const handleSubmit = () =>{

    console.log(values)

    dispatch(EditParty(values))
    navigate('/app/gallery')
  }



  // 

  // useEffect( () => {

  //   let x  = new Promise((resolve ,reject)=>{
  //     if(editmsg !== null && typeof(editmsg) === 'string')
  //     {
  //       tostS("edited Successfully")
  //     }
  //     if(editerror !==null)
  //     {
  //       tostE(editerror)
  //     }

  //     resolve()

  //   })
  //   x.then(()=>{

  //   dispatch(CleanGalleryData())
   

  //   })

  
     
  //   }, [editmsg , editerror])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['img']:fileUploaded , ['tempImage1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }

  };
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Edit Gallery Items"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={ values.tempImage1 !== "" ? values.tempImage1 :  values.images }
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>

          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
          Upload picture
        </Button>
      </CardActions>
    </Card>
            {/* <Profile /> */}
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Cards to New Arrivals" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label="Price of Card"
                name="price"
                onChange={handleChange}
                required
                value={values.price}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                type="number"
                fullWidth
                label="Quantity of Cards"
                name="quantity"
                onChange={handleChange}
                required
                value={values.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                type="number"
                fullWidth
                label="Discount"
                name="discount"
                onChange={handleChange}
                required
                value={values.discount}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                
                label="Card Number"
                name="card_number"
                onChange={handleChange}
                required
                value={values.card_number}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Select Card Type*</InputLabel>
            <Select
              fullWidth
              label = "Card Type"              
              value={values.card_type}
              onChange={handleChangeType}
              name="card_type"
              required
              variant="outlined"
            >
              <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value='Wedding Cards'>Wedding Cards</MenuItem>
          <MenuItem value='Box Cards'>Box Cards</MenuItem>
          <MenuItem value='Invitation Cards'>Invitation Cards</MenuItem>
          <MenuItem value='Envelopes'>Envelopes</MenuItem>
            </Select>
            </FormControl>
            </Grid>
            
            <Grid item md={6} xs={12}>
           
            <TextField
                fullWidth
                multiline
                rows={4}
                label="Remark"
                name="remark"
                onChange={handleChange}
                required
                value={values.remark}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField                
                fullWidth
                multiline
                rows={4}
                label="Other Details"
                name="other_detail"
                onChange={handleChange}
                required
                value={values.other_detail}
                variant="outlined"
              />
            </Grid>
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBanners;
