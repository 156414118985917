import {ADD_NEW_ARRIVAL_LOADING , ADD_NEW_ARRIVAL_SUCCESS , ADD_NEW_ARRIVAL_FAILURE} from '../Actions/Types';
import {GET_NEW_ARRIVAL_LOADING , GET_NEW_ARRIVAL_SUCCESS , GET_NEW_ARRIVAL_FAILURE} from '../Actions/Types';
import {EDIT_NEW_ARRIVAL_LOADING , EDIT_NEW_ARRIVAL_SUCCESS , EDIT_NEW_ARRIVAL_FAILURE} from '../Actions/Types';
import {DEL_NEW_ARRIVAL_LOADING , DEL_NEW_ARRIVAL_SUCCESS , DEL_NEW_ARRIVAL_FAILURE} from '../Actions/Types';
import {SHIFT_TO_GALLERY_LOADING , SHIFT_TO_GALLERY_SUCCESS , SHIFT_TO_GALLERY_FAILURE} from '../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_NEW_ARRIVAL_DATA} from '../Actions/Types';



const AddNewArrival = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},            
            shiftData: [{}],
            shiftError:{},
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},    
            shiftData: [{}],
            shiftError:{},
        }

    case CLEAN_NEW_ARRIVAL_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                shiftData: [{}],
                shiftError:{},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},   
            }
    

    case ADD_NEW_ARRIVAL_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_NEW_ARRIVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_NEW_ARRIVAL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
          
    case GET_NEW_ARRIVAL_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_NEW_ARRIVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_NEW_ARRIVAL_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };


    case EDIT_NEW_ARRIVAL_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_NEW_ARRIVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_NEW_ARRIVAL_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };

    case DEL_NEW_ARRIVAL_LOADING:
        return {
          ...state,
          loading: true,
          delerror: {},
          deldata:[{}],
        };
    case DEL_NEW_ARRIVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          delerror: {},
          deldata: action.data,
        };
    case DEL_NEW_ARRIVAL_FAILURE:
        return {
          ...state,
          loading: false,
          delerror: action.error,
          deldata: [{}],
        };
              
    case SHIFT_TO_GALLERY_LOADING:
        return {
          ...state,
          loading: true,
          shiftError: {},
          shiftData:[{}],
        };
    case SHIFT_TO_GALLERY_SUCCESS:
        return {
          ...state,
          loading: false,
          shiftError: {},
          shiftData: action.data,
        };
    case SHIFT_TO_GALLERY_FAILURE:
        return {
          ...state,
          loading: false,
          shiftError: action.error,
          shiftData: [{}],
        };
          
    default:
        return state

}
}



export default AddNewArrival;
