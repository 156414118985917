import React, { useState , useEffect } from 'react';
import { Link as RouterLink  , useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {  useDispatch , shallowEqual } from 'react-redux'

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../../src/components/Logo';
import {logout} from '../../Actions/Login'
import {cleanAllData} from '../../Actions/ActionCreators'
import { tostS } from '../../Config/Toast';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: 
  {
    width: 60,
    height: 60
  }
}));

const TopBar = ({className,onMobileNavOpen,...rest }) => 
{
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch()




const token = useSelector(state => state.login.data.token)
const uid = useSelector(state => state.login.data.id)


  if(!token || token === null || token === undefined || token === '')
  {
    navigate('/login')
  }


  const  handleSubmit = () =>{
    var sessionOut = {
      id:uid
    }
    // dispatch(logout(sessionOut))
    let x = new Promise((resolve , reject)=>{

     dispatch(cleanAllData())
    
    resolve()  
    })
    x.then(()=>{
      tostS("Logged Out Successfully!!")
      // dispatch(logout(sessionOut)) 
    })
  }


  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={1}
      {...rest}
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <Logo />
        </RouterLink> */}
        <Typography
            style={{color:"white" }}
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            Sambhar Cards
          </Typography>
        <Box flexGrow={1} />
        

        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton onClick={handleSubmit}  color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
