import React,{useRef, useState , useEffect} from 'react';
import { useReactToPrint } from "react-to-print";
import {

    Box,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    Container,
    Grid,
    CardHeader,
    TextField,
    Avatar,
    Checkbox,
    TablePagination,
    Button,
    Card,
    InputAdornment,
    SvgIcon,
    Tooltip,
  } from "@material-ui/core";
  import { tostS, tostW, tostE } from "../../Config/Toast";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux'
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import moment from 'moment'
import {AddSeq , GetOrdSeq ,EditSeq} from '../../Actions/BillSequence'
import {cleanOrderSeq} from '../../Actions/ActionCreators'
import {useDispatch} from 'react-redux'

import Page from "../../../src/components/Page";

import { AddOrders, GetOrders, DelOrders, EditOrders, ChangeOrderStatus , AddBilti} from '../../Actions/Orders'



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,

    color: theme.palette.common.white,
    height:4,
    // padding:4,
  },
  [`&.${tableCellClasses.body}`]: {
  
    fontSize: 10,
    padding:2,
    border:"1px solid black",
    height:5
  },
  '&:css-hh20tw-MuiTableCell-root': {
   padding:"8px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    padding:10,
    marginLeft:50,
    marginRight:50
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  rootn: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,

 },
 
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));



const TAX_RATE = 0.07;

function ccyFormat(f , m) {

  
  console.log(f)
  var total = 0
  for(let c = 0 ; c < f.length ; c++ )
  {
    total = total + parseFloat(f[c].rate) * parseFloat(f[c].quantity)
  }
  return (total +parseFloat(m)).toFixed(2) 
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow('Paperclips (Box)', 100, 1.15),
  createRow('Paper (Case)', 10, 45.99),
  createRow('Waste Basket', 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;


const App = () => {  
  const componentRef = useRef();
  const {state} = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate  = useNavigate();

  var [fdata , setFdata] = useState([])

console.log(state)
  const filterData   = (gdata) =>{
    var temp =  state
    var temp2  = []
    var bill_number = "";
    var mobile_number = "";
    var firm_name = ""

    var hell =  new Promise((resolve,reject)=>{
      
   
      temp.forEach((data, i)=>{
        temp2 = [...temp2 , ...data.order_details]
        
      })

    mobile_number = state[0].mobile
    firm_name = state[0].name_of_firm

    for(let i =  0 ; i <  state.length ; i++)
    {
      bill_number = bill_number + String(state[i].ord_id) +  "/"
    }

    resolve()



    })
    hell.then(()=>{
      var chkdata  =  gdata 
      for(let i = 0  ;  i < temp2.length ; i++) 
      {
       
       
        console.log(chkdata)
        for(let j  = 0 ; j < chkdata.length ; j++)
        {
          // console.log(chkdata[j].card_number)
    
         
          if(temp2[i].card_number === chkdata[j].card_number)
          {
            console.log(chkdata[j].rate)
            temp2[i]['rate'] = chkdata[j].rate 
            break
          }
        }
      

     
      
       
      }
        
        console.log(temp2)
        // setFdata(temp2)
        
      })
      
      return { 'bill_number' : bill_number , 'mobile' : mobile_number , "firm_name" : firm_name , "fullD"  :  temp2}

  }






  // const mod_data  =  useSelector((state) =>  state.AddStock.getdata )

  const m2  =  state




  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const [values , setValues] = React.useState({
    tax_f : 0,
    cash : 0,
    bank : 0,
    other : 0,
    credit : 0,
    fullData :state.fullD,
    edit_flag : false
  })

  const handleChange = (e) =>{
    setValues({...values , [e.target.name]: e.target.value})
  }

  console.log(values.fullData)
  console.log(m2)


  const handleInput = (e , index) =>{
    var y  =  values.fullData
  y[index][e.target.name] = e.target.value

    setValues({...values , ['fullData'] : y})
    
  }


  

  const handleInput2 = (e ) =>{
  setValues({...values,[e.target.name] : e.target.value})

    
  }
  
  const handleEditFlag = () =>{
    console.log(values.edit_flag)
    
    setValues({...values , ['edit_flag'] : true})
  }

  const handleEditFlag2 = () =>{
    console.log(values.edit_flag)

  
    
    setValues({...values , ['edit_flag'] : false})
  }
  
  const current_order_seq_data  = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) &&
  state.AddSeq.getdata.length
      ?  state.AddSeq.getdata[0].current_order_seq
      : "NA"
  );

  const last_order_seq_data  = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) &&
  state.AddSeq.getdata.length
      ?  state.AddSeq.getdata[0].last_order_seq
      : "NA"
  );


  const seq_id  = useSelector((state) =>
  state.AddSeq.getdata &&
  Array.isArray(state.AddSeq.getdata) &&
  state.AddSeq.getdata.length
      ?  state.AddSeq.getdata[0].seq_id
      : "NA"
  );



  const Addmsg = useSelector(state => state.AddSeq && state.AddSeq.editdata && state.AddSeq.editdata.message ? state.AddSeq.editdata.message : null  )
  const Adderror = useSelector(state => state.AddSeq && state.AddSeq.editerror && state.AddSeq.editerror.message ? state.AddSeq.editerror.message : null  )
  
  
  const handleUpdateBill = () =>{


    console.log(values.fullData)

    var t  =  {
      rstate:state,
      others : values,
      print_bill_number :   last_order_seq_data === (0 || '0') ? current_order_seq_data :  parseInt(last_order_seq_data) + 1  
    }

    console.log(t)
    var df = {
      'order_status' : 2,
      'ord_id' : state.bill_array,
      "bill_number" : last_order_seq_data === (0 || '0') ? current_order_seq_data :  parseInt(last_order_seq_data) + 1,
      "bill_data" : t,
     
      
  }

     
    dispatch(ChangeOrderStatus(df));
    // dispatch(EditSeq({
    //   "last_order_seq" : last_order_seq_data === (0 || '0') ? current_order_seq_data :  parseInt(last_order_seq_data) + 1 ,
    // "seq_id" : seq_id
    // }))
  }

  const Statusmsg = useSelector(state => state.AddOrders.statusdata && state.AddOrders.statusdata.message ? state.AddOrders.statusdata.message : null)
  const Statuserror = useSelector(state => state.AddOrders.statuserror && state.AddOrders.statuserror.message ? state.AddOrders.statuserror.message : null)
  
  
  
  useEffect( () => {

    dispatch(GetOrdSeq())


    let x  = new Promise((resolve ,reject)=>{
      if(Addmsg!==null && typeof(Addmsg) === 'string')
      {
        tostS(Addmsg)
       
        navigate('/app/Orders')
       
      }
      if(Adderror!==null && typeof(Adderror) === 'string')
      {
          tostE(Adderror)
      }

      if (Statusmsg !== null && typeof (Statusmsg) === 'string') {
        tostS(Statusmsg)
        dispatch(EditSeq({
          "last_order_seq" : last_order_seq_data === (0 || '0') ? current_order_seq_data :  parseInt(last_order_seq_data) + 1 ,
        "seq_id" : seq_id
        }))
      }
      if (Statuserror !== null && typeof (Statuserror) === 'string') {
        tostE(Statuserror)
      }
     
      resolve()

    })
    x.then(()=>{
    dispatch(cleanOrderSeq())

    })


    
  }, [ Addmsg , Adderror  ,Statusmsg ,Statuserror])



  const [textin , setTextin] = useState([])


  const addFields = () =>{
 
      var temp  =  textin
      var y  =   {name : "" , value  :""  , visible  : true , index : temp.length  }
      temp.push(y)
      setTextin(temp)
  

  }
  
  return (
   <Page className={classes.root} title="Order Details">
           <Grid container spacing={3}>

<Grid item md={6} xs={12} style={{justifyContent:"center"}}>
<Button
                    
                    onClick={handlePrint}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"green" , color:"white" , width:"90%"}}
                    // fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                    Print Sheet
                  </Button>

</Grid>

<Grid item md={6} xs={12} style={{justifyContent:"center"}}>
  {values.edit_flag === false?
<Button
                    
                    onClick={handleEditFlag}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"orange" , color:"white" , width:"90%"}}
                    // fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                    Edit Bill
                  </Button>
 : 

                  <Button
                    
                    onClick={handleEditFlag2}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"orange" , color:"white" , width:"90%"}}
                    // fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                    Save 
                  </Button>

  }

</Grid>

</Grid>
        
                 
          <div ref={componentRef} className="card">
            
            <hr />
            <div class="float__infoss">

         
            <Container maxWidth="md" style={{marginTop:10}} >
         
         <Card sx={{ maxWidth: "50%" }}>
        {/* <CardHeader subheader="" title="Order Details" /> */}
        <Divider />
        <CardContent>
        <Grid container spacing={0}>
         <Grid item md={6} xs={6}>
           <Typography>Billing Date : {moment(new Date(Date.now())).format("DD/MM/YYYY")} </Typography>
         </Grid>
         {/* <Grid item md={6} xs={6}>
            <Typography>Order Date : {moment(state.order_date).format("DD/MM/YYYY")}  </Typography>
   
         </Grid> */}
        
         <Grid item md={6} xs={6}>
           {/* <Typography>Bill Number : {m2.bill_number}</Typography> */}
           <Typography>Bill Number : {last_order_seq_data === (0 || '0') ? current_order_seq_data :  parseInt(last_order_seq_data) + 1  }</Typography>
         </Grid>
         
         {/* <Grid item md={6} xs={6}>
         <Typography>Bilti Number : {state.bilti_no} </Typography>
      </Grid> */}
         {/* <Grid item md={6} xs={6}>
            <Typography>Name : {state.name} </Typography>
         </Grid> */}
         <Grid item md={6} xs={6}>
            <Typography>Mobile : {m2.mobile} </Typography>
         </Grid>
         <Grid item md={6} xs={6}>
            <Typography>Firm Name : {m2.firm_name} </Typography>
         </Grid>
         </Grid>
         </CardContent>
   </Card>
   </Container>
      
    <>
    <Container maxWidth="md">
         
         <Card sx={{ maxWidth: "100%" }}>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700  }}  size="small"  aria-label="spanning table">
        <TableHead>
         
          <TableRow>
            <TableCell>Card Number</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.fullData
           && Array.isArray(values.fullData) && values.fullData.length?
          <>
          {values.fullData.map((row , i) => (
            <TableRow key={i}>
              <TableCell>{row.card_number}</TableCell>
              {values.edit_flag === false ?
              <TableCell align="right">{row.quantity}</TableCell>
              : <TableCell align="right"><input value ={row.quantity} name="quantity" placeholder='Enter Quantity' onChange={(e)=>{handleInput(e, i)}} /></TableCell> }
               {values.edit_flag === false ?
              <TableCell align="right">{row.rate}</TableCell>
              :<TableCell align="right"><input value ={row.rate} name="rate" placeholder='Enter Rate' onChange={(e)=>{handleInput(e, i)}} /></TableCell>  }


              <TableCell align="right">{(parseFloat(row.quantity) *  parseFloat(row.rate)).toFixed(2)}</TableCell>
            </TableRow>
          ))}

         
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell align="right" style={{fontWeight:"bold"}} colSpan={2}>Subtotal</TableCell>
            <TableCell align="right" style={{fontWeight:"bold"}}>{ccyFormat(values.fullData ,0) }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={{fontWeight:"bold"}} colSpan={2}>Tax/Freight</TableCell>
            {values.edit_flag === false?
            <TableCell align="right" style={{fontWeight:"bold"}}>{values.tax_f}</TableCell>
            :
            <TableCell align="right" style={{fontWeight:"bold"}}><input value ={values.tax_f} name="tax_f" placeholder='Enter Tax/Fright' onChange={(e)=>{handleInput2(e)}} /></TableCell> }

          </TableRow>
          <TableRow>
            <TableCell align="right" style={{fontWeight:"bold"}} colSpan={2}>Total</TableCell>
            <TableCell align="right" style={{fontWeight:"bold"}}>{ccyFormat(values.fullData , values.tax_f)}</TableCell>
          </TableRow>
          </> :null}

              



        </TableBody>
      </Table>
    </TableContainer>
    </Card>

    <Card>
    <CardContent>
        <Grid container spacing={0}>
        <Grid item md={12} xs={12}>
           <Typography style={{fontWeight:"bold"}}>Payment Details </Typography>
         </Grid>
     
      
         <Grid item md={4} xs={4}>
           <Typography style={{fontWeight:"bold"}}>Cash</Typography>
         </Grid>
      
        {values.edit_flag === false ?
         <Grid item md={4} xs={4}>
           <Typography>{values.cash}</Typography>
         </Grid>
         :
         <Grid item md={4} xs={4}>
           <Typography><input value ={values.cash} name="cash" placeholder='Enter Cash Value' onChange={(e)=>{handleInput2(e)}} /></Typography>
         </Grid>
}
         <Grid item md={4} xs={4}>
         </Grid>
      
         <Grid item md={4} xs={4}>
           <Typography style={{fontWeight:"bold"}}>UPI/Bank Tranfer</Typography>
         </Grid>
      
         {values.edit_flag === false ?
         <Grid item md={4} xs={4}>
           <Typography>{values.bank}</Typography>
         </Grid>
             :
             <Grid item md={4} xs={4}>
               <Typography><input value ={values.bank} name="bank" placeholder='Enter Value' onChange={(e)=>{handleInput2(e)}} /></Typography>
             </Grid>
    }
         <Grid item md={4} xs={4}>
         </Grid>
      
         <Grid item md={4} xs={4}>
           <Typography style={{fontWeight:"bold"}}>Other</Typography>
         </Grid>
      
         {values.edit_flag === false ?
         <Grid item md={4} xs={4}>
           <Typography>{values.other}</Typography>
         </Grid>
             :
             <Grid item md={4} xs={4}>
               <Typography><input value ={values.other} name="other" placeholder='Enter Value' onChange={(e)=>{handleInput2(e)}} /></Typography>
             </Grid>
    }
    <Grid item md={4} xs={4}>
         </Grid>
      
         <Grid item md={4} xs={4}>
           <Typography style={{fontWeight:"bold"}}>Credit</Typography>
         </Grid>
      
         {values.edit_flag === false ?
         <Grid item md={4} xs={4}>
           <Typography>{values.credit}</Typography>
         </Grid>
             :
             <Grid item md={4} xs={4}>
               <Typography><input value ={values.credit} name="credit" placeholder='Enter Value' onChange={(e)=>{handleInput2(e)}} /></Typography>
             </Grid>
    }
         <Grid item md={4} xs={4}>
         </Grid>
         <Grid item md={4} xs={4}>
           <Typography style={{fontWeight:"bold"}}>Total</Typography>
         </Grid>
      
         <Grid item md={4} xs={4}>
           <Typography>{(parseFloat(values.bank) + parseFloat(values.credit) +  parseFloat(values.other) + parseFloat(values.cash) ).toFixed(2) }   </Typography>
         </Grid>
             
         <Grid item md={4} xs={4}>
         </Grid>
      
         
        
        
        
         </Grid>
         </CardContent>
    </Card>
   
   </Container>

        </>
            </div>
          </div>

          <Grid container spacing={3}>

<Grid item md={12} xs={12} style={{justifyContent:"center"}}>
<Button
                    
                    onClick={handleUpdateBill}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"#b85278" , color:"white" , width:"100%"}}
                    // fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                   Dispatch All
                  </Button>

</Grid>
{/* <Grid item md={6} xs={12} style={{justifyContent:"center"}}>
<Button
                    
                    onClick={addFields}
                    className="print__button"
                    style={{marginTop:10 , backgroundColor:"#b85278" , color:"white" , width:"100%"}}
                    // fullWidth
                  
                    size="large"
                    variant="contained"
                  >
                    Add Fields
                  </Button>

</Grid> */}
</Grid>
       

  
    </Page>    
  )
}
export default App