import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../components/Page';

import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch} from 'react-redux';
import {tostS  , tostW , tostE } from '../../Config/Toast'
import {ToastContainer} from 'react-toastify'


import {AddUpi} from '../../Actions/UPI'
import {CleanUpiData} from '../../Actions/ActionCreators'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload UPI Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  }

}));

const AddBanners = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()




  const id = useSelector(state => state.login.data.admin_id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
   
  acc_no :"",
  ifsc_code :"",
  contact_person :"",
  contact_number:"",
  img : "",
  bank_name : "",
  fixImg : "/static/images/avatars/upload.png"

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (date) =>{

    console.log(date)

  }


  const handleSubmit = () =>{


    if(values.img !== '' && values.img !== undefined && values.img !== null )
    {
      
      dispatch(AddUpi(values))
      setValues({
        ...values,
        image1:"",
        tempImage1:"",
        acc_no :"",
        ifsc_code :"",
        contact_person:"",
        contact_number:"",
        bank_name : "",
        img : "",
       
      });
    }
    else{
      tostW("Please Select Card Image")
    }
     

  }



  const addmsg = useSelector(state => state.AddUpi && state.AddUpi.data && state.AddUpi.data.message ? state.AddUpi.data.message : null  )
  const adderror = useSelector(state => state.AddUpi && state.AddUpi.error && state.AddUpi.error.message ? state.AddUpi.error.message : null  )


  useEffect( () => {

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !== null && typeof(addmsg) === 'string')
      {
        tostS("Added Successfully")
      }
      if(adderror !==null)
      {
        tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(CleanUpiData())
   

    })

  
     
    }, [addmsg , adderror])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['img']:fileUploaded , ['tempImage1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }

  };
  
  /********* Image Handling   ************** */

  return (
    <Page
      className={classes.root}
      title="Add UPI"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={values.tempImage1 !== '' ?  values.tempImage1 : values.fixImg }
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>

          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
          Upload picture
        </Button>
      </CardActions>
    </Card>
            {/* <Profile /> */}
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Upi Details" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label="Account Number"
                name="acc_no"
                onChange={handleChange}
                required
                value={values.acc_no}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                
                fullWidth
                label="IFSC Code"
                name="ifsc_code"
                onChange={handleChange}
                required
                value={values.ifsc_code}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                
                fullWidth
                label="Bank name"
                name="bank_name"
                onChange={handleChange}
                required
                value={values.bank_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                
                fullWidth
                label="Contact Person"
                name="contact_person"
                onChange={handleChange}
                required
                value={values.contact_person}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                
                label="Contact Number"
                name="contact_number"
                onChange={handleChange}
                required
                value={values.contact_number}
                variant="outlined"
              />
            </Grid>
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBanners;
