import {ADD_STOCK_LOADING , ADD_STOCK_SUCCESS , ADD_STOCK_FAILURE} from '../Actions/Types';
import {GET_STOCK_LOADING , GET_STOCK_SUCCESS , GET_STOCK_FAILURE} from '../Actions/Types';
import {EDIT_STOCK_LOADING , EDIT_STOCK_SUCCESS , EDIT_STOCK_FAILURE} from '../Actions/Types';
import {MODIFY_STOCK_LOADING , MODIFY_STOCK_SUCCESS , MODIFY_STOCK_FAILURE} from '../Actions/Types';
import {DEL_STOCK_LOADING , DEL_STOCK_SUCCESS , DEL_STOCK_FAILURE} from '../Actions/Types';
import {UPDATE_STOCK_VISIBILITY_LOADING , UPDATE_STOCK_VISIBILITY_SUCCESS , UPDATE_STOCK_VISIBILITY_FAILURE} from '../Actions/Types';


import {CLEAN_ALL_DATA , CLEAN_STOCK_DATA} from '../Actions/Types';



const AddStock = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            viewData:[{}],
            viewError:{},
            modifyData:[{}],
            modifyError:{}
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            modifyData:[{}],
            modifyError:{}
        }

    case CLEAN_STOCK_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                viewData:[{}],
                viewError:{},
                modifyData:[{}],
                modifyError:{}
            }
    

    case ADD_STOCK_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_STOCK_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
      
    case GET_STOCK_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_STOCK_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };
          
    case EDIT_STOCK_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_STOCK_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };
      
    case MODIFY_STOCK_LOADING:
        return {
          ...state,
          loading: true,
          modifyError: {},
          modifyData:[{}],
        };
    case MODIFY_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          modifyError: {},
          modifyData: action.data,
        };
    case MODIFY_STOCK_FAILURE:
        return {
          ...state,
          loading: false,
          modifyError: action.error,
          modifyData: [{}],
        };

        
    case DEL_STOCK_LOADING:
        return {
          ...state,
          loading: true,
          delerror: {},
          deldata:[{}],
        };
    case DEL_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          delerror: {},
          deldata: action.data,
        };
    case DEL_STOCK_FAILURE:
        return {
          ...state,
          loading: false,
          delerror: action.error,
          deldata: [{}],
        };
    case UPDATE_STOCK_VISIBILITY_LOADING:
        return {
          ...state,
          loading: true,
          viewError: {},
          viewData:[{}],
        };
    case UPDATE_STOCK_VISIBILITY_SUCCESS:
        return {
          ...state,
          loading: false,
          viewError: {},
          viewData: action.data,
        };
    case UPDATE_STOCK_VISIBILITY_FAILURE:
        return {
          ...state,
          loading: false,
          viewError: action.error,
          viewData: [{}],
        };
    
    default:
        return state

}
}



export default AddStock;
