import {LTD_LOADING, LTD_SUCCESS, LTD_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_LTD} from '../Actions/Types';

const LooseStock = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
           data: [{}],
           error: {},
           
       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],
           error: {},

       }

       case CLEAN_LTD:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
 
        }


   

   case LTD_LOADING:
       return {
         ...state,
         loading: true,
         error: {},
         data:[{}],
       };
   case LTD_SUCCESS:
       return {
         ...state,
         loading: false,
         error: {},
         data: action.data,
       };
   case LTD_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.err,
         data: [{}],
       };
     
   default:
       return state

}
}



export default LooseStock;
