import {OUT_OF_STOCK_LOADING, OUT_OF_STOCK_SUCCESS, OUT_OF_STOCK_FAILURE, CLEAN_OUT_OF_STOCK_DATA} from '../Actions/Types';

import {CLEAN_ALL_DATA} from '../Actions/Types';

const OutOfStock = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
           data: [{}],
           error: {},
           
       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],
           error: {},

       }

   case CLEAN_OUT_OF_STOCK_DATA:
       return {
               ...state,
               loading:false,
               data: [{}],
               error: {},
               
           }
   

   case OUT_OF_STOCK_LOADING:
       return {
         ...state,
         loading: true,
         error: {},
         data:[{}],
       };
   case OUT_OF_STOCK_SUCCESS:
       return {
         ...state,
         loading: false,
         error: {},
         data: action.data,
       };
   case OUT_OF_STOCK_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.error,
         data: [{}],
       };
     
   default:
       return state

}
}



export default OutOfStock;
