import { combineReducers  } from "redux";
import login from './Login';

import Home from './Home'
import GetState from './State';
import GetCity from './City';
import GetCountry from './Country';
import AddUsers from './Users';
import AddOrders from "./Orders";
import AddOfferCard from "./OfferCard";
import AddNewArrival from "./NewArrivals";
import AddGallery from "./Gallery";
import AddUpi from "./UPI"

import RateCard from "./RateCard";
   
import PlaceOrder from "./PlaceOrder";
import OutOfStock from "./OutOfStock";

import AddStock from "./Stock";
import AddParty from "./Party";
import StockSummary from "./StockSummary";

import ModifyOrder from './OrdersModify'
import LooseStock from './LooseStock'

import Msgs from "./Msgs";

import History from "./history";


import Ltd from './LTD'

import AddSeq from "./OrdSeq";

export default combineReducers({
    login,
    Msgs,
   
    Home,
    GetCity,
    GetState,
    GetCountry,
    AddUsers,
    AddOrders,
    AddOfferCard,
    AddNewArrival,
    AddGallery,
    AddUpi,
    OutOfStock,
    PlaceOrder,
    ModifyOrder,
    LooseStock,
    Ltd,
    RateCard,

    AddStock,
    AddParty,
    StockSummary,

    History,
    AddSeq
});
