
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { GetSummaryBegin , GetSummarySuccess , GetSummaryFailure } from './ActionCreators'
import { getRateCardBegin , getRateCardSuccess , getRateCardFailure } from './ActionCreators'


import { Base_URL } from '../Config/BaseUrlConfig'






export const GetSummary = (data) => {
    return (dispatch) => {
      dispatch(GetSummaryBegin());

      axios.get(Base_URL + '/admin/getSummaryByCN', {params:{'card_number' : data}} )
          .then((res) => {
            //   console.log(res)
              dispatch(GetSummarySuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  // alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetSummaryFailure(err.response.data));

              }
          })
  }

}



export const GetRateCards = (data) => {
  return (dispatch) => {
    dispatch(getRateCardBegin());

    axios.get(Base_URL + '/admin/getratecard' )
        .then((res) => {
          //   console.log(res)
            dispatch(getRateCardSuccess(res.data.data));
        }).catch((err) => {
          //   console.log(err)
            if (!err.response) {
                // alert("Something Went wrong : Please Check Network or Server Connectivity")

            }
            else {
                dispatch(getRateCardFailure(err.response.data));

            }
        })
}

}
