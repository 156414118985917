import {MOVE_TO_LOOSE_LOADING, MOVE_TO_LOOSE_SUCCESS, MOVE_TO_LOOSE_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_MTL} from '../Actions/Types';

const ModifyOrder = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
           data: [{}],
           error: {},
       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],
           error: {},
           cardData: [{}],
           cardError: {},

       }

   case CLEAN_MTL:
       return {
         ...state,
         loading:false,
         data: [{}],
         error: {}

           }
   case MOVE_TO_LOOSE_LOADING:
      return {
        ...state,
        loading: true,
        data: [{}],
        error:{},
      };
   case MOVE_TO_LOOSE_SUCCESS:
       return {
         ...state,
         loading: false,
         data: action.mtl,
         error: {},
       };
   case MOVE_TO_LOOSE_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.mtlerr,
         data: [{}],
       };   


   default:
       return state

}
}



export default ModifyOrder;
