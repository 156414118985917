
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { sendMsgBegin , sendMsgSuccess , sendMsgFailure } from './ActionCreators'
import { getMsgBegin , getMsgSuccess , getMsgFailure } from './ActionCreators'

import { Base_URL } from '../Config/BaseUrlConfig'




export const sendMsg = (data) => {
       

      return (dispatch) => {
        dispatch(sendMsgBegin());

        axios.post(Base_URL + '/admin/sendfcm', data)
            .then((res) => {
                // console.log(res.data)
                dispatch(sendMsgSuccess(res.data));
            }).catch((err) => {
                // console.log(err)
                if (!err.response) {
                    // alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(sendMsgFailure(err.response.data));

                }
            })
    }

}


export const GetMsgs = () => {
    return (dispatch) => {
      dispatch(getMsgBegin());

      axios.get(Base_URL + '/admin/getAllNotification')
          .then((res) => {
            //   console.log(res)
              dispatch(getMsgSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(getMsgFailure(err.response.data));

              }
          })
  }

}


