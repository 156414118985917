import {GET_LOOSE_LOADING, GET_LOOSE_SUCCESS, GET_LOOSE_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA} from '../Actions/Types';

const LooseStock = (state , action) => {
   if(typeof state === 'undefined') {
       return {
           loading:false,
           data: [{}],
           error: {},
           
       }
   }


   
switch(action.type) {
   case CLEAN_ALL_DATA:
       return {
           ...state,
           loading:false,
           data: [{}],
           error: {},

       }


   

   case GET_LOOSE_LOADING:
       return {
         ...state,
         loading: true,
         error: {},
         data:[{}],
       };
   case GET_LOOSE_SUCCESS:
       return {
         ...state,
         loading: false,
         error: {},
         data: action.lsd,
       };
   case GET_LOOSE_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.lse,
         data: [{}],
       };
     
   default:
       return state

}
}



export default LooseStock;
