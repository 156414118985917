
import axios from "../Config/Interceptor1";


// import axios from 'axios'

import { GetOfferCardBegin , GetOfferCardSuccess , GetOfferCardFailure } from './ActionCreators'


import { Base_URL } from '../Config/BaseUrlConfig'



export const GetOfferCard = (data) => {
    return (dispatch) => {
      dispatch(GetOfferCardBegin());

      axios.get(Base_URL + '/admin/getOfferCard', )
          .then((res) => {
            //   console.log(res)
              dispatch(GetOfferCardSuccess(res.data));
          }).catch((err) => {
            //   console.log(err)
              if (!err.response) {
                  // alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetOfferCardFailure(err.response.data));

              }
          })
  }

}

