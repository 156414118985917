import {GET_STATES_LOADING , GET_STATES_SUCCESS , GET_STATES_FAILURE} from '../Actions/Types';
import {CLEAN_ALL_DATA , CLEAN_STATES_DATA} from '../Actions/Types';



const GetStates = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            getdata: [{}],
            geterror: {},
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            getdata: [{}],
            geterror: {},
        }

    case CLEAN_STATES_DATA:
        return {
                ...state,
                loading:false,
                getdata: [{}],
                geterror: {},
                
            }
    

        case GET_STATES_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_STATES_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_STATES_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };
      
    default:
        return state

}
}



export default GetStates;
