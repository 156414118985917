
import axios from "../Config/Interceptor1";


// import axios from 'axios'
import { GetGalleryBegin , GetGallerySuccess , GetGalleryFailure } from './ActionCreators'


import { Base_URL } from '../Config/BaseUrlConfig'



export const GetGallery = (data) => {
    return (dispatch) => {
      dispatch(GetGalleryBegin());

      axios.get(Base_URL + '/admin/getGalleryData')
          .then((res) => {
              console.log(res)
              dispatch(GetGallerySuccess(res.data));
          }).catch((err) => {
              console.log(err)
              if (!err.response) {
                //   alert("Something Went wrong : Please Check Network or Server Connectivity")

              }
              else {
                  dispatch(GetGalleryFailure(err.response.data));

              }
          })
  }

}

