
import {ADD_OFFER_CARD_LOADING , ADD_OFFER_CARD_SUCCESS , ADD_OFFER_CARD_FAILURE} from '../Actions/Types';
import {GET_OFFER_CARD_LOADING , GET_OFFER_CARD_SUCCESS , GET_OFFER_CARD_FAILURE} from '../Actions/Types';
import {EDIT_OFFER_CARD_LOADING , EDIT_OFFER_CARD_SUCCESS , EDIT_OFFER_CARD_FAILURE} from '../Actions/Types';
import {DEL_OFFER_CARD_LOADING , DEL_OFFER_CARD_SUCCESS , DEL_OFFER_CARD_FAILURE} from '../Actions/Types';

import {CLEAN_ALL_DATA , CLEAN_OFFER_CARD_DATA} from '../Actions/Types';



const AddOfferCard = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
        }
    }


    
switch(action.type) {
    case CLEAN_ALL_DATA:
        return {
            ...state,
            loading:false,
            data: [{}],
            error: {},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
        }

    case CLEAN_OFFER_CARD_DATA:
        return {
                ...state,
                loading:false,
                data: [{}],
                error: {},
                getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                
            }
    

    case ADD_OFFER_CARD_LOADING:
        return {
          ...state,
          loading: true,
          error: {},
          data:[{}],
        };
    case ADD_OFFER_CARD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.data,
        };
    case ADD_OFFER_CARD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          data: [{}],
        };
      
    case GET_OFFER_CARD_LOADING:
        return {
          ...state,
          loading: true,
          geterror: {},
          getdata:[{}],
        };
    case GET_OFFER_CARD_SUCCESS:
        return {
          ...state,
          loading: false,
          geterror: {},
          getdata: action.data,
        };
    case GET_OFFER_CARD_FAILURE:
        return {
          ...state,
          loading: false,
          geterror: action.error,
          getdata: [{}],
        };
          
    case EDIT_OFFER_CARD_LOADING:
        return {
          ...state,
          loading: true,
          editerror: {},
          editdata:[{}],
        };
    case EDIT_OFFER_CARD_SUCCESS:
        return {
          ...state,
          loading: false,
          editerror: {},
          editdata: action.data,
        };
    case EDIT_OFFER_CARD_FAILURE:
        return {
          ...state,
          loading: false,
          editerror: action.error,
          editdata: [{}],
        };

    case DEL_OFFER_CARD_LOADING:
        return {
          ...state,
          loading: true,
          delerror: {},
          deldata:[{}],
        };
    case DEL_OFFER_CARD_SUCCESS:
        return {
          ...state,
          loading: false,
          delerror: {},
          deldata: action.data,
        };
    case DEL_OFFER_CARD_FAILURE:
        return {
          ...state,
          loading: false,
          delerror: action.error,
          deldata: [{}],
        };

    default:
        return state

}
}



export default AddOfferCard;
