import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Tool as ToolIcon,
  Radio as RadioIcon,
  Bookmark as BIcon,
  Navigation as NIcon,
  Image as imgIcon,
  Bell as BellIcon
} from 'react-feather';
import NavItem from './NavItem';
import {useSelector} from 'react-redux'
import PaymentIcon from '@material-ui/icons/Payment';
import DnsIcon from '@material-ui/icons/Dns'
import ShowChartIcon from '@material-ui/icons/ShowChart';
import HistoryIcon from '@material-ui/icons/History';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const user = {
  avatar: "/static/sam.png",
  jobTitle: 'Sambhar Cards Admin',
  
};




const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
 
  {
    href: '/app/NewArrival',
    icon: BIcon,
    title: 'New Arrival'
  },
 
  {
    href: '/app/gallery',
    icon: imgIcon,
    title: 'Gallery'
  },
  {
    href: '/app/UPI',
    icon: PaymentIcon,
    title: 'UPI Details'
  },
  {
    href:'/app/users',
    icon:UsersIcon,
    title:'Users'
  },
  {
    href:'/app/Orders',
    icon:ShoppingBagIcon,
    title:'Orders'
  },
  {
    href:'/app/OfferCard',
    icon:RadioIcon,
    title:'Offer Cards'
  },
  {
    href:'/app/masterview',
    icon:DnsIcon,
    title:'Master'

  },
  {
    href:'/app/stock',
    icon:ShowChartIcon,
    title:'Stock'
  },
  {
    href:'/app/history',
    icon:HistoryIcon,
    title:'History'
  },
  {
    href:'/app/BillSequence',
    icon:FormatListNumberedIcon,
    title:'Bill Sequence'
  },
  {
    href:'/app/msgs',
    icon:BellIcon,
    title:'Notifications'
  }
  
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 100,
    height: 100
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const role = useSelector(state => state.login.data)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>


        <List>
       
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
         
        </List>



      </Box>
      <Box flexGrow={1} />

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
